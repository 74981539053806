import { useCallback, useState } from 'react';
import {
	Alert,
	Pressable,
	StyleProp,
	StyleSheet,
	Text,
	TextStyle,
	useWindowDimensions,
	View,
	ViewStyle,
} from 'react-native';
import {
	NavigationState,
	Route,
	SceneRendererProps,
	TabBar,
	TabBarIndicatorProps,
	TabBarItemProps,
	TabView,
} from 'react-native-tab-view';
import { Event, Scene } from 'react-native-tab-view/lib/typescript/types';
import { useSelector } from 'react-redux';
import { AntDesign } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';

//constants
import Colors, { PRIMARY_COLOR } from '../../common/constants/Colors';
import { FONT_BOLD, FONT_NORMAL } from '../../common/constants/Fonts';
import axios from '../../common/utils/axios';

import IncidentCard, { InicidentProps } from './IncidentCard';

export type AlertProps = {
	id: number;
	alerttype: string;
	today_fl: boolean;
	title: string;
	description: string;
	target: string;
	severity: string;
	water_level: string;
	rainfall: string;
	assigned_by: string;
	alertArea: [
		{
			id: number;
			district: {
				id: number;
				district_code: number;
				name: string;
				name_en: string;
				name_hi: string;
				state: {
					id: number;
					state_code: number;
					name: string;
					name_en: string;
					name_hi: string;
				};
			};
			block: {
				id: number;
				block: string;
			};
			village: null | {
				id: number;
				village: string;
			};
			created_at: string;
			updated_at: string;
			river: {
				id: 1000001;
				name: string;
			};
		}
	];
	created_at: string;
	updated_at: string;
};

type routeProps = {
	incidents: InicidentProps[];
};

type customSceneProps = SceneRendererProps & {
	route: {
		key: string;
		title: string;
	};
};

const ROUTE_HEIGHT = 100;

const AllRoute = ({ incidents }: routeProps) => (
	<View style={{ height: ROUTE_HEIGHT }}>
		{incidents && incidents.length > 0 ? (
			<IncidentCard
				title={incidents[0].title}
				status={incidents[0].status}
				assigned={incidents[0].assigned}
				reported_by={incidents[0].reported_by}
				assigned_by={incidents[0].assigned_by ? incidents[0].assigned_by : ''}
				created_at={incidents[0].created_at}
				updated_at={incidents[0].updated_at}
				village={incidents[0].village}
				block={incidents[0].block}
				district={incidents[0].district}
				history={incidents[0].history}
			/>
		) : (
			<View style={styles.errorContainer}>
				<View style={styles.empty}>
					<Text style={styles.emptyText}>No data found</Text>
				</View>
			</View>
		)}
	</View>
);

export default function AdminIncidentTabs({ navigation }: { navigation: any }) {
	const [incidents, setincidents] = useState<InicidentProps[]>([]);
	const [index, setIndex] = useState(0);
	const { blockId, districtId } = useSelector((state: any) => state.location.value);

	const layout = useWindowDimensions();

	const [routes] = useState([
		{ key: 'assigned', title: 'Assigned' },
		{ key: 'unassigned', title: 'Unassigned' },
	]);

	useFocusEffect(
		useCallback(() => {
			const getIncidentDetails = async () => {
				try {
					await axios
						.post('/api/dashboard/incident-dashboard', {
							params: { page: 1 },
							limit: '1',
							type: index === 0 ? 'assigned' : 'unassigned',
							block_id: blockId && blockId.toString(),
							district_id: districtId && districtId.toString(),
						})
						.then((response) => {
							const { data } = response.data;
							if (data) {
								setincidents(data);
							} else {
								setincidents([]);
							}
						});
				} catch (error: any) {
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			};

			getIncidentDetails();
		}, [blockId, districtId, index])
	);

	// const renderScene = SceneMap({
	// 	assigned: () => <AllRoute incidents={incidents} />,
	// 	unassigned: AllRoute,
	// });

	const renderScene = ({ route }: customSceneProps) => {
		switch (route.key) {
			case 'assigned':
				return <AllRoute incidents={incidents} />;
			case 'unassigned':
				return <AllRoute incidents={incidents} />;
		}
	};

	const renderTabBar = (
		props: JSX.IntrinsicAttributes &
			SceneRendererProps & {
				navigationState: NavigationState<Route>;
				scrollEnabled?: boolean | undefined;
				bounces?: boolean | undefined;
				activeColor?: string | undefined;
				inactiveColor?: string | undefined;
				pressColor?: string | undefined;
				pressOpacity?: number | undefined;
				getLabelText?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getAccessible?: ((scene: Scene<Route>) => boolean | undefined) | undefined;
				getAccessibilityLabel?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getTestID?: ((scene: Scene<Route>) => string | undefined) | undefined;
				renderLabel?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderIcon?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderBadge?: ((scene: Scene<Route>) => React.ReactNode) | undefined;
				renderIndicator?:
					| ((props: TabBarIndicatorProps<Route>) => React.ReactNode)
					| undefined;
				renderTabBarItem?:
					| ((
							props: TabBarItemProps<Route> & { key: string }
					  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
					| undefined;
				onTabPress?: ((scene: Scene<Route> & Event) => void) | undefined;
				onTabLongPress?: ((scene: Scene<Route>) => void) | undefined;
				tabStyle?: StyleProp<ViewStyle>;
				indicatorStyle?: StyleProp<ViewStyle>;
				indicatorContainerStyle?: StyleProp<ViewStyle>;
				labelStyle?: StyleProp<TextStyle>;
				contentContainerStyle?: StyleProp<ViewStyle>;
				style?: StyleProp<ViewStyle>;
				gap?: number | undefined;
			}
	) => (
		<View>
			<TabBar
				{...props}
				indicatorStyle={{
					backgroundColor: PRIMARY_COLOR,
				}}
				scrollEnabled
				style={{
					backgroundColor: Colors.light.background,
				}}
				renderLabel={({ route, focused }) => {
					return (
						<Text
							style={{
								color: focused ? PRIMARY_COLOR : Colors.dark.background,
							}}
						>
							{route.title}
						</Text>
					);
				}}
			/>
		</View>
	);

	return (
		<View style={styles.alertContainer}>
			<View style={styles.flexWrapper}>
				<Text style={styles.title}>Latest Incidents</Text>
				<Pressable style={styles.button} onPress={() => navigation.navigate('IncidentTab')}>
					<Text style={styles.buttonText}>View all</Text>
					<AntDesign name="doubleright" size={15} color="#346499" />
				</Pressable>
			</View>
			<View style={styles.tabWrapper}>
				<TabView
					navigationState={{ index, routes }}
					renderScene={renderScene}
					onIndexChange={setIndex}
					initialLayout={{ width: layout.width }}
					style={styles.tab}
					renderTabBar={renderTabBar}
				/>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	alertContainer: {
		marginHorizontal: 16,
		marginVertical: 25,
		maxHeight: 160,
		marginTop: 40,
	},
	tabWrapper: {
		flexDirection: 'row',
		width: '100%',
		height: '100%',
	},
	tab: {
		marginTop: 10,
		height: 150,
	},
	flexWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	button: {
		backgroundColor: 'transparent',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
	},
	buttonText: {
		fontFamily: FONT_NORMAL,
		color: PRIMARY_COLOR,
		fontSize: 13,
		marginRight: 6,
	},
	title: {
		fontWeight: '700',
		color: PRIMARY_COLOR,
		fontSize: 18,
		lineHeight: 22,
	},
	errorContainer: {
		width: '100%',
		// alignItems: "flex-end",
	},
	empty: {
		padding: 8,
		backgroundColor: '#F5F7FB',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		marginTop: 10,
		borderRadius: 4,
		height: 100,
	},
	routeEmpty: {
		height: 100,
	},
	emptyText: {
		fontFamily: FONT_BOLD,
		fontSize: 18,
		color: '#346499',
		marginLeft: 6,
	},
});
