import { useEffect, useState } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';

import {
	FONT_BOLD,
	FONT_LIGHT,
	FONT_MEDIUM,
	FONT_NORMAL,
	FONT_SEMI_BOLD,
} from '../constants/Fonts';

export default function useCachedResources() {
	const [isLoadingComplete, setLoadingComplete] = useState(false);

	// Load any resources or data that we need prior to rendering the app
	useEffect(() => {
		async function loadResourcesAndDataAsync() {
			try {
				SplashScreen.preventAutoHideAsync();

				// Load fonts
				await Font.loadAsync({
					...FontAwesome.font,
					[FONT_LIGHT]: require('../../../assets/fonts/Mulish-Light.ttf'),
					[FONT_NORMAL]: require('../../../assets/fonts/Mulish-Regular.ttf'),
					[FONT_MEDIUM]: require('../../../assets/fonts/Mulish-Medium.ttf'),
					[FONT_SEMI_BOLD]: require('../../../assets/fonts/Mulish-SemiBold.ttf'),
					[FONT_BOLD]: require('../../../assets/fonts/Mulish-Bold.ttf'),
				});
			} catch (e) {
				// We might want to provide this error information to an error reporting service
				console.warn(e);
			} finally {
				setLoadingComplete(true);
				SplashScreen.hideAsync();
			}
		}

		loadResourcesAndDataAsync();
	}, []);

	return isLoadingComplete;
}
