import { useEffect, useState } from 'react';
import { Alert, Pressable, StyleSheet, Text } from 'react-native';

import { PRIMARY_COLOR } from '@/common/constants/Colors';
import { COMMON, LOGIN, OFFICIAL, PERMANENT, REGISTER } from '@/common/constants/Constants';
import { FONT_BOLD } from '@/common/constants/Fonts';
import { sharedStyles } from '@/common/constants/SharedStyles';

import Header from '@/components/OnboardingHeader';
import { View } from '@/components/Themed';

import { authType } from '@/redux/slices/auth';
import { userType } from '@/redux/slices/user';
import { useDispatch, useSelector } from '@/redux/store';

export default function ChooseScreen({ navigation }: { navigation: any }) {
	const [selectedAuthType, setAuthType] = useState<string | null>(null);

	const authDetails = useSelector((state) => state.auth.value);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			userType({
				email: '',
			})
		);
	}, [dispatch]);

	const handleNext = () => {
		if (selectedAuthType === null) {
			Alert.alert('Alert!', 'Please select Signup/Login', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else {
			dispatch(
				authType({
					type: selectedAuthType,
					userType: authDetails.userType,
					officialType: authDetails.officialType,
				})
			);
			if (authDetails.userType === OFFICIAL) {
				if (authDetails.officialType === PERMANENT) {
					if (selectedAuthType === REGISTER) {
						navigation.navigate('PVerifyDetails');
					} else {
						navigation.navigate('VerifyContact');
					}
				} else {
					navigation.navigate('VerifyContact');
				}
			} else if (authDetails.userType === COMMON) {
				navigation.navigate('VerifyContact');
			}
		}
	};
	const handleBack = () => {
		navigation.pop();
	};

	return (
		<View style={styles.mainWrapper}>
			<Header />
			<View style={styles.container}>
				<Text style={styles.textStyle}>
					Please choose
					<Text style={styles.textRed}>*</Text>
				</Text>
				<Pressable
					style={selectedAuthType === REGISTER ? styles.selected : styles.selectButton}
					onPress={() => setAuthType(REGISTER)}
				>
					<Text
						style={
							selectedAuthType === REGISTER ? styles.selectedText : styles.selectText
						}
					>
						Sign up
					</Text>
				</Pressable>
				<Pressable
					style={selectedAuthType === LOGIN ? styles.selected : styles.selectButton}
					onPress={() => setAuthType(LOGIN)}
				>
					<Text
						style={selectedAuthType === LOGIN ? styles.selectedText : styles.selectText}
					>
						Login
					</Text>
				</Pressable>
			</View>
			<View style={[sharedStyles.btnWrapper, styles.btnWrapper]}>
				<Pressable style={sharedStyles.skipButton} onPress={handleBack}>
					<Text style={[sharedStyles.skipButtonText, styles.skipButtonText]}>Back</Text>
				</Pressable>
				<Pressable style={styles.button} onPress={handleNext}>
					<Text style={styles.buttonText}>Next</Text>
				</Pressable>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	mainWrapper: {
		flex: 1,
		flexDirection: 'column',
	},
	container: {
		width: '100%',
		padding: 16,
		flex: 1,
		justifyContent: 'center',
		marginTop: 35,
	},
	textStyle: {
		fontSize: 18,
		lineHeight: 22,
		color: PRIMARY_COLOR,
		fontWeight: '700',
		marginBottom: 10,
	},
	button: {
		backgroundColor: PRIMARY_COLOR,
		padding: 16,
		borderRadius: 4,
		width: '46%',
		color: 'white',
	},
	buttonText: {
		color: 'white',
		fontSize: 18,
		fontFamily: FONT_BOLD,
		textAlign: 'center',
	},
	skipButtonText: {
		fontSize: 18,
	},
	btnWrapper: {
		flex: 1,
		width: '100%',
		padding: 16,
		marginBottom: 20,
		marginTop: 0,
	},
	selectButton: {
		width: '100%',
		backgroundColor: '#F5F7FB',
		padding: 16,
		borderRadius: 4,
		marginVertical: 8,
	},
	selectText: {
		fontSize: 18,
		fontWeight: '700',
		lineHeight: 22,
		color: '#55575A',
	},
	selected: {
		width: '100%',
		backgroundColor: PRIMARY_COLOR,
		padding: 16,
		borderRadius: 4,
		marginVertical: 10,
	},
	selectedText: {
		fontSize: 18,
		fontWeight: '700',
		lineHeight: 22,
		color: 'white',
	},
	textRed: {
		color: 'red',
	},
});
