import { useCallback, useEffect, useRef, useState } from 'react';
import {
	Dimensions,
	FlatList,
	Image,
	Modal,
	SafeAreaView,
	StyleSheet,
	TouchableOpacity,
	View,
} from 'react-native';
import GestureRecognizer from 'react-native-swipe-gestures';
import { AntDesign } from '@expo/vector-icons';

import { PRIMARY_COLOR } from '../../common/constants/Colors';

import Header from './Header';
import ImagePreview from './ImagePreview';
import { ImageObject, IProps, RenderImageProps } from './types';

const { height: deviceHeight, width: deviceWidth } = Dimensions.get('window');

const defaultProps = {
	hideThumbs: false,
	resizeMode: 'contain',
	thumbColor: '#d9b44a',
	thumbResizeMode: 'cover',
	thumbSize: 48,
};

const ImageGallery = (props: IProps & typeof defaultProps) => {
	const {
		close,
		hideThumbs,
		images,
		initialIndex,
		isOpen,
		renderCustomImage,
		renderCustomThumb,
		renderFooterComponent,
		resizeMode,
		thumbColor,
		thumbResizeMode,
		thumbSize,
	} = props;

	const [activeIndex, setActiveIndex] = useState(0);
	const topRef = useRef<FlatList>(null);
	const bottomRef = useRef<FlatList>(null);

	const keyExtractorThumb = (item: ImageObject, index: number) =>
		item && item.id ? item.id.toString() : index.toString();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const keyExtractorImage = (item: ImageObject, index: number) =>
		item && item.id ? item.id.toString() : index.toString();

	const scrollToIndex = (i: number) => {
		setActiveIndex(i);

		if (topRef?.current) {
			topRef.current.scrollToIndex({
				animated: true,
				index: i,
			});
		}
		if (bottomRef?.current) {
			if (i * (thumbSize + 10) - thumbSize / 2 > deviceWidth / 2) {
				bottomRef?.current?.scrollToIndex({
					animated: true,
					index: i,
				});
			} else {
				bottomRef?.current?.scrollToIndex({
					animated: true,
					index: 0,
				});
			}
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const renderItem = ({ item, index }: RenderImageProps) => {
		return (
			<ImagePreview
				index={index}
				isSelected={activeIndex === index}
				item={item}
				resizeMode={resizeMode}
				renderCustomImage={renderCustomImage}
				next={() => {
					setActiveIndex(activeIndex + 1);
					console.log(activeIndex);
				}}
				prev={() => setActiveIndex(activeIndex - 1)}
				length={images.length}
			/>
		);
	};

	const renderThumb = ({ item, index }: RenderImageProps) => {
		let type = item.mime_type.split('/')[0];
		return (
			<TouchableOpacity onPress={() => scrollToIndex(index)} activeOpacity={0.8}>
				{renderCustomThumb ? (
					renderCustomThumb(item, index, activeIndex === index)
				) : type === 'image' ? (
					<Image
						resizeMode={thumbResizeMode}
						style={
							activeIndex === index
								? [
										styles.thumb,
										styles.activeThumb,
										{ borderColor: thumbColor },
										{ width: thumbSize, height: thumbSize },
								  ]
								: [styles.thumb, { width: thumbSize, height: thumbSize }]
						}
						source={{
							uri: item.thumbnail_url,
						}}
					/>
				) : (
					<View style={styles.logoWrapper}>
						<Image
							resizeMode={thumbResizeMode}
							style={
								activeIndex === index
									? [
											styles.thumb,
											styles.activeThumb,
											{ borderColor: thumbColor },
											{ width: thumbSize, height: thumbSize },
									  ]
									: [styles.thumb, { width: thumbSize, height: thumbSize }]
							}
							source={{
								uri: item.thumbnail_url,
							}}
						/>
						<AntDesign
							name="play"
							style={styles.playIcon}
							size={24}
							color={PRIMARY_COLOR}
						/>
					</View>
				)}
			</TouchableOpacity>
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onMomentumEnd = (e: any) => {
		const { x } = e.nativeEvent.contentOffset;
		scrollToIndex(Math.round(x / deviceWidth));
	};

	useEffect(() => {
		if (isOpen && initialIndex) {
			setActiveIndex(initialIndex);
		} else if (!isOpen) {
			setActiveIndex(0);
		}
	}, [isOpen, initialIndex]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const getImageLayout = useCallback((_: any, index: any) => {
		return {
			index,
			length: deviceWidth,
			offset: deviceWidth * index,
		};
	}, []);

	const getThumbLayout = useCallback(
		(_: any, index: any) => {
			return {
				index,
				length: thumbSize,
				offset: thumbSize * index,
			};
		},
		[thumbSize]
	);

	return (
		<SafeAreaView style={styles.safeAreaContainer}>
			<Modal
				animationType={isOpen ? 'slide' : 'fade'}
				visible={isOpen}
				transparent
				statusBarTranslucent={true}
			>
				<View style={styles.container}>
					<GestureRecognizer
						onSwipeRight={() => {
							if (activeIndex > 0) {
								setActiveIndex(activeIndex - 1);
							}
						}}
						onSwipeLeft={() => {
							if (activeIndex < images.length - 1) {
								setActiveIndex(activeIndex + 1);
							}
						}}
						onSwipeDown={close}
					>
						<ImagePreview
							index={activeIndex}
							isSelected={true}
							item={images[activeIndex]}
							resizeMode={resizeMode}
							renderCustomImage={renderCustomImage}
							next={() => {
								setActiveIndex(activeIndex + 1);
							}}
							prev={() => setActiveIndex(activeIndex - 1)}
							length={images.length}
						/>
					</GestureRecognizer>
					{hideThumbs ? null : (
						<FlatList
							initialScrollIndex={initialIndex}
							getItemLayout={getThumbLayout}
							contentContainerStyle={styles.thumbnailListContainer}
							data={props.images}
							horizontal
							keyExtractor={keyExtractorThumb}
							pagingEnabled
							ref={bottomRef}
							renderItem={renderThumb}
							showsHorizontalScrollIndicator={false}
							style={[styles.bottomFlatlist, { bottom: thumbSize }]}
						/>
					)}

					<View style={styles.header}>
						<Header close={close} />
					</View>
					{renderFooterComponent ? (
						<View style={styles.footer}>
							{renderFooterComponent(images[activeIndex], activeIndex)}
						</View>
					) : null}
				</View>
			</Modal>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.9)',
		flex: 1,
		height: deviceHeight,
		justifyContent: 'center',
		width: deviceWidth,
	},

	header: {
		position: 'absolute',
		top: 0,
		width: '100%',
	},
	footer: {
		bottom: 0,
		position: 'absolute',
		width: '100%',
	},
	activeThumb: {
		borderWidth: 3,
	},
	thumb: {
		borderRadius: 4,
		marginRight: 10,
	},
	thumbnailListContainer: {
		paddingHorizontal: 15,
	},
	bottomFlatlist: {
		position: 'absolute',
	},
	safeAreaContainer: {
		flex: 1,
	},
	logoWrapper: {
		width: 70,
		height: 70,
		borderRadius: 4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	playIcon: {
		position: 'absolute',
		left: 19,
		top: 25,
	},
});

ImageGallery.defaultProps = defaultProps;

export default ImageGallery;
