import { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Image, Pressable, StyleSheet, Text } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

import { PRIMARY_COLOR } from '../common/constants/Colors';
import { FONT_BOLD, FONT_SEMI_BOLD } from '../common/constants/Fonts';
import axios from '../common/utils/axios';

import { View } from './Themed';

type ProfilePicture = {
	original_url: string | null;
};

type Props = {
	refresh?: boolean;
	setRefresh?: () => void;
	navigation: any;
};

export default function ProfileHeader({ navigation, setRefresh }: Props) {
	// const [locationOpen, setLocationOpen] = useState(false);
	// const [locationValue, setLocationValue] = useState("Patna");
	// const [currentLocation, setCurrentLocation] = useState("Patna");
	// const [location, setLocation] = useState<any[]>([]);
	const isMounted = useRef(false);
	const [profilePicture, setProfilePicture] = useState<ProfilePicture | null>(null);

	useFocusEffect(
		useCallback(() => {
			const getAlertDetails = async () => {
				try {
					await axios.get('/api/user/profile-picture').then((response) => {
						if (!isMounted.current) {
							const { data } = response.data;

							if (data.original_url) {
								setProfilePicture(data);
							} else {
								setProfilePicture(null);
							}
							setRefresh && setRefresh();
						}
					});
				} catch (error: any) {
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			};
			getAlertDetails();
		}, [setRefresh])
	);

	useEffect(
		() => () => {
			isMounted.current = true;
		},
		[]
	);

	// useEffect(() => {
	//     const getAreaList = async () => {
	//         try {
	//             await axios.get("/api/incident/arealist").then((response) => {
	//                 if (!isMounted.current) {
	//                     const { data } = response.data;
	//                     let updated = data.data.map((location: Location) => {
	//                         return { ...location, value: location.label };
	//                     });
	//                     setLocation(updated);
	//                 }
	//             });
	//         } catch (error: any) {
	//             Alert.alert(
	//                 "Alert!",
	//                 error.message ? error.message : "Something went wrong!",
	//                 [
	//                     {
	//                         text: "Cancel",
	//                         style: "cancel",
	//                     },
	//                     {
	//                         text: "OK",
	//                     },
	//                 ]
	//             );
	//         }
	//     };
	//     getAreaList();
	// }, []);

	return (
		<View style={styles.container}>
			<Image style={styles.logo} source={require('../../assets/images/icon.png')} />

			<View style={styles.rightWrapper}>
				{/* <Text style={styles.locationIcon}>
                    <Ionicons name="location-sharp" size={18} color="white" />
                </Text>

                <Text style={styles.text}>{currentLocation}</Text> */}
				{/* <Text onPress={() => setModalVisible(true)}> */}
				<Text onPress={() => {}}>
					{/* <Ionicons name="notifications" size={24} color="white" /> */}
				</Text>
				<Pressable onPress={() => navigation.navigate('ProfileTab')}>
					<Image
						style={styles.profileImg}
						source={
							profilePicture === null
								? require('../../assets/images/user-variant.png')
								: { uri: profilePicture.original_url }
						}
					/>
				</Pressable>
			</View>
			{/* <Modal
                animationType="fade"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                    Alert.alert("Modal has been closed.");
                    setModalVisible(!modalVisible);
                }}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.title}>Notifications</Text>
                        <Controller
                            name="location"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <DropDownPicker
                                    style={styles.input}
                                    open={locationOpen}
                                    value={locationValue}
                                    items={location}
                                    setOpen={setLocationOpen}
                                    setValue={setLocationValue}
                                    setItems={setLocation}
                                    onChangeValue={onChange}
                                    zIndex={1000}
                                    zIndexInverse={3000}
                                    placeholder="Select"
                                    placeholderStyle={{
                                        color: "#A7A7A7",
                                    }}
                                    dropDownContainerStyle={{
                                        borderColor: "#A7A7A7",
                                    }}
                                />
                            )}
                        />
                        <View style={styles.buttonWrapper}>
                            <Pressable
                                style={[styles.button, styles.closeButton]}
                                onPress={() => setModalVisible(!modalVisible)}
                            >
                                <Text
                                    style={[
                                        styles.textStyle,
                                        styles.closeBtnText,
                                    ]}
                                >
                                    Close
                                </Text>
                            </Pressable>
                            <Pressable
                                style={[styles.button, styles.actionButton]}
                                onPress={() => {
                                    setCurrentLocation(locationValue);
                                    setModalVisible(!modalVisible);
                                }}
                            >
                                <Text
                                    style={[
                                        styles.textStyle,
                                        styles.actionBtnText,
                                    ]}
                                >
                                    Update
                                </Text>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </Modal> */}
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: PRIMARY_COLOR,
		width: '100%',
		paddingTop: 50,
		paddingHorizontal: 16,
		paddingBottom: 16,
		flexDirection: 'row',
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#00000080',
	},
	title: {
		fontSize: 21,
		fontFamily: FONT_BOLD,
		color: PRIMARY_COLOR,
	},
	input: {
		height: 50,
		marginVertical: 10,
		padding: 10,
		width: '100%',
		backgroundColor: '#FFFFFF',
		borderRadius: 7,
		borderWidth: 1.02,
		borderColor: '#DFE0EB',
		borderStyle: 'solid',
	},
	buttonWrapper: {
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'flex-end',
		marginTop: 8,
		backgroundColor: 'transparent',
	},

	logo: {
		width: 100,
		height: 50,
	},
	rightWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		backgroundColor: 'transparent',
		flexDirection: 'row',
	},
	text: {
		color: '#fff',
		paddingLeft: 6,
		paddingRight: 13,
	},
	locationIcon: {
		color: '#fff',
	},
	profileImg: {
		width: 35,
		height: 35,
		marginRight: 8,
		marginLeft: 13,
		borderRadius: 50,
	},
	modalView: {
		margin: 20,
		backgroundColor: '#F5F7FB',
		borderRadius: 4,
		padding: 14,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	button: {
		borderRadius: 4,
		paddingVertical: 10,
		paddingHorizontal: 20,
		elevation: 2,
	},
	closeButton: {
		backgroundColor: 'white',
		borderWidth: 1,
		borderColor: PRIMARY_COLOR,
		marginRight: 10,
	},
	actionButton: {
		backgroundColor: PRIMARY_COLOR,
	},
	closeBtnText: {
		color: PRIMARY_COLOR,
	},
	actionBtnText: {
		color: 'white',
	},
	textStyle: {
		fontFamily: FONT_SEMI_BOLD,
		textAlign: 'center',
	},
});
