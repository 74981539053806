import { useEffect } from 'react';
import { useCallback, useRef, useState } from 'react';
import { Alert, Image, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';

//constants
import { PRIMARY_COLOR } from '../../common/constants/Colors';
import { FONT_NORMAL } from '../../common/constants/Fonts';
import axios from '../../common/utils/axios';
import { locationDetails } from '../../redux/slices/location';
import { dispatch, useSelector } from '../../redux/store';
import { AlertProps } from '../../screens/domain/HomeTabScreen';
import HomeAlertsNotifcationsCard from '../alerts-notification/AlertNotifications';

import DistrictMap from './DistrictMap';
import DropdownModal from './DropdownModal';
import IncidentCard, { InicidentProps } from './IncidentCard';
import { RouteType, WaterDichargeDetails, WaterLevelDetails } from './OfficialDashboard';
import RainfallData from './RainfallData';
import WaterDischage from './WaterDischage';
import WaterLevel from './WaterLevel';
import Weather from './Weather';

export default function CommonDashboard({ navigation }: { navigation: any }) {
	const [alerts, setAlerts] = useState<AlertProps[]>([]);
	const [incidents, setincidents] = useState<InicidentProps[]>([]);
	const isMounted = useRef(false);
	const [isOpen, setOpen] = useState<boolean>(false);
	const LocationDetails = useSelector((state) => state.locationLabel.value);
	const [waterLevelRoutes, setWaterLevelRoutes] = useState<RouteType[]>([]);
	const [river, setRiver] = useState('');

	const { river_id, riverType, refreshLocation, riverName, blockId, districtId } = useSelector(
		(state: any) => state.location.value
	);

	const [waterLevelDetails, setWaterLevelDetails] = useState<WaterLevelDetails | undefined>();
	const [waterDischargeDetails, setWaterDischargeDetails] = useState<
		WaterDichargeDetails | undefined
	>();

	useFocusEffect(
		useCallback(() => {
			if (river_id && river_id !== '' && riverType && riverType !== '') {
				getBarrage();
			}
			getAlertDetails();
			getIncidentDetails();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [riverType, river_id])
	);

	useEffect(() => {
		if (river_id && river_id !== '' && riverType && riverType !== '') {
			getBarrage();
		}
		getAlertDetails();
		getIncidentDetails();
		dispatch(
			locationDetails({
				districtId: districtId,
				blockId: blockId,
				river_id: river_id,
				riverName: riverName,
				riverType: riverType,
				refreshLocation: false,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshLocation]);

	const getBarrage = async () => {
		if (river_id && river_id !== '' && riverType && riverType !== '') {
			const params = {
				river_id: river_id && river_id.toString(),
				type: riverType && riverType,
			};
			try {
				await axios
					.get('/api/dashboard/getbarrage', {
						params,
					})
					.then((response) => {
						if (!isMounted.current) {
							const { data } = response.data;
							if (Object.keys(data).length) {
								if (data[riverType].length > 0) {
									setRiver(data.river);
									setWaterLevelRoutes(data[riverType]);
									getWaterLevel(data[riverType][0].key);
								} else {
									setWaterLevelRoutes([]);
									setWaterDischargeDetails(undefined);
									setWaterLevelDetails(undefined);
								}
							} else {
								setWaterLevelRoutes([]);
								setWaterDischargeDetails(undefined);
								setWaterLevelDetails(undefined);
							}
						}
					});
			} catch (error: any) {
				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			}
		}
	};

	const getWaterLevel = async (id: string) => {
		const params = {
			type_id: id,
			type: riverType && riverType,
			river_id: river_id,
		};
		try {
			await axios
				.get('/api/dashboard/waterlevel', {
					params,
				})
				.then((response) => {
					if (!isMounted.current) {
						const { data } = response.data;
						if (Object.keys(data).length) {
							if (data.water_discharge.length !== 0) {
								setWaterDischargeDetails(data.water_discharge);
							} else {
								setWaterDischargeDetails(undefined);
							}
							setWaterLevelDetails(data.water_level);
						} else {
							setWaterLevelDetails(undefined);
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	// const getAlertDetails = async () => {
	//     try {

	//         await axios.get("/api/alerts/alert-list/custom", {
	//             params:{
	//                 // id:"1000002",
	//                 district_id: districtId && districtId.toString(),
	//                 block_id: blockId && blockId.toString()
	//             }
	//         }).then((response) => {
	//             const { data } = response.data;
	//             if(data.length!==0){
	//                 setAlerts(data);
	//             }else{
	//                 setAlerts([])
	//             }
	//         })
	//     } catch (error: any) {
	//         Alert.alert(
	//             "Alert!",
	//             error.message ? error.message : "Something went wrong!",
	//             [
	//                 {
	//                     text: "Cancel",
	//                     style: "cancel",
	//                 },
	//                 {
	//                     text: "OK",
	//                 },
	//             ]
	//         );
	//     }
	// };
	const getAlertDetails = async () => {
		try {
			await axios.get('/api/alerts/alert-list/all').then((response) => {
				const { data } = response.data;
				if (data.length !== 0) {
					setAlerts(data);
				} else {
					setAlerts([]);
				}
			});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const getIncidentDetails = async () => {
		try {
			await axios
				.post('/api/dashboard/incident-dashboard', {
					params: { page: 1 },
					type: 'all',
					limit: '1',
				})
				.then((response) => {
					const { data } = response.data;
					if (data) {
						setincidents(data);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	return (
		<ScrollView style={styles.mainContainer}>
			<View style={styles.mainContainer}>
				<View style={styles.welcomeBanner}>
					<Image
						style={styles.user}
						source={require('../../../assets/images/User1.png')}
					/>
					<Image
						style={styles.user}
						source={require('../../../assets/images/User2.png')}
					/>
					<Text style={styles.text}>An Initiative by Bihar Government</Text>
				</View>
				<View style={styles.jurisWrapper}>
					<View style={styles.jurisHead}>
						<Text style={styles.jurisHeading}>Location Details</Text>
						<Pressable onPress={() => setOpen(true)}>
							<Text style={styles.chooseTxt}>Edit</Text>
						</Pressable>
					</View>
					<View style={styles.flex}>
						<View style={styles.flexContainer}>
							<Text style={styles.key}>District</Text>
							<Text style={styles.value}>
								{LocationDetails.district !== '' ? LocationDetails.district : '-'}
							</Text>
						</View>
						<View style={styles.flexContainer}>
							<Text style={styles.key}>Block</Text>
							<Text style={styles.value}>
								{LocationDetails.block !== '' ? LocationDetails.block : '-'}
							</Text>
						</View>
						<View style={styles.flexContainer}>
							<Text style={styles.key}>Village</Text>
							<Text style={styles.value}>
								{LocationDetails.village !== '' ? LocationDetails.village : '-'}
							</Text>
						</View>
					</View>
				</View>
				<DropdownModal openState={isOpen} handleClose={() => setOpen(false)} />
				{alerts && alerts.length > 0 && (
					<View style={styles.alertContainer}>
						<View style={styles.flexWrapper}>
							<Text style={[styles.title, styles.topSpace]}>Alerts & Warnings</Text>
							<Pressable
								style={styles.button}
								onPress={() => navigation.navigate('AlertTab')}
							>
								<Text style={styles.buttonText}>View all</Text>
								<AntDesign name="doubleright" size={15} color="#346499" />
							</Pressable>
						</View>
						<HomeAlertsNotifcationsCard alert={alerts && alerts[0]} />
					</View>
				)}

				{incidents && incidents.length > 0 && (
					<View style={styles.incidentContainer}>
						<View style={styles.flexWrapper}>
							<Text style={[styles.title, styles.topSpace]}>Incidents</Text>
							<Pressable
								style={styles.button}
								onPress={() => navigation.navigate('IncidentTab')}
							>
								<Text style={styles.buttonText}>View all</Text>
								<AntDesign name="doubleright" size={15} color="#346499" />
							</Pressable>
						</View>
						<IncidentCard
							title={incidents[0].title && incidents[0].title}
							status={incidents[0].status && incidents[0].status}
							assigned_by={''}
							assigned={''}
							reported_by={''}
							village={incidents[0].village && incidents[0].village}
							block={incidents[0].block && incidents[0].block}
							district={incidents[0].district && incidents[0].district}
							created_at={incidents[0].created_at && incidents[0].created_at}
							updated_at={incidents[0].updated_at && incidents[0].updated_at}
						/>
					</View>
				)}
				<View style={styles.wrapper}>
					<Text style={[styles.title, styles.topSpace]}>Your District Map</Text>
					<DistrictMap
						open={() => setOpen(true)}
						currRiver={river}
						waterLevelRoute={waterLevelRoutes}
					/>
					<Text style={StyleSheet.flatten([styles.title, { marginTop: 10 }])}>
						Information Products
					</Text>

					<Text style={[styles.title, styles.topSpace]}>Rainfall Data</Text>
					<RainfallData open={() => setOpen(true)} />
					<View style={[styles.flexWrapper, styles.topSpace]}>
						<Text style={[styles.title]}>Water Level</Text>
						{waterLevelDetails === undefined ||
						Object.keys(waterLevelDetails).length === 0 ? (
							<Pressable onPress={() => setOpen(true)}>
								<Text style={styles.editText}>Edit Location Details</Text>
							</Pressable>
						) : null}
					</View>
					<WaterLevel
						currRiver={river}
						waterLevelRoute={waterLevelRoutes}
						waterLevelData={waterLevelDetails}
						onTabChange={(id: any) => {
							getWaterLevel(id);
						}}
						open={() => setOpen(true)}
					/>
					{riverType && riverType !== 'station' && (
						<>
							<Text style={[styles.title, styles.topSpace]}>Water Discharge</Text>
							<WaterDischage waterDischargeData={waterDischargeDetails} />
						</>
					)}
					<Text style={[styles.title, styles.topSpace]}>Weather</Text>
					<Weather open={() => setOpen(true)} />
				</View>
			</View>
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
		backgroundColor: 'white',
		// paddingBottom:'60%'
		paddingBottom: 30,
	},
	title: {
		fontWeight: '700',
		color: PRIMARY_COLOR,
		fontSize: 18,
		lineHeight: 22,
	},
	topSpace: {
		marginTop: 20,
	},
	editText: {
		fontSize: 12,
		// lineHeight: 15,
		fontWeight: 'normal',
		color: PRIMARY_COLOR,
		textDecorationLine: 'underline',
	},
	button: {
		backgroundColor: 'transparent',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
	},
	buttonText: {
		fontFamily: FONT_NORMAL,
		color: PRIMARY_COLOR,
		fontSize: 13,
		marginRight: 6,
	},

	//admin dashboard
	jurisHeading: {
		fontSize: 16,
		lineHeight: 20,
		color: PRIMARY_COLOR,
	},
	welcomeBanner: {
		backgroundColor: '#F5F7FB',
		padding: 12,
		marginBottom: 30,
		flexDirection: 'row',
		alignItems: 'center',
	},
	jurisHead: {
		backgroundColor: '#F5F7FB',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	jurisWrapper: {
		backgroundColor: '#F5F7FB',
		padding: 10,
	},
	chooseTxt: {
		textDecorationLine: 'underline',
		fontWeight: '600',
		lineHeight: 20,
		fontSize: 16,
	},
	alertContainer: {
		marginHorizontal: 16,
		marginTop: 10,
	},
	incidentContainer: {
		marginHorizontal: 16,
	},
	flexWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	wrapper: {
		backgroundColor: 'white',
		paddingHorizontal: 16,
	},
	text: {
		fontSize: 11,
		fontWeight: '300',
		paddingLeft: 13,
		fontFamily: FONT_NORMAL,
	},
	user: {
		width: 30,
		height: 30,
	},
	key: {
		fontSize: 12,
		fontWeight: '300',
		lineHeight: 15,
		marginRight: 5,
	},
	value: {
		fontSize: 12,
		fontWeight: '300',
		lineHeight: 15,
		color: '#55575A',
	},
	flexContainer: {
		flexDirection: 'row',
		marginRight: 20,
	},
	flex: {
		flexDirection: 'row',
		marginTop: 10,
	},
});
