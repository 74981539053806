import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider as ReduxProvider } from 'react-redux';
import { StatusBar } from 'expo-status-bar';

import { AuthProvider } from './src/common/contexts/JWTContext';
import useCachedResources from './src/common/hooks/useCachedResources';
import Navigation from './src/navigation';
import { store } from './src/redux/store';

export default function App() {
	const isLoadingComplete = useCachedResources();

	if (!isLoadingComplete) {
		return null;
	}

	return (
		<AuthProvider>
			<ReduxProvider store={store}>
				<SafeAreaProvider>
					<StatusBar animated style="light" />
					<Navigation />
				</SafeAreaProvider>
			</ReduxProvider>
		</AuthProvider>
	);
}
