import { useEffect } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';

//constants
import { PRIMARY_COLOR } from '../../common/constants/Colors';
import { Text, View } from '../../components/Themed';

export default function LoadingScreen({ navigation }: { navigation: any }) {
	useEffect(() => {
		let loadingTimeout = setTimeout(() => navigation.navigate('Root'), 1000);

		return () => {
			clearTimeout(loadingTimeout);
		};
	}, [navigation]);

	return (
		<View style={styles.loadingWrapper}>
			<ActivityIndicator color={PRIMARY_COLOR} size="large" />
			<Text>Loading...</Text>
		</View>
	);
}

const styles = StyleSheet.create({
	loadingWrapper: {
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
});
