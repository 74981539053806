import { Dimensions, Image, Modal, Pressable, StyleSheet, Text, View } from 'react-native';

//constant
import { FONT_BOLD } from '../../common/constants/Fonts';

type Props = {
	openState: boolean;
	onClose: () => void;
	reportAnother: () => void;
};

const { height: deviceHeight, width: deviceWidth } = Dimensions.get('window');

export default function ReportSubmitModal(props: Props) {
	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={props.openState}
			onRequestClose={() => props.onClose()}
		>
			<View style={styles.container}>
				<View style={styles.innerContainer}>
					<View style={styles.flexWrapper}>
						<Image
							style={styles.correct}
							source={require('../../../assets/images/Correct.png')}
						/>
					</View>
					<Text style={styles.title}>Thank you for reporting the incident.</Text>
					<Text style={styles.SubTitle}>
						Bihar Government really appreciates this action.
					</Text>
					<Text style={styles.dialogPara}>
						Your report has been succesfully sent to relevant authorities for review and
						investigation.
					</Text>

					<Pressable style={styles.dialogReportBtn} onPress={props.reportAnother}>
						<Text style={styles.reportBtnText}>Report another incident</Text>
					</Pressable>
					<Pressable style={styles.dialogGoBack} onPress={props.onClose}>
						<Text style={styles.dialogGoBackText}>Go back</Text>
					</Pressable>
				</View>
			</View>
		</Modal>
	);
}

const styles = StyleSheet.create({
	correct: {
		height: 100,
		width: 100,
		marginBottom: 20,
	},
	flexWrapper: {
		alignItems: 'center',
	},
	title: {
		fontSize: 22,
		fontFamily: FONT_BOLD,
		color: '#FFFFFF',
		textAlign: 'center',
		marginBottom: 12,
	},
	SubTitle: {
		color: '#FFFFFF',
		fontFamily: FONT_BOLD,
		fontSize: 15.82,
		textAlign: 'center',
		marginBottom: 12,
	},
	dialogPara: {
		color: '#FFFFFF',
		paddingHorizontal: 15,
		fontSize: 15.82,
		textAlign: 'center',
	},
	dialogReportBtn: {
		backgroundColor: '#AF2525',
		borderRadius: 4,
		textAlign: 'center',
		padding: 10,
		marginTop: 16,
	},
	dialogGoBack: {
		backgroundColor: 'transparent',
		borderWidth: 1,
		borderRadius: 4,
		borderColor: '#FFFFFF',
		borderStyle: 'solid',
		padding: 10,
		marginTop: 16,
	},
	dialogGoBackText: {
		fontFamily: FONT_BOLD,
		fontSize: 21,
		lineHeight: 26,
		textAlign: 'center',
		color: '#FFFFFF',
	},
	reportBtnText: {
		color: '#FFFFFF',
		textAlign: 'center',
	},
	container: {
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
		flex: 1,
		height: deviceHeight,
		width: deviceWidth,
	},
	innerContainer: {
		backgroundColor: '#346499',
		marginTop: '53%',
		marginHorizontal: 16,
		paddingVertical: 40,
		paddingHorizontal: 20,
	},
});
