import { createSlice } from '@reduxjs/toolkit';

export const nepalSlice = createSlice({
	name: 'nepal',
	initialState: {
		value: {
			basinId: '',
			basinName: '',
			stationId: '',
			stationName: '',
		},
	},
	reducers: {
		nepalDetails: (state, action) => {
			state.value = action.payload;
		},
	},
});
export const { nepalDetails } = nepalSlice.actions;

export default nepalSlice.reducer;
