import { useEffect, useRef, useState } from 'react';
import {
	ActivityIndicator,
	Alert,
	Image,
	Pressable,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
	View,
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';

import CGeneralDetails from '@Images/svg/CGeneralDetails';
import PGeneralDetails from '@Images/svg/PGeneralDetails';

import Colors, { PRIMARY_COLOR } from '@/common/constants/Colors';
import { COMMON, OFFICIAL, PERMANENT } from '@/common/constants/Constants';
import { FONT_MEDIUM } from '@/common/constants/Fonts';
import { sharedStyles } from '@/common/constants/SharedStyles';
import useAuth from '@/common/hooks/useAuth';
import axios from '@/common/utils/axios';

import Header from '@/components/OnboardingHeader';

import { useSelector } from '@/redux/store';

//constants
type GeneralDetailsType = {
	full_name: string;
	designation: {
		label: string;
	};
	jurisdiction: {
		zone: {
			label: string;
		};
		circle: {
			label: string;
		};
		division: {
			label: string;
		};
		sub_division: {
			label: string;
		};
		section: {
			label: string;
		};
	};
};
export default function CommonDetailsScreen({ navigation }: { navigation: any }) {
	const [image, setImage] = useState<string | any>('');
	const [generalDetails, setGeneralDetails] = useState<GeneralDetailsType>({
		full_name: 'User',
		designation: {
			label: 'N/A',
		},
		jurisdiction: {
			zone: {
				label: 'N/A',
			},
			circle: {
				label: 'N/A',
			},
			division: {
				label: 'N/A',
			},
			sub_division: {
				label: 'N/A',
			},
			section: {
				label: 'N/A',
			},
		},
	});
	const [imgUpload, setImgUpload] = useState(false);
	const [loading, setLoading] = useState(false);
	const [fullName, setFullName] = useState('');
	const AuthDetails = useSelector((state) => state.auth.value);
	const userDetails = useSelector((state) => state.user.value);
	const isMounted = useRef(false);
	const { signup } = useAuth();

	useEffect(() => {
		const getIncidentList = async () => {
			try {
				await axios
					.get('/api/auth/user-profile', {
						params: { id: userDetails.id },
					})
					.then((response) => {
						if (!isMounted.current) {
							const { data } = response.data;
							setGeneralDetails(data);
						}
					});
			} catch (error: any) {
				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
				setLoading(false);
			}
		};
		if (AuthDetails.userType === OFFICIAL && AuthDetails.officialType === PERMANENT) {
			getIncidentList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() => () => {
			isMounted.current = true;
		},
		[]
	);

	const handleBack = () => {
		navigation.pop();
	};

	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 0.6,
		});

		if (!result.cancelled) {
			let ext = result.uri.slice(-4);
			if (
				// ext === ".png" ||
				ext === '.jpg' ||
				ext === 'jpeg' ||
				ext === '.svg'
			) {
				setImage(result.uri);
			} else {
				Alert.alert(
					'Alert!',
					'Please check the image type only jpeg, jpg and svg are allowed!',
					[
						{
							text: 'OK',
						},
					]
				);
			}
		}
	};

	const deleteImage = () => {
		setImage('');
	};

	const handleSubmit = async () => {
		if (AuthDetails.userType === OFFICIAL) {
			let newImageUri;
			if (image === '') {
				setImgUpload(false);
				newImageUri = '';
			} else {
				setImgUpload(true);
				newImageUri = 'file:///' + image.split('file:/').join('');
			}
			try {
				setLoading(true);
				await signup(
					AuthDetails.userType,
					AuthDetails.officialType,
					AuthDetails.type,
					AuthDetails.phoneValue,
					userDetails.email,
					generalDetails.full_name,
					imgUpload,
					newImageUri
				);
				setLoading(false);
				navigation.navigate('Loading');
			} catch (error: any) {
				setLoading(false);

				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			}
		} else {
			if (fullName === '') {
				Alert.alert('Alert!', 'Please add required fields!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			} else {
				let newImageUri;
				if (image === '') {
					setImgUpload(false);
					newImageUri = '';
				} else {
					setImgUpload(true);
					newImageUri = 'file:///' + image.split('file:/').join('');
				}
				try {
					setLoading(true);
					await signup(
						AuthDetails.userType,
						AuthDetails.officialType,
						AuthDetails.type,
						AuthDetails.phoneValue,
						'',
						fullName,
						imgUpload,
						newImageUri
					);
					setLoading(false);
					navigation.navigate('Loading');
				} catch (error: any) {
					setLoading(false);

					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			}
		}
	};

	return (
		<>
			<Header />
			<ScrollView style={styles.scrollContainer}>
				<>
					<View style={styles.imageWrapper}>
						{AuthDetails.userType === OFFICIAL && <PGeneralDetails />}
						{AuthDetails.userType === COMMON && <CGeneralDetails />}
					</View>
					<View style={styles.container}>
						<Text style={styles.headingText}>General Details</Text>
						<View style={styles.imageUpload}>
							{!image ? (
								<>
									<Pressable style={styles.imageContainer} onPress={pickImage}>
										<AntDesign name="camera" size={32} color="#346499" />
									</Pressable>
									<Pressable onPress={pickImage}>
										<Text style={styles.text}>Upload your profile picture</Text>
									</Pressable>
								</>
							) : (
								<>
									<Image source={{ uri: image }} style={styles.image} />

									<Pressable onPress={pickImage}>
										<Text style={styles.text}>Change</Text>
									</Pressable>
									<Pressable onPress={deleteImage} style={styles.deleteWrapper}>
										<AntDesign name="delete" size={24} color="#346499" />
									</Pressable>
								</>
							)}
						</View>
						<View style={styles.form}>
							{AuthDetails.userType === COMMON && (
								<>
									<Text style={styles.title}>
										Full name
										<Text style={styles.textRed}>*</Text>
									</Text>
									<TextInput
										style={sharedStyles.generalInput}
										onChangeText={(value) => {
											setFullName(value);
										}}
										value={fullName}
										maxLength={30}
										selectionColor={Colors.light.tabIconDefault}
									/>
								</>
							)}

							{AuthDetails.userType === OFFICIAL && (
								<>
									<Text style={styles.title}>Full name</Text>
									<TextInput
										style={sharedStyles.generalInput}
										value={generalDetails.full_name}
										editable={false}
										selectTextOnFocus={false}
										maxLength={30}
										selectionColor={Colors.light.tabIconDefault}
									/>
									<Text style={styles.title}>Designation</Text>
									<TextInput
										style={sharedStyles.generalInput}
										value={generalDetails.designation.label}
										editable={false}
										selectTextOnFocus={false}
										maxLength={30}
										selectionColor={Colors.light.tabIconDefault}
									/>
									<Text
										style={StyleSheet.flatten([styles.title, { marginTop: 8 }])}
									>
										Jurisdiction Details
									</Text>
									<View style={styles.wrapper}>
										{/* <Juridiction /> */}
										{generalDetails &&
											generalDetails.jurisdiction &&
											generalDetails.jurisdiction.zone && (
												<View>
													<Text style={styles.heading}>Zone</Text>
													<Text style={styles.value}>
														{generalDetails.jurisdiction.zone.label}
													</Text>
												</View>
											)}
										{generalDetails &&
											generalDetails.jurisdiction &&
											generalDetails.jurisdiction.circle && (
												<View>
													<Text style={styles.heading}>Circle</Text>
													<Text style={styles.value}>
														{generalDetails.jurisdiction.circle.label}
													</Text>
												</View>
											)}
										{generalDetails &&
											generalDetails.jurisdiction &&
											generalDetails.jurisdiction.division && (
												<View>
													<Text style={styles.heading}>Division</Text>
													<Text style={styles.value}>
														{generalDetails.jurisdiction.division.label}
													</Text>
												</View>
											)}
										{generalDetails &&
											generalDetails.jurisdiction &&
											generalDetails.jurisdiction.sub_division && (
												<View>
													<Text style={styles.heading}>Sub-Division</Text>
													<Text style={styles.value}>
														{
															generalDetails.jurisdiction.sub_division
																.label
														}
													</Text>
												</View>
											)}

										{generalDetails &&
											generalDetails.jurisdiction &&
											generalDetails.jurisdiction.section && (
												<View>
													<Text style={styles.heading}>Section</Text>
													<Text style={styles.value}>
														{generalDetails.jurisdiction.section.label}
													</Text>
												</View>
											)}
									</View>
								</>
							)}
						</View>
					</View>
				</>
			</ScrollView>
			<View style={styles.buttonDiv}>
				<View style={[sharedStyles.btnWrapper, styles.btnWrapper]}>
					<Pressable style={sharedStyles.skipButton} onPress={handleBack}>
						<Text style={sharedStyles.skipButtonText}>Back</Text>
					</Pressable>
					{!loading ? (
						<Pressable
							style={[sharedStyles.skipButton, sharedStyles.done]}
							onPress={handleSubmit}
						>
							<Text style={[sharedStyles.skipButtonText, sharedStyles.doneText]}>
								Sign up
							</Text>
						</Pressable>
					) : (
						<View style={[sharedStyles.skipButton, sharedStyles.done]}>
							<ActivityIndicator animating={loading} color="white" />
						</View>
					)}
				</View>
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	scrollContainer: {
		flex: 1,
		backgroundColor: 'white',
	},
	container: {
		flex: 1,
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		padding: 16,
		backgroundColor: 'white',
	},
	imageUpload: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginBottom: 6,
	},
	imageContainer: {
		borderColor: '#55575A',
		borderWidth: 2,
		borderStyle: 'dashed',
		width: 75,
		height: 76,
		borderRadius: 60,
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 8,
	},
	imageWrapper: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 20,
		backgroundColor: 'white',
	},
	image: {
		width: 75,
		height: 75,
		borderRadius: 60,
		marginRight: 8,
	},
	deleteWrapper: {
		marginTop: 4,
	},
	title: {
		fontWeight: '600',
		fontSize: 15,
		lineHeight: 18,
	},
	buttonDiv: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		width: '100%',
		backgroundColor: 'white',
		paddingHorizontal: 16,
	},
	form: {
		paddingVertical: 16,
		width: '100%',
	},
	text: {
		fontSize: 16,
		color: '#55575A',
		fontFamily: FONT_MEDIUM,
		marginLeft: 8,
	},
	headingText: {
		fontSize: 18,
		fontWeight: '700',
		lineHeight: 22,
		marginBottom: 20,
		color: PRIMARY_COLOR,
	},
	btnWrapper: {
		flex: 1,
		width: '100%',
		marginVertical: 20,
		paddingBottom: 16,
	},
	wrapper: {
		marginVertical: 16,
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	heading: {
		fontSize: 11,
		fontWeight: '300',
		lineHeight: 13,
		paddingBottom: 4,
	},
	value: {
		fontSize: 15,
		fontWeight: '600',
		lineHeight: 19,
		marginBottom: 11,
	},
	textRed: {
		color: 'red',
	},
});
