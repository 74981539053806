import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	ActivityIndicator,
	Alert,
	Image,
	Pressable,
	ScrollView,
	StyleSheet,
	TextInput,
	TouchableHighlight,
} from 'react-native';
import { useDispatch } from 'react-redux';
import {
	AntDesign,
	Feather,
	Ionicons,
	MaterialCommunityIcons,
	SimpleLineIcons,
} from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import mime from 'mime';

import Colors, { PRIMARY_COLOR } from '../../common/constants/Colors';
import { COMMON, CONTRACT, OFFICIAL } from '../../common/constants/Constants';
import { FONT_BOLD, FONT_LIGHT, FONT_MEDIUM } from '../../common/constants/Fonts';
import { sharedStyles } from '../../common/constants/SharedStyles';
import useAuth from '../../common/hooks/useAuth';
import { NO, YES } from '../../common/utils/appConstants';
import axios from '../../common/utils/axios';
import AccordionListItem from '../../components/accordion-list/AccordionListItem';
import ProfileHeader from '../../components/ProfileHeader';
import { Text, View } from '../../components/Themed';
import { locationDetails } from '../../redux/slices/location';
import { locationLabelDetails } from '../../redux/slices/locationLabel';
import { useSelector } from '../../redux/store';

type AlertType = {
	id: number;
	name: string;
	isChecked: boolean;
};

type JurisdictionDetailType = {
	jurisdiction: {
		zone: { label: string };
		circle: { label: string };
		division: { label: string };
		sub_division: { label: string };
		district: { label: string };
		section: { label: string };
	};
	designation: {
		label: string;
	};
	account_type: string;
};

export default function ProfileTabScreen({ navigation }: { navigation: any }) {
	const [alertData, setAlertData] = useState<AlertType[]>([]);
	const [alertLoading, setAlertLoading] = useState<boolean>(false);
	const [checkImage, setCheckImage] = useState<boolean>(false);
	const [deleteImg, setDeleteImg] = useState<boolean>(false);
	const [disableBtn, setDisableBtn] = useState<boolean>(true);
	const [editPasswordState, setEditPasswordState] = useState<boolean>(false);
	const [secureEntryOld, setSecureEntryOld] = useState<boolean>(true);
	const [secureEntryNew, setSecureEntryNew] = useState<boolean>(true);
	const [secureEntryCurrent, setSecureEntryCurrent] = useState<boolean>(true);
	const [editState, setEditState] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [image, setImage] = useState<string>('');
	const [jurisdictionDetail, setJurisdictionDetail] = useState<JurisdictionDetailType>({
		jurisdiction: {
			zone: { label: '' },
			circle: { label: '' },
			division: { label: '' },
			sub_division: { label: '' },
			district: { label: '' },
			section: { label: '' },
		},
		designation: { label: '' },
		account_type: '',
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [location] = useState<string>('');
	const [nameProfile, setNameProfile] = useState<string>('User');
	const [passwordLoading, setPasswordLoading] = useState<boolean>();
	const [phone, setPhone] = useState<string>('');
	const [refresh, setRefresh] = useState(false);
	const [updateProfile, setUpdateProfile] = useState<boolean>(false);
	const AuthDetails = useSelector((state) => state.auth.value);
	const { logout, profileData } = useAuth();
	const dispatch = useDispatch();

	const isMounted = useRef(false);

	const { handleSubmit, control, setValue } = useForm();

	const handleLogout = async () => {
		try {
			await logout();
			dispatch(
				locationDetails({
					zoneId: '',
					circleId: '',
					divisionId: '',
					sub_divisionId: '',
					districtId: '',
					blockId: '',
					river_id: '',
					riverName: '',
					riverType: '',
					refreshLocation: true,
				})
			);
			dispatch(
				locationLabelDetails({
					district: '',
					block: '',
					village: '',
				})
			);
			navigation.navigate('Splash');
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useFocusEffect(
		useCallback(() => {
			const getProfileDetails = async () => {
				try {
					await axios.get('/api/user/profile').then((response) => {
						if (!isMounted.current) {
							const { data } = response.data;
							setJurisdictionDetail(data);
							setEmail(data?.email);
							data.full_name
								? setValue('full_name', `${data.full_name}`)
								: setValue('name', '');

							// data.last_name
							//     ? setValue("last_name", `${data.last_name}`)
							//     : setValue("name", "");
							// data.full_name
							//     ? data.last_name
							//         ? setNameProfile(
							//               `${data.full_name} ${data.last_name}`
							//           )
							//         : setNameProfile(data.full_name)
							//     : setNameProfile("User");
							data.full_name
								? setNameProfile(data.full_name)
								: setNameProfile('User');
							//setLocation(data.address)
							setPhone(data.phone_number);
							data.profile_picture && setImage(data.profile_picture.original_url);
						}
					});
				} catch (error: any) {
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			};
			getProfileDetails();
			setUpdateProfile(false);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [updateProfile])
	);

	useFocusEffect(
		useCallback(() => {
			const getAlertDetails = async () => {
				try {
					await axios.get('/api/user/get-alerts').then((response) => {
						if (!isMounted.current) {
							const { data } = response.data;
							setAlertData(data);
						}
					});
				} catch (error: any) {
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			};
			getAlertDetails();
		}, [])
	);

	useEffect(
		() => () => {
			isMounted.current = true;
		},
		[]
	);

	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 0.6,
		});
		if (!result.cancelled) {
			let ext = result.uri.slice(-4);
			if (
				// ext === ".png" ||
				ext === '.jpg' ||
				ext === 'jpeg' ||
				ext === '.svg'
			) {
				setImage(result.uri);
				setCheckImage(true);
				setDeleteImg(false);
			} else {
				Alert.alert(
					'Alert!',
					'Please check the image type only jpeg, jpg and svg are allowed!',
					[
						{
							text: 'OK',
						},
					]
				);
			}
		}
	};
	const deleteImage = () => {
		setDeleteImg(true);
		setImage('');
	};
	const onSubmit = async (data: any) => {
		if (data.full_name === '') {
			Alert.alert('Alert!', 'Please add required fields!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else {
			const validFirstName = /^[a-z|A-Z]+(?:[ |_][a-z|A-Z]+)*$/.test(data.full_name);
			// const validLasttName = /^[A-Za-z_][A-Za-z0-9_]{1,80}$/.test(
			//     data.last_name
			// );
			if (!validFirstName) {
				if (!validFirstName) {
					Alert.alert(
						'Alert!',
						'Please make sure that name contains only small letters, capital letters, space or underscore',
						[
							{
								text: 'Cancel',
								style: 'cancel',
							},
							{
								text: 'OK',
							},
						]
					);
				} else if (!validFirstName) {
					Alert.alert(
						'Alert!',
						'Please make sure that first name contains only small letters, capital letters, space or underscore',
						[
							{
								text: 'Cancel',
								style: 'cancel',
							},
							{
								text: 'OK',
							},
						]
					);
				} else {
					Alert.alert(
						'Alert!',
						'Please make sure that last name contains only small letters, capital letters, space or underscore',
						[
							{
								text: 'Cancel',
								style: 'cancel',
							},
							{
								text: 'OK',
							},
						]
					);
				}
			} else {
				const profileForm = new FormData();
				if (checkImage) {
					const newImageUri = 'file:///' + image.split('file:/').join('');

					profileForm.append('profile_picture', {
						uri: newImageUri,
						type: mime.getType(newImageUri),
						name: newImageUri.split('/').pop(),
					} as any);
				}
				if (deleteImg) {
					profileForm.append('remove_image', YES);
				} else {
					profileForm.append('remove_image', NO);
				}
				profileForm.append('phone_number', phone);
				profileForm.append('full_name', data.full_name);
				profileForm.append('last_name', data.last_name);
				try {
					setLoading(true);
					await axios.post('/api/user/profile-update', profileForm, {
						headers: { 'Content-Type': 'multipart/form-data' },
					});
					setDeleteImg(false);
					setCheckImage(false);
					setEditState(!editState);
					setUpdateProfile(true);
					setLoading(false);
					setRefresh(true);
					await profileData();

					Alert.alert('Success!', 'Changes updated successfully!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				} catch (error: any) {
					setLoading(false);
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			}
		}
	};

	const onPasswordChange = async (data: any) => {
		if (data.old_password === '' || data.new_password === '' || data.confirm_password === '') {
			Alert.alert('Alert!', 'Please add required fields!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else {
			if (data.new_password !== data.confirm_password) {
				Alert.alert('Alert!', "New password & confirm password don't match !", [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			} else {
				try {
					setPasswordLoading(true);
					await axios.post('/api/user/password-update', {
						current_password: data.old_password,
						new_password: data.new_password,
						confirm_password: data.confirm_password,
					});
					setPasswordLoading(false);
					setEditPasswordState(!editPasswordState);
					setRefresh(true);
					setValue('old_password', '');
					setValue('new_password', '');
					setValue('confirm_password', '');
					Alert.alert('Success!', 'Password Changed Successfully', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				} catch (error: any) {
					setPasswordLoading(false);
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			}
		}
	};

	const toggleEditState = () => {
		setEditState(!editState);
	};

	const checkboxSubmit = async () => {
		try {
			setAlertLoading(true);
			await axios.post('/api/user/update-alert', alertData);
			setAlertLoading(false);
			setDisableBtn(true);
			Alert.alert('Success!', 'Changes updated successfully!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} catch (error: any) {
			setAlertLoading(false);
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const handleChange = (id: any) => {
		let temp = alertData.map((alertItem) => {
			if (id === alertItem.id) {
				return { ...alertItem, isChecked: !alertItem.isChecked };
			}
			return alertItem;
		});
		setAlertData(temp);
		setDisableBtn(false);
	};

	return (
		<>
			<ProfileHeader
				navigation={navigation}
				refresh={refresh}
				setRefresh={() => setRefresh(false)}
			/>
			<ScrollView style={styles.scrollviewContainer}>
				<View style={styles.container}>
					<Text style={styles.heading}>User Profile</Text>

					<View style={styles.headerWrapper}>
						<View style={styles.imageUpload}>
							{!image ? (
								<>
									{editState ? (
										<>
											<Pressable
												style={styles.imageContainer}
												onPress={pickImage}
											>
												<AntDesign
													name="camera"
													size={32}
													color="#346499"
												/>
											</Pressable>
											<Pressable onPress={pickImage}>
												<Text style={styles.photoUplodBtn}>
													Upload Photo
												</Text>
											</Pressable>
										</>
									) : (
										<Image
											source={require('../../../assets/images/user.png')}
											style={styles.image}
										/>
									)}
								</>
							) : (
								<>
									<Image source={{ uri: image }} style={styles.image} />
									{editState && (
										<>
											<Pressable onPress={pickImage}>
												<Text style={styles.photoUplodBtn}>
													Change Photo
												</Text>
											</Pressable>
											<Pressable
												onPress={deleteImage}
												style={styles.deleteWrapper}
											>
												<AntDesign
													name="delete"
													size={24}
													color="#346499"
												/>
											</Pressable>
										</>
									)}
								</>
							)}
						</View>
						<View style={styles.verticleLine} />
						<View style={styles.nameProfile}>
							<Text style={styles.rightHeading}>{nameProfile}</Text>

							{jurisdictionDetail &&
								jurisdictionDetail.account_type === OFFICIAL &&
								jurisdictionDetail.designation &&
								jurisdictionDetail.designation.label && (
									<View style={styles.locationDiv}>
										<Text style={styles.inputText}>
											{jurisdictionDetail &&
												jurisdictionDetail.designation &&
												jurisdictionDetail.designation.label}
										</Text>
									</View>
								)}

							{location && (
								<View style={styles.locationDiv}>
									<Text>
										<Ionicons name="location-sharp" size={16} color="black" />
									</Text>
									<Text style={styles.location}>{location}</Text>
								</View>
							)}

							{/* <View style={styles.locationDiv}>
                                <Text>
                                    <Feather
                                        name="users"
                                        size={18}
                                        color="#D73030"
                                    />
                                </Text>
                                <Text style={styles.report}>
                                    11 incidents reported
                                </Text>
                            </View> */}
						</View>
					</View>
					<View style={styles.accordianWrapper}>
						<AccordionListItem title={'Update Profile'}>
							<Text style={styles.inputTitle}>
								Full name
								<Text style={styles.textRed}>*</Text>
							</Text>
							<Controller
								name="full_name"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextInput
										style={[sharedStyles.generalInput, styles.input]}
										onChangeText={onChange}
										value={value}
										placeholder="Type here"
										placeholderTextColor="#A7A7A7"
										editable={editState}
										selectTextOnFocus={editState}
										maxLength={30}
										selectionColor={Colors.light.tabIconDefault}
									/>
								)}
							/>
							{AuthDetails.userType !== COMMON &&
								AuthDetails.officialType !== CONTRACT && (
									<>
										<Text style={styles.inputTitle}>Email</Text>
										<View
											style={StyleSheet.flatten([
												sharedStyles.generalInput,
												styles.input,
												{ backgroundColor: '#FAFAFA' },
											])}
										>
											<Text style={styles.inputText}>
												{email ? email : '-'}
											</Text>
										</View>
									</>
								)}
							<>
								<Text style={styles.inputTitle}>Phone</Text>
								<View
									style={StyleSheet.flatten([
										sharedStyles.generalInput,
										styles.input,
										{ backgroundColor: '#FAFAFA' },
									])}
								>
									<Text style={styles.inputText}>{phone}</Text>
								</View>
							</>
							{/* <Text style={styles.inputTitle}>Location</Text>
                        {!editState ? (
                            <View style={[sharedStyles.generalInput, styles.input]}>
                                <Text style={styles.inputText}>{location}</Text>
                            </View>
                        ) : (
                            <Controller
                                name="location"
                                defaultValue="District Purnia, North Bihar"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextInput
                                        style={[sharedStyles.generalInput, styles.input]}
                                        onChangeText={onChange}
                                        value={value}
                                        placeholder="District Purnia, North Bihar"
                                        placeholderTextColor="#A7A7A7"
                                        editable={editState}
                                        selectTextOnFocus={editState}
                                    />
                                )}
                            />
                        )} */}
							<View style={styles.buttonWrapper}>
								{!loading ? (
									<Pressable
										onPress={handleSubmit(onSubmit)}
										disabled={!editState}
										style={StyleSheet.flatten([
											styles.saveButton,
											{
												backgroundColor: !editState ? '#A7A7A7' : '#346499',
												borderColor: !editState ? '#A7A7A7' : '#346499',
											},
										])}
									>
										<Text style={styles.saveButtonText}>Save Changes</Text>
									</Pressable>
								) : (
									<View
										style={StyleSheet.flatten([
											styles.saveButton,
											{
												backgroundColor: !editState ? '#A7A7A7' : '#346499',
												borderColor: !editState ? '#A7A7A7' : '#346499',
											},
										])}
									>
										<ActivityIndicator animating={loading} color="white" />
									</View>
								)}

								<Pressable
									onPress={toggleEditState}
									style={StyleSheet.flatten([
										styles.button,
										{
											borderColor: !editState ? '#346499' : '#A7A7A7',
										},
									])}
									disabled={editState}
								>
									<Feather
										name="edit"
										size={16}
										color={!editState ? '#346499' : '#A7A7A7'}
									/>
									<Text
										style={StyleSheet.flatten([
											styles.buttonText,
											{
												color: !editState ? '#346499' : '#A7A7A7',
											},
										])}
									>
										Edit
									</Text>
								</Pressable>
							</View>
						</AccordionListItem>
					</View>
					{AuthDetails.userType !== COMMON && jurisdictionDetail.jurisdiction && (
						<View style={styles.accordianWrapper}>
							<AccordionListItem title="Jurisdiction Details">
								<View style={styles.accordianContainer}>
									{jurisdictionDetail.jurisdiction.zone && (
										<View style={styles.mrFive}>
											<Text style={styles.inputTitle}>Zone</Text>
											<View
												style={[
													sharedStyles.generalInput,
													styles.input,
													styles.jurisInput,
												]}
											>
												<Text style={styles.inputText}>
													{jurisdictionDetail.jurisdiction.zone.label}
												</Text>
											</View>
										</View>
									)}
									{jurisdictionDetail.jurisdiction.circle && (
										<View style={styles.mrFive}>
											<Text style={styles.inputTitle}>Circle</Text>
											<View
												style={[
													sharedStyles.generalInput,
													styles.input,
													styles.jurisInput,
												]}
											>
												<Text style={styles.inputText}>
													{jurisdictionDetail.jurisdiction.circle.label}
												</Text>
											</View>
										</View>
									)}
									{jurisdictionDetail.jurisdiction.division && (
										<View style={styles.mrFive}>
											<Text style={styles.inputTitle}>Division</Text>
											<View
												style={[
													sharedStyles.generalInput,
													styles.input,
													styles.jurisInput,
												]}
											>
												<Text style={styles.inputText}>
													{jurisdictionDetail.jurisdiction.division.label}
												</Text>
											</View>
										</View>
									)}
									{jurisdictionDetail.jurisdiction.sub_division && (
										<View style={styles.mrFive}>
											<Text style={styles.inputTitle}>Sub-Division</Text>
											<View
												style={[
													sharedStyles.generalInput,
													styles.input,
													styles.jurisInput,
												]}
											>
												<Text style={styles.inputText}>
													{
														jurisdictionDetail.jurisdiction.sub_division
															.label
													}
												</Text>
											</View>
										</View>
									)}
									{jurisdictionDetail.jurisdiction.district && (
										<View style={styles.mrFive}>
											<Text style={styles.inputTitle}>District</Text>
											<View
												style={[
													sharedStyles.generalInput,
													styles.input,
													styles.jurisInput,
												]}
											>
												<Text style={styles.inputText}>
													{jurisdictionDetail.jurisdiction.district.label}
												</Text>
											</View>
										</View>
									)}
									{/* {jurisdictionDetail.jurisdiction
                                        .section && (
                                        <View style={styles.mrFive}>
                                            <Text style={styles.inputTitle}>
                                                Section
                                            </Text>
                                            <View
                                                style={[
                                                    sharedStyles.generalInput,
                                                    styles.input,
                                                    styles.jurisInput
                                                ]}
                                            >
                                                <Text style={styles.inputText}>
                                                    {
                                                        jurisdictionDetail
                                                            .jurisdiction
                                                            .section.label
                                                    }
                                                </Text>
                                            </View>
                                        </View>
                                    )} */}
								</View>
							</AccordionListItem>
						</View>
					)}
					{AuthDetails.userType !== COMMON && AuthDetails.officialType !== CONTRACT && (
						<View style={styles.accordianWrapper}>
							<AccordionListItem title="Change Password">
								<Text style={styles.inputTitle}>
									Enter Old Password
									<Text style={styles.textRed}>*</Text>
								</Text>
								<Controller
									name="old_password"
									control={control}
									render={({ field: { onChange, value } }) => (
										<View
											style={[
												sharedStyles.generalInput,
												styles.input,
												sharedStyles.passwordField,
											]}
										>
											<TextInput
												style={styles.textInput}
												onChangeText={onChange}
												value={value}
												placeholder="Type here"
												placeholderTextColor="#A7A7A7"
												editable={editPasswordState}
												selectTextOnFocus={editPasswordState}
												maxLength={8}
												secureTextEntry={secureEntryOld}
												selectionColor={Colors.light.tabIconDefault}
											/>
											{editPasswordState && (
												<Pressable
													onPress={() =>
														setSecureEntryOld(!secureEntryOld)
													}
												>
													<Ionicons
														name={secureEntryOld ? 'eye-off' : 'eye'}
														size={24}
													/>
												</Pressable>
											)}
										</View>
									)}
								/>
								<Text style={styles.inputTitle}>
									Enter New Password
									<Text style={styles.textRed}>*</Text>
								</Text>
								<Controller
									name="new_password"
									control={control}
									render={({ field: { onChange, value } }) => (
										<View
											style={[
												sharedStyles.generalInput,
												styles.input,
												sharedStyles.passwordField,
											]}
										>
											<TextInput
												style={styles.textInput}
												onChangeText={onChange}
												value={value}
												placeholder="Type here"
												placeholderTextColor="#A7A7A7"
												editable={editPasswordState}
												selectTextOnFocus={editPasswordState}
												maxLength={8}
												secureTextEntry={secureEntryNew}
												selectionColor={Colors.light.tabIconDefault}
											/>
											{editPasswordState && (
												<Pressable
													onPress={() =>
														setSecureEntryNew(!secureEntryNew)
													}
												>
													<Ionicons
														name={secureEntryNew ? 'eye-off' : 'eye'}
														size={24}
													/>
												</Pressable>
											)}
										</View>
									)}
								/>
								<Text style={styles.inputTitle}>
									Confirm New Password
									<Text style={styles.textRed}>*</Text>
								</Text>
								<Controller
									name="confirm_password"
									control={control}
									render={({ field: { onChange, value } }) => (
										<View
											style={[
												sharedStyles.generalInput,
												styles.input,
												sharedStyles.passwordField,
											]}
										>
											<TextInput
												style={styles.textInput}
												onChangeText={onChange}
												value={value}
												placeholder="Type here"
												placeholderTextColor="#A7A7A7"
												editable={editPasswordState}
												selectTextOnFocus={editPasswordState}
												maxLength={8}
												secureTextEntry={secureEntryCurrent}
												selectionColor={Colors.light.tabIconDefault}
											/>
											{editPasswordState && (
												<Pressable
													onPress={() =>
														setSecureEntryCurrent(!secureEntryCurrent)
													}
												>
													<Ionicons
														name={
															secureEntryCurrent ? 'eye-off' : 'eye'
														}
														size={24}
													/>
												</Pressable>
											)}
										</View>
									)}
								/>
								<View style={styles.buttonWrapper}>
									{!passwordLoading ? (
										<Pressable
											onPress={handleSubmit(onPasswordChange)}
											disabled={!editPasswordState}
											style={StyleSheet.flatten([
												styles.saveButton,
												{
													backgroundColor: !editPasswordState
														? '#A7A7A7'
														: '#346499',
													borderColor: !editPasswordState
														? '#A7A7A7'
														: '#346499',
												},
											])}
										>
											<Text style={styles.saveButtonText}>Save Changes</Text>
										</Pressable>
									) : (
										<View
											style={StyleSheet.flatten([
												styles.saveButton,
												{
													backgroundColor: !editPasswordState
														? '#A7A7A7'
														: '#346499',
													borderColor: !editPasswordState
														? '#A7A7A7'
														: '#346499',
												},
											])}
										>
											<ActivityIndicator
												animating={passwordLoading}
												color="white"
											/>
										</View>
									)}

									<Pressable
										onPress={() => setEditPasswordState(!editPasswordState)}
										style={StyleSheet.flatten([
											styles.button,
											{
												borderColor: !editPasswordState
													? '#346499'
													: '#A7A7A7',
											},
										])}
										disabled={editPasswordState}
									>
										<Feather
											name="edit"
											size={16}
											color={!editPasswordState ? '#346499' : '#A7A7A7'}
										/>
										<Text
											style={StyleSheet.flatten([
												styles.buttonText,
												{
													color: !editPasswordState
														? '#346499'
														: '#A7A7A7',
												},
											])}
										>
											Edit
										</Text>
									</Pressable>
								</View>
							</AccordionListItem>
						</View>
					)}
					{AuthDetails.userType === COMMON && (
						<View style={styles.accordianWrapper}>
							<AccordionListItem title={'Alerts & Notifications'}>
								{alertData.map((ele, i) => (
									<View style={styles.checkBox} key={i}>
										<Pressable
											onPress={() => handleChange(ele.id)}
											style={styles.checkboxWrapper}
										>
											<MaterialCommunityIcons
												name={
													ele.isChecked
														? 'checkbox-marked'
														: 'checkbox-blank-outline'
												}
												size={24}
												color="#346499"
											/>
											<Text style={styles.alertDateElementName}>
												{ele.name}
											</Text>
										</Pressable>
									</View>
								))}
								<View style={styles.buttonWrapper}>
									{!alertLoading ? (
										<Pressable
											onPress={checkboxSubmit}
											disabled={disableBtn}
											style={StyleSheet.flatten([
												styles.saveButton,
												{
													backgroundColor: disableBtn
														? '#A7A7A7'
														: '#346499',
													borderColor: disableBtn ? '#A7A7A7' : '#346499',
												},
											])}
										>
											<Text style={styles.saveButtonText}>Save Changes</Text>
										</Pressable>
									) : (
										<View
											style={StyleSheet.flatten([
												styles.saveButton,
												{
													borderColor: '#346499',
												},
											])}
										>
											<ActivityIndicator
												animating={alertLoading}
												color="white"
											/>
										</View>
									)}
								</View>
							</AccordionListItem>
						</View>
					)}
				</View>
				<TouchableHighlight onPress={handleLogout} style={styles.logoutWrapper}>
					<View style={styles.logoutDiv}>
						<Text style={styles.logoutText}>Log Out</Text>

						<Text>
							<SimpleLineIcons name="logout" size={24} color="black" />
						</Text>
					</View>
				</TouchableHighlight>
			</ScrollView>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 3,
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		padding: 16,
		backgroundColor: '#F5F5F5',
		width: '100%',
	},
	scrollviewContainer: {
		width: '100%',
	},
	accordianWrapper: {
		padding: 16,
		display: 'flex',
		alignContent: 'center',
		width: '100%',
		justifyContent: 'center',
		marginTop: 20,
	},
	accordianContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	deleteWrapper: {
		marginTop: 4,
	},
	verticleLine: {
		height: '100%',
		width: 1,
		backgroundColor: '#DFE0EB',
		marginHorizontal: 6,
	},
	buttonWrapper: {
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'flex-end',
		alignItems: 'center',
		backgroundColor: '#FFF',
		marginTop: 6,
	},
	button: {
		borderRadius: 4,
		borderColor: PRIMARY_COLOR,
		borderWidth: 1,
		borderStyle: 'solid',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		paddingVertical: 6.4,
		paddingHorizontal: 16,
	},
	saveButton: {
		borderRadius: 4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		borderWidth: 1,
		borderStyle: 'solid',
		paddingVertical: 6.4,
		paddingHorizontal: 12,
		backgroundColor: PRIMARY_COLOR,
		marginRight: 8,
		width: 130,
	},
	buttonText: {
		fontFamily: FONT_MEDIUM,
		color: PRIMARY_COLOR,
		fontSize: 15,
		marginLeft: 6,
	},
	saveButtonText: {
		fontFamily: FONT_MEDIUM,
		color: '#FFFFFF',
		fontSize: 15,
	},
	input: {
		height: 50,
		backgroundColor: '#FFFFFF',
		borderWidth: 1.02,
		borderColor: '#DFE0EB',
		borderStyle: 'solid',
		justifyContent: 'center',
	},
	jurisInput: {
		alignItems: 'center',
		height: 'auto',
		backgroundColor: '#FAFAFA',
	},
	inputText: {
		color: '#55575A',
	},
	inputTitle: {
		fontSize: 13,
		fontFamily: FONT_MEDIUM,
	},
	checkBox: {
		backgroundColor: 'transparent',
	},

	checkboxWrapper: {
		alignItems: 'center',
		backgroundColor: 'transparent',
		flexDirection: 'row',
		paddingVertical: 3,
	},

	headerWrapper: {
		backgroundColor: '#FFFFFF',
		boxShadow: '0px 4px 29px rgba(85, 111, 139, 0.09)',
		borderRadius: 13,
		paddingTop: 12,
		paddingBottom: 4,
		paddingHorizontal: 10,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row',
	},
	locationDiv: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row',
		paddingVertical: 10,
	},
	logoutDiv: {
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		flexDirection: 'row',
		backgroundColor: '#FFFFFF',
		width: '100%',
		padding: 16,
	},
	logoutWrapper: {
		marginVertical: 18,
		backgroundColor: '#F5F5F5',
	},
	heading: {
		fontSize: 21,
		fontFamily: FONT_BOLD,
		color: PRIMARY_COLOR,
		paddingBottom: 16,
	},
	rightHeading: {
		fontSize: 21,
		fontFamily: FONT_BOLD,
		color: PRIMARY_COLOR,
		flexWrap: 'wrap',
	},
	nameProfile: {
		paddingLeft: 8,
		flexShrink: 1,
	},
	location: {
		fontSize: 13,
		fontFamily: FONT_LIGHT,
		paddingLeft: 8,
	},
	report: {
		fontSize: 15,
		fontFamily: FONT_LIGHT,
		color: '#D73030',
		paddingLeft: 8,
	},
	logoutText: {
		fontSize: 16,
		fontFamily: FONT_LIGHT,
	},
	imageUpload: {
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 6,
		width: 90,
	},
	imageContainer: {
		borderColor: '#55575A',
		borderWidth: 2,
		borderStyle: 'dashed',
		width: 75,
		height: 75,
		borderRadius: 35,
		alignItems: 'center',
		justifyContent: 'center',
	},
	image: {
		width: 70,
		height: 70,
		borderRadius: 35,
	},
	photoUplodBtn: {
		color: PRIMARY_COLOR,
		fontSize: 13,
		marginTop: 8,
	},
	textRed: {
		color: 'red',
	},
	mrFive: {
		marginRight: 5,
	},
	textInput: {
		width: '90%',
	},
	alertDateElementName: {
		paddingLeft: 8,
	},
});
