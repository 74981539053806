/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import {
	Alert,
	StyleProp,
	StyleSheet,
	TextStyle,
	useWindowDimensions,
	ViewStyle,
} from 'react-native';
import {
	NavigationState,
	Route,
	SceneMap,
	SceneRendererProps,
	TabBar,
	TabBarIndicatorProps,
	TabBarItemProps,
	TabView,
} from 'react-native-tab-view';
import { Event, Scene } from 'react-native-tab-view/lib/typescript/types';
import { useSelector } from 'react-redux';

import { FONT_BOLD, FONT_LIGHT, FONT_NORMAL, FONT_SEMI_BOLD } from '../../common/constants/Fonts';
import axios from '../../common/utils/axios';
import { Text, View } from '../Themed';

type Props = {
	waterLevelRoute?: any;
	currRiver: string;
};

type RouteType = {
	key: string;
	title: string;
};

type Details = {
	water_level: string;
	downstream_level: number;
	upstream_level: number;
	gate_opennig_tme: string;
	gate_close_tme: string;
	water_level_date: string;
	water_discharge: number;
};

export default function RiverTabs({ waterLevelRoute }: Props) {
	const [index, setIndex] = React.useState(0);
	const [routes, setRoutes] = React.useState<RouteType[]>([]);
	const [riverData, setRiverData] = React.useState<Details>();
	const { river_id, riverType } = useSelector((state: any) => state.location.value);

	useEffect(() => {
		setRoutes(waterLevelRoute);
		if (waterLevelRoute && waterLevelRoute[index]) {
			getData(waterLevelRoute[index].key);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [waterLevelRoute]);

	const getData = async (id: string) => {
		try {
			await axios
				.get('/api/dashboard/waterlevel-dash', {
					params: {
						river_id: river_id,
						type: riverType,
						type_id: id,
					},
				})

				.then((response) => {
					const { data } = response.data;
					if (data && data.length > 0) {
						setRiverData(data[0]);
					} else {
						setRiverData(undefined);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const layout = useWindowDimensions();

	const getSceneMap = () => {
		let sceneMap: any = {};
		routes.length &&
			routes.map((item: any, routeIndex: any) => {
				sceneMap[item.key] = () => {
					return <FirstRoute key={routeIndex} />;
				};
			});

		return sceneMap;
	};

	const sceneRendering = SceneMap(getSceneMap());

	const FirstRoute = () => (
		<>
			{riverData === undefined || Object.keys(riverData).length === 0 ? (
				<View style={styles.errorContainer}>
					<View style={styles.empty}>
						<Text style={styles.emptyText}>No data found</Text>
					</View>
				</View>
			) : (
				<View style={styles.innerCard}>
					<View style={styles.flexWrapper}>
						<View style={styles.WrapperSmall}>
							<View style={styles.innerWrapper}>
								<Text style={styles.lightText}>Upstream{'\n'}Level</Text>
							</View>
							<View style={styles.innerWrapper}>
								<Text style={[styles.lightGreyText]}>
									{riverData && riverData.upstream_level
										? riverData.upstream_level
										: 0}
								</Text>
							</View>
						</View>
						<View style={styles.WrapperSmall}>
							<View style={styles.innerWrapper}>
								<Text style={styles.lightText}>Downstream{'\n'}Level</Text>
							</View>
							<View style={styles.innerWrapper}>
								<Text style={[styles.lightGreyText]}>
									{riverData && riverData.downstream_level
										? riverData.downstream_level
										: 0}
								</Text>
							</View>
						</View>
						<View style={styles.WrapperSmall}>
							<View style={styles.innerWrapper}>
								<Text style={styles.lightText}>
									Gate{'\n'}Opening{'\n'}Time
								</Text>
							</View>
							<View style={styles.innerWrapper}>
								<Text style={[styles.lightGreyText]}>
									{riverData && riverData.gate_opennig_tme
										? riverData.gate_opennig_tme
										: '00:00:00'}
								</Text>
							</View>
						</View>
						<View style={styles.WrapperSmall}>
							<View style={styles.innerWrapper}>
								<Text style={styles.lightText}>
									Gate{'\n'}Closing{'\n'}Time
								</Text>
							</View>
							<View style={styles.innerWrapper}>
								<Text style={[styles.lightGreyText]}>
									{riverData && riverData.gate_close_tme
										? riverData.gate_close_tme
										: '00:00:00'}
								</Text>
							</View>
						</View>
						<View style={styles.WrapperSmall}>
							<View style={styles.innerWrapper}>
								<Text style={styles.lightText}>Water{'\n'}Discharge</Text>
							</View>
							<View style={styles.innerWrapper}>
								<Text style={[styles.lightGreyText]}>
									{riverData && riverData.water_discharge
										? riverData.water_discharge
										: '0'}{' '}
									cusec
								</Text>
							</View>
						</View>
					</View>
				</View>
			)}
		</>
	);

	const renderTabBar = (
		props: JSX.IntrinsicAttributes &
			SceneRendererProps & {
				navigationState: NavigationState<Route>;
				scrollEnabled?: boolean | undefined;
				bounces?: boolean | undefined;
				activeColor?: string | undefined;
				inactiveColor?: string | undefined;
				pressColor?: string | undefined;
				pressOpacity?: number | undefined;
				getLabelText?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getAccessible?: ((scene: Scene<Route>) => boolean | undefined) | undefined;
				getAccessibilityLabel?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getTestID?: ((scene: Scene<Route>) => string | undefined) | undefined;
				renderLabel?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderIcon?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderBadge?: ((scene: Scene<Route>) => React.ReactNode) | undefined;
				renderIndicator?:
					| ((props: TabBarIndicatorProps<Route>) => React.ReactNode)
					| undefined;
				renderTabBarItem?:
					| ((
							props: TabBarItemProps<Route> & { key: string }
					  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
					| undefined;
				onTabPress?: ((scene: Scene<Route> & Event) => void) | undefined;
				onTabLongPress?: ((scene: Scene<Route>) => void) | undefined;
				tabStyle?: StyleProp<ViewStyle>;
				indicatorStyle?: StyleProp<ViewStyle>;
				indicatorContainerStyle?: StyleProp<ViewStyle>;
				labelStyle?: StyleProp<TextStyle>;
				contentContainerStyle?: StyleProp<ViewStyle>;
				style?: StyleProp<ViewStyle>;
				gap?: number | undefined;
			}
	) => (
		<TabBar
			{...props}
			indicatorStyle={styles.white}
			scrollEnabled
			style={styles.white}
			tabStyle={styles.tabBar}
		/>
	);

	return (
		<View
			style={[
				(riverData === undefined || Object.keys(riverData).length === 0) &&
					styles.noDataHeight,
				(!waterLevelRoute || waterLevelRoute.length === 0) && styles.noRouteHeight,
			]}
		>
			{!waterLevelRoute || waterLevelRoute.length === 0 ? (
				<View style={styles.errorContainer}>
					<View style={[styles.empty, styles.routeEmpty]}>
						<Text style={styles.emptyText}>No data found</Text>
					</View>
				</View>
			) : (
				<>
					<View style={styles.container}>
						<Text style={styles.streamText}>Upstream</Text>

						<Text style={styles.streamText}>Downstream</Text>
					</View>
					<View style={styles.tabWrapper}>
						<TabView
							navigationState={{ index, routes }}
							renderScene={sceneRendering}
							onIndexChange={(currentIndex) => {
								setIndex(currentIndex);
								getData(routes[index].key);
							}}
							initialLayout={{ width: layout.width }}
							style={styles.tab}
							renderTabBar={renderTabBar}
						/>
					</View>
				</>
			)}
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderRadius: 4,
		marginTop: 14,
		marginBottom: 10,
	},
	tabWrapper: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: 170,
		// flex: 1,
	},
	textWrapper: {
		backgroundColor: '#F5F7FB',
		padding: 14,
		flex: 1,
		marginTop: 10,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	WrapperSmall: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
		backgroundColor: '#F5F7FB',
		// height: 80,
	},
	normalText: {
		fontFamily: FONT_NORMAL,
		fontSize: 13,
	},
	boldText: {
		fontFamily: FONT_BOLD,
		fontSize: 13,
		marginTop: 8,
		// color: 'red'
	},
	tab: {
		justifyContent: 'center',
		width: '100%',
	},
	errorContainer: {
		width: '100%',
		// alignItems: "flex-end",
	},
	empty: {
		padding: 8,
		backgroundColor: '#F5F7FB',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		marginTop: 10,
		borderRadius: 4,
		height: 80,
	},
	routeEmpty: {
		// height: 100,
	},
	noDataHeight: {
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		borderRadius: 4,
		marginTop: 3,
		// height: "15%",
		width: '100%',
	},
	noRouteHeight: {
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		borderRadius: 4,
		marginTop: 3,
		// height: "20%",
		width: '100%',
	},
	emptyText: {
		fontFamily: FONT_BOLD,
		fontSize: 18,
		color: '#346499',
		marginLeft: 6,
	},
	divider: {
		height: 50,
		width: 1,
		backgroundColor: '#DFE0EB',
	},
	flexWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		backgroundColor: '#F5F7FB',
	},
	innerWrapper: {
		alignItems: 'center',
		backgroundColor: '#F5F7FB',
		justifyContent: 'center',
		height: 40,
		width: '100%',
	},
	innerCard: {
		padding: 8,
		backgroundColor: '#F5F7FB',
		width: '100%',
		marginTop: 8,
		borderRadius: 4,
	},
	semiBoldText: {
		fontFamily: FONT_SEMI_BOLD,
		fontSize: 12,
	},
	bigYellowText: {
		fontFamily: FONT_SEMI_BOLD,
		fontSize: 15,
		color: '#E0A800',
	},
	lightText: {
		fontFamily: FONT_LIGHT,
		fontSize: 11,
		textAlign: 'center',
	},
	lightGreyText: {
		fontFamily: FONT_LIGHT,
		fontSize: 11,
		color: '#000',
		textAlign: 'center',
		// marginTop:10
	},
	tabBar: {
		backgroundColor: '#346499',
		borderRadius: 4,
		marginHorizontal: 5,
	},
	streamText: {
		fontSize: 12,
		paddingRight: 6,
	},
	white: {
		backgroundColor: 'white',
	},
});
