import { Pressable, StyleSheet } from 'react-native';
import { Feather } from '@expo/vector-icons';

//constant
import { PRIMARY_COLOR } from '../../common/constants/Colors';
import { FONT_BOLD, FONT_LIGHT, FONT_MEDIUM, FONT_NORMAL } from '../../common/constants/Fonts';
import { useSelector } from '../../redux/store';
import { Text, View } from '../Themed';

type Props = {
	open: () => void;
};
export default function Weather(props: Props) {
	const { district } = useSelector((state: any) => state.locationLabel.value);
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	const date = new Date();
	const getOrdinalNum = (number: number) => {
		let selector;

		if ((number > 3 && number < 21) || number % 10 > 3) {
			selector = 0;
		} else {
			selector = number % 10;
		}
		return number + ['th', 'st', 'nd', 'rd'][selector];
	};
	return (
		<View style={styles.container}>
			<View style={styles.wrapper}>
				<Text style={styles.selectTitle}>District - {district}</Text>
				<Pressable onPress={props.open}>
					<Text style={styles.editText}>Edit Location Details</Text>
				</Pressable>
			</View>
			<View style={styles.innerCard}>
				<View style={styles.todayWeather}>
					<Text style={styles.lightText}>Today</Text>
					<Text style={styles.normalText}>
						{getOrdinalNum(date.getDate())} {monthNames[date.getMonth()]}
					</Text>
					<Text style={styles.normalSmallText}>{dayName[date.getDay()]}</Text>
					{/* <Text style={styles.normalSmallText}>05:08 PM</Text> */}
				</View>
				<View style={styles.weather}>
					<Text style={styles.boldText}>23° C</Text>

					<Text style={styles.normalText}>Precipitation: 10%</Text>
					<Text style={styles.mediumText}>Humidity: 74%</Text>
				</View>
				<View style={styles.weather}>
					<Feather name="sun" size={24} color="orange" />
					<Text style={styles.mediumText}>Sunny</Text>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		borderRadius: 4,
		marginTop: 3,
		marginBottom: 2,
	},
	innerCard: {
		paddingHorizontal: 8,
		paddingVertical: 10,
		backgroundColor: '#F5F7FB',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		marginTop: 8,
		borderRadius: 4,
	},
	lightText: {
		fontFamily: FONT_LIGHT,
		fontSize: 10,
		color: '#55575A',
		marginVertical: 2,
	},
	boldText: {
		fontFamily: FONT_BOLD,
		fontSize: 18.21,
		marginVertical: 2,
	},
	normalText: {
		fontFamily: FONT_NORMAL,
		fontSize: 11.38,
		marginVertical: 2,
	},
	mediumText: {
		fontFamily: FONT_MEDIUM,
		fontSize: 11.38,
		marginVertical: 2,
	},
	normalSmallText: {
		fontFamily: FONT_NORMAL,
		fontSize: 9.86,
		marginVertical: 2,
	},
	wrapper: {
		backgroundColor: 'white',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginVertical: 8,
		width: '100%',
	},
	selectTitle: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: '600',
	},
	editText: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: '600',
		color: PRIMARY_COLOR,
		textDecorationLine: 'underline',
	},
	weather: {
		backgroundColor: '#F5F7FB',
		alignItems: 'center',
	},
	todayWeather: {
		backgroundColor: '#F5F7FB',
	},
});
