/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useRef, useState } from 'react';
import {
	ActivityIndicator,
	Alert,
	FlatList,
	StyleProp,
	StyleSheet,
	TextStyle,
	useWindowDimensions,
	ViewStyle,
} from 'react-native';
import {
	NavigationState,
	Route,
	SceneMap,
	SceneRendererProps,
	TabBar,
	TabBarIndicatorProps,
	TabBarItemProps,
	TabView,
} from 'react-native-tab-view';
import { Event, Scene } from 'react-native-tab-view/lib/typescript/types';
import { useIsFocused } from '@react-navigation/native';

import Colors, { PRIMARY_COLOR } from '../../common/constants/Colors';
//constants
import { FONT_BOLD } from '../../common/constants/Fonts';
import axios from '../../common/utils/axios';
import ProfileHeader from '../../components/ProfileHeader';
//components
import { Text, View } from '../../components/Themed';

import HomeAlertsNotifcationsCard from './../../components/alerts-notification/AlertNotifications';

export default function AlertsTabScreen({ navigation }: { navigation: any }) {
	const [alertData, setAlertData] = useState([]);
	const [alertDataToday, setAlertDataToday] = useState([]);
	const [loading, setLoading] = useState(false);
	const [focus, setFocus] = useState(false);
	const [routes] = useState([
		{ key: 'today', title: 'Today' },
		{ key: 'all', title: 'All Alerts' },
	]);

	const [index, setIndex] = useState(0);

	const layout = useWindowDimensions();

	const isFocused = useIsFocused();
	const isMounted = useRef(false);

	const getAlerts = async () => {
		try {
			if (!focus) {
				setLoading(true);
			}
			const response = await axios.get('/api/alerts/alert-list/all');
			const { data } = response.data;
			if (!isMounted.current) {
				setAlertData(data);
				setAlertDataToday(data.filter((item: any) => item.today_fl === true));
			}
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (!isFocused) {
			setFocus(false);
		}
		if (isFocused) {
			setFocus(true);
			getAlerts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFocused]);

	useEffect(
		() => () => {
			isMounted.current = true;
			setFocus(false);
		},
		[]
	);

	const AllRoute = () => (
		<View style={[styles.loadContainer, styles.horizontal]}>
			{loading ? (
				<ActivityIndicator color={PRIMARY_COLOR} size="large" />
			) : (
				<View style={[styles.loadContainer, styles.horizontal]}>
					{!alertData.length ? (
						<View style={styles.form}>
							<Text>No Alerts Found</Text>
						</View>
					) : (
						<FlatList
							showsVerticalScrollIndicator={false}
							data={alertData}
							renderItem={renderItem}
							keyExtractor={(item: any, i: any) => i}
							refreshing={false}
							onRefresh={getAlerts}
						/>
					)}
				</View>
			)}
		</View>
	);

	const TodayRoute = () => (
		<View style={[styles.loadContainer, styles.horizontal]}>
			{loading ? (
				<ActivityIndicator color={PRIMARY_COLOR} size="large" />
			) : (
				<View style={[styles.loadContainer, styles.horizontal]}>
					{!alertDataToday.length ? (
						<View style={styles.form}>
							<Text>No Alerts Found for Today</Text>
						</View>
					) : (
						<FlatList
							showsVerticalScrollIndicator={false}
							data={alertDataToday}
							renderItem={renderItem}
							keyExtractor={(item: any, i: any) => i}
							refreshing={false}
							onRefresh={getAlerts}
						/>
					)}
				</View>
			)}
		</View>
	);

	const renderScene = SceneMap({
		today: TodayRoute,
		all: AllRoute,
	});

	const renderItem = ({ item }: any) => (
		<View>
			<HomeAlertsNotifcationsCard alert={item} />
		</View>
	);

	const renderTabBar = (
		props: JSX.IntrinsicAttributes &
			SceneRendererProps & {
				navigationState: NavigationState<Route>;
				scrollEnabled?: boolean | undefined;
				bounces?: boolean | undefined;
				activeColor?: string | undefined;
				inactiveColor?: string | undefined;
				pressColor?: string | undefined;
				pressOpacity?: number | undefined;
				getLabelText?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getAccessible?: ((scene: Scene<Route>) => boolean | undefined) | undefined;
				getAccessibilityLabel?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getTestID?: ((scene: Scene<Route>) => string | undefined) | undefined;
				renderLabel?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderIcon?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderBadge?: ((scene: Scene<Route>) => React.ReactNode) | undefined;
				renderIndicator?:
					| ((props: TabBarIndicatorProps<Route>) => React.ReactNode)
					| undefined;
				renderTabBarItem?:
					| ((
							props: TabBarItemProps<Route> & { key: string }
					  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
					| undefined;
				onTabPress?: ((scene: Scene<Route> & Event) => void) | undefined;
				onTabLongPress?: ((scene: Scene<Route>) => void) | undefined;
				tabStyle?: StyleProp<ViewStyle>;
				indicatorStyle?: StyleProp<ViewStyle>;
				indicatorContainerStyle?: StyleProp<ViewStyle>;
				labelStyle?: StyleProp<TextStyle>;
				contentContainerStyle?: StyleProp<ViewStyle>;
				style?: StyleProp<ViewStyle>;
				gap?: number | undefined;
			}
	) => (
		<View>
			<TabBar
				{...props}
				indicatorStyle={styles.indicator}
				scrollEnabled
				style={{
					backgroundColor: Colors.light.background,
				}}
				renderLabel={({ route, focused }) => {
					return (
						<Text
							style={{
								color: focused ? PRIMARY_COLOR : Colors.dark.background,
							}}
						>
							{route.title}
						</Text>
					);
				}}
			/>
		</View>
	);

	return (
		<View style={styles.container}>
			<ProfileHeader navigation={navigation} />
			<View style={styles.innerContainer}>
				<View style={styles.headingWrapper}>
					<Text style={styles.title}>Alerts & Notifications</Text>
				</View>

				<View style={styles.tabWrapper}>
					<TabView
						navigationState={{ index, routes }}
						renderScene={renderScene}
						onIndexChange={setIndex}
						initialLayout={{ width: layout.width }}
						style={styles.tab}
						renderTabBar={renderTabBar}
					/>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
	},
	innerContainer: {
		padding: 14,
		flex: 1,
	},
	headingWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	title: {
		fontFamily: FONT_BOLD,
		color: PRIMARY_COLOR,
		fontSize: 21,
	},
	form: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	tabWrapper: {
		flexDirection: 'row',
		width: '100%',
		height: '100%',
	},
	tab: {
		marginTop: 10,
	},
	loadContainer: {
		flex: 1,
		justifyContent: 'center',
	},
	horizontal: {
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	indicator: {
		backgroundColor: PRIMARY_COLOR,
		marginBottom: 5,
	},
});
