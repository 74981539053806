/**
 * Defines the app constants
 */

export const IS_NEW_USER = 'is_new_user';
export const IS_OLD_USER = 'is_old_user';
export const YES = 'yes';
export const NO = 'no';
export const DOS_DATA = [
	{
		description: 'Tune to your local radio for warnings and advice.',
	},
	{
		description: 'Move to higher ground.',
	},
	{
		description:
			'Move vehicles, equipments, chemicals, old persons, children, pregnant women, etc., to higher and safer places.',
	},
	{
		description: 'Disconnect all electrical appliances.',
	},
	{
		description: 'Turn off electricity and gas before you leave the house.',
	},
	{
		description:
			'Tie your valuable items or articles and keep them in a safe and high place or bury it  underground.',
	},
	{
		description:
			'Shift the livestock and cattle to safe places or high land. Keep some feed and fodder for them.',
	},
	{
		description: 'Insure household articles, livestock and crop.',
	},
	{
		description: 'Collect your survival kit or basic essentials before leaving your house.',
	},
	{
		description: 'Think of safety first during a flood.',
	},
	{
		description: 'Take care when walking through shallow waters.',
	},
];

export const DONTS_DATA = [
	{
		description: 'Do not allow children to play in, or near flood waters.',
	},
	{
		description: 'Do not wander around in a flooded area.',
	},
	{
		description: 'Do not drive into water of unknown depth and current.',
	},
	{
		description: 'Do not eat food, which is affected by flood waters.',
	},
	{
		description: 'Do not try to walk or drive through flood waters.',
	},
	{
		description: 'Do not walk across bridges as they may collapse.',
	},
	{
		description: 'Do not contact flood waters to avoid contamination.',
	},
	{
		description:
			'Do not walk through flowing water - currents can be deceptive, and shallow,fast moving water can knock you off your feet.',
	},
	{
		description:
			'Do not swim through fast flowing water or you may get swept away or struck by an object in the water.',
	},
];
