import { createSlice } from '@reduxjs/toolkit';

export const currentLocationSlice = createSlice({
	name: 'currentLocation',
	initialState: {
		value: {
			granted: true,
		},
	},
	reducers: {
		currentLocation: (state, action) => {
			state.value = action.payload;
		},
	},
});
export const { currentLocation } = currentLocationSlice.actions;

export default currentLocationSlice.reducer;
