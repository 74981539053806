import { useEffect, useState } from 'react';
import { Alert, Dimensions, Modal, Pressable, StyleSheet, Text, View } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { EvilIcons } from '@expo/vector-icons';

//constants
import { PRIMARY_COLOR } from '../../common/constants/Colors';
import { COMMON, DM, RIVER_TYPE, USER } from '../../common/constants/Constants';
import { FONT_BOLD } from '../../common/constants/Fonts';
import { sharedStyles } from '../../common/constants/SharedStyles';
import useAuth from '../../common/hooks/useAuth';
import axios from '../../common/utils/axios';
import { locationDetails } from '../../redux/slices/location';
import { locationLabelDetails } from '../../redux/slices/locationLabel';
import { useDispatch, useSelector } from '../../redux/store';

type Option = {
	label: string;
	value: number;
};

const { height: deviceHeight, width: deviceWidth } = Dimensions.get('window');

type Props = {
	openState: boolean;
	handleClose: () => void;
};

export default function DropdownModal(props: Props) {
	const AuthDetails = useSelector((state) => state.auth.value);
	let user = AuthDetails.userType;
	const { user: details } = useAuth();
	const [showNext, setShowNext] = useState<boolean>(
		details && details.designation && details.designation.label === USER ? true : false
	);
	const JurisDetails = useSelector((state) => state.location.value);
	const JurisLabels = useSelector((state) => state.locationLabel.value);
	const dispatch = useDispatch();

	//admin dashboard
	const [openZone, setOpenZone] = useState(false);
	const [openCircle, setOpenCircle] = useState(false);
	const [openDivision, setOpenDivision] = useState(false);
	const [openSubDivision, setOpenSubDivision] = useState(false);
	const [_openSection, setOpenSection] = useState(false);
	const [openRiver, setOpenRiver] = useState(false);
	const [openBasin, setOpenBasin] = useState(false);
	const [_openSubBasin, setOpenSubBasin] = useState(false);
	const [openRiverType, setOpenRiverType] = useState(false);
	const [openDistrict, setOpenDistrict] = useState(false);
	const [openBlock, setOpenBlock] = useState(false);
	const [openVillage, setOpenVillage] = useState(false);

	//zone
	const [zoneList, setZoneList] = useState<Option[]>([]);
	const [zonePage, setZonePage] = useState<number>(1);
	const [zoneLastPage, setZoneLastPage] = useState<number>(1);
	const [zoneValue, setZoneValue] = useState<Option | any>(null);
	//Circle
	const [circleList, setCircleList] = useState<Option[]>([]);
	const [circlePage, setCirclePage] = useState<number>(1);
	const [circleLastPage, setCircleLastPage] = useState<number>(1);
	const [circleValue, setCircleValue] = useState<Option | any>(null);
	//Division
	const [divisionList, setDivisionList] = useState<Option[]>([]);
	const [divisionPage, setDivisionPage] = useState<number>(1);
	const [divisionLastPage, setDivisionLastPage] = useState<number>(1);
	const [divisionValue, setDivisionValue] = useState<Option | any>(null);
	// //Sub-Division
	const [subDivisionList, setSubDivisionList] = useState<Option[]>([]);
	const [subDivisionPage, setSubDivisionPage] = useState<number>(1);
	const [subDivisionLastPage, setSubDivisionLastPage] = useState<number>(1);
	const [subDivisionValue, setSubDivisionValue] = useState<Option | any>(null);
	//section
	const [_sectionPage, setSectionPage] = useState<number>(1);
	//river
	const [riverList, setRiverList] = useState<Option[]>([]);
	const [riverPage, setRiverPage] = useState<number>(1);
	const [riverLastPage, setRiverLastPage] = useState<number>(1);
	const [riverValue, setRiverValue] = useState<Option | any>(null);
	const [riverName, setRiverName] = useState<Option | any>(null);
	//basin
	const [basinList, setBasinList] = useState<Option[]>([]);
	const [basinPage, setBasinPage] = useState<number>(1);
	const [basinLastPage, setBasinLastPage] = useState<number>(1);
	const [basinValue, setBasinValue] = useState<Option | any>(null);
	//river type
	const [riverTypeList, setRiverTypeList] = useState(RIVER_TYPE);
	const [riverTypePage, setRiverTypePage] = useState<number>(1);
	const [riverTypeLastPage] = useState<number>(1);
	const [riverTypeValue, setRiverTypeValue] = useState<Option | any>(null);

	//district
	const [districtList, setDistrictList] = useState<Option[]>([]);
	const [districtPage, setDistrictPage] = useState<number>(1);
	const [districtLastPage, setDistrictLastPage] = useState<number>(1);
	const [districtValue, setDistrictValue] = useState<Option | any>(null);
	const [districtLabel, setDistrictLabel] = useState('');
	//block
	const [blockList, setBlockList] = useState<Option[]>([]);
	const [blockPage, setBlockPage] = useState<number>(1);
	const [blockLastPage, setBlockLastPage] = useState<number>(1);
	const [blockValue, setBlockValue] = useState<Option | any>(null);
	const [blockLabel, setBlockLabel] = useState<String | null>(null);
	//village
	const [villageList, setVillageList] = useState<Option[]>([]);
	const [villagePage, setVillagePage] = useState<number>(1);
	const [villageLastPage, setVillageLastPage] = useState<number>(1);
	const [villageValue, setVillageValue] = useState<Option | any>(null);
	const [villageLabel, setVillageLabel] = useState('');

	//zone
	useEffect(() => {
		const getZoneList = async (check: boolean) => {
			setCirclePage(1);
			setDivisionPage(1);
			setSubDivisionPage(1);
			setDistrictPage(1);
			setBlockPage(1);
			setVillagePage(1);
			setRiverPage(1);
			setBasinPage(1);
			setSectionPage(1);
			try {
				await axios
					.get('/api/master/zone-list', {
						params: { page: zonePage },
					})
					.then((response) => {
						const { data } = response.data;
						if (data && data.items) {
							setZoneLastPage(data.info.lastPage);
							if (zonePage === 1) {
								setZoneList(data.items);
							} else {
								setZoneList((prev) => [...prev, ...data.items]);
							}
							// if (
							//     !zoneValue &&
							//     details &&
							//     details.defaultjurisdiction &&
							//     details.defaultjurisdiction.zone
							// ) {
							// setZoneValue(details.defaultjurisdiction.zone.value);
							// getDistrictList(
							//     false,
							//     details.jurisdiction.zone.value
							// );
							//     getCircleList(true);
							// }
							if (!zoneValue && JurisDetails && JurisDetails.zoneId && check) {
								setZoneValue(JurisDetails.zoneId);
								getCircleList(false, JurisDetails.zoneId, true);
							}
						}
					});
			} catch (error: any) {
				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			}
		};
		if (zonePage <= zoneLastPage && props.openState) {
			if (zoneValue === null) {
				if (JurisDetails.zoneId) {
					getZoneList(true);
				} else {
					getZoneList(false);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zonePage, props.openState]);

	//circle
	const getCircleList = async (refresh: boolean, zone_value: string, check: boolean) => {
		if (refresh) {
			setCirclePage(1);
		}
		if ((refresh && circlePage > 1) || !zone_value) {
			return;
		}
		setDivisionPage(1);
		setSubDivisionPage(1);
		// setSectionPage(1);
		setDistrictPage(1);
		setBlockPage(1);
		setVillagePage(1);
		setRiverPage(1);
		setBasinPage(1);
		setCircleValue(null);
		setDivisionList([]);
		setDivisionValue(null);
		setSubDivisionList([]);
		setSubDivisionValue(null);
		// setSectionValue(null);
		// setSectionList([]);
		setDistrictValue(null);
		setDistrictList([]);
		setBlockValue(null);
		setBlockList([]);
		setVillageValue(null);
		setVillageList([]);
		setRiverValue(null);
		setRiverList([]);
		setBasinValue(null);
		setBasinList([]);
		setRiverTypeValue(null);
		try {
			await axios
				.get('/api/master/circle', {
					params: {
						page: circlePage,
						zone_id: zone_value && zone_value.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setCircleLastPage(data.info.lastPage);
						if (circlePage === 1) {
							setCircleList(data.items);
						} else {
							setCircleList((prev) => [...prev, ...data.items]);
						}
						if (!circleValue && JurisDetails && JurisDetails.circleId && check) {
							setCircleValue(JurisDetails.circleId);
							getDivisionList(false, JurisDetails.circleId, true);
						}
					} else {
						setCircleList([]);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (
			zoneValue !== null &&
			circlePage <= circleLastPage &&
			details &&
			details.designation.label !== DM &&
			details.designation.label !== USER
		) {
			getCircleList(false, zoneValue, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [circlePage]);

	//division

	const getDivisionList = async (refresh: boolean, circle_value: string, check: boolean) => {
		if (refresh) {
			setDivisionPage(1);
		}
		if ((refresh && divisionPage > 1) || !circle_value) {
			return;
		}
		setSubDivisionPage(1);
		// setSectionPage(1);
		setDistrictPage(1);
		setBlockPage(1);
		setVillagePage(1);
		setRiverPage(1);
		setBasinPage(1);
		setDivisionValue(null);
		setSubDivisionList([]);
		setSubDivisionValue(null);
		// setSectionValue(null);
		// setSectionList([]);
		setDistrictValue(null);
		setDistrictList([]);
		setBlockValue(null);
		setBlockList([]);
		setVillageValue(null);
		setVillageList([]);
		setRiverValue(null);
		setRiverList([]);
		setBasinValue(null);
		setBasinList([]);
		setRiverTypeValue(null);
		try {
			await axios
				.get('/api/master/division', {
					params: {
						page: divisionPage,
						circle_id: circle_value && circle_value.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setDivisionLastPage(data.info.lastPage);
						if (divisionPage === 1) {
							setDivisionList(data.items);
						} else {
							setDivisionList((prev) => [...prev, ...data.items]);
						}
						// if (
						//     !divisionValue &&
						//     details &&
						//     details.jurisdiction &&
						//     details.jurisdiction.division
						// ) {
						//     setDivisionValue(
						//         details.jurisdiction.division.value
						//     );
						//     getSubDivisionList(true);
						// }
						if (
							!divisionValue &&
							JurisDetails &&
							JurisDetails &&
							JurisDetails.divisionId &&
							check
						) {
							setDivisionValue(JurisDetails.divisionId);
							getSubDivisionList(false, JurisDetails.divisionId, true);
						}
					} else {
						setDivisionList([]);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (circleValue !== null && divisionPage <= divisionLastPage) {
			getDivisionList(false, circleValue, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [divisionPage]);

	//sub division

	const getSubDivisionList = async (refresh: boolean, division_value: string, check: boolean) => {
		if (refresh) {
			setSubDivisionPage(1);
		}
		if ((refresh && subDivisionPage > 1) || !division_value) {
			return;
		}
		// setSectionPage(1);
		setDistrictPage(1);
		setBlockPage(1);
		setVillagePage(1);
		setRiverPage(1);
		setBasinPage(1);
		setSubDivisionValue(null);
		// setSectionValue(null);
		// setSectionList([]);
		setDistrictValue(null);
		setDistrictList([]);
		setBlockValue(null);
		setBlockList([]);
		setVillageValue(null);
		setVillageList([]);
		setRiverValue(null);
		setRiverList([]);
		setBasinValue(null);
		setBasinList([]);
		setRiverTypeValue(null);
		try {
			await axios
				.get('/api/master/sub-division', {
					params: {
						page: subDivisionPage,
						division_id: division_value && division_value.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setSubDivisionLastPage(data.info.lastPage);
						if (subDivisionPage === 1) {
							setSubDivisionList(data.items);
						} else {
							setSubDivisionList((prev) => [...prev, ...data.items]);
						}
						// if (
						//     !subDivisionValue &&
						//     details &&
						//     details.jurisdiction &&
						//     details.jurisdiction.sub_division
						// ) {
						//     setSubDivisionValue(
						//         details.jurisdiction.sub_division.value
						//     );
						//     getSectionList(true);
						// }
						if (
							!subDivisionValue &&
							JurisDetails &&
							JurisDetails.sub_divisionId &&
							check
						) {
							setSubDivisionValue(JurisDetails.sub_divisionId);
							getDistrictList(false, JurisDetails.sub_divisionId, true);
						}
					} else {
						setSubDivisionList([]);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (divisionValue !== null && subDivisionPage <= subDivisionLastPage) {
			getSubDivisionList(false, divisionValue, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subDivisionPage]);

	//section

	// const getSectionList = async (refresh: boolean) => {
	//     if (refresh) {
	//         setSectionPage(1);
	//     }
	//     if (
	//         (refresh && sectionPage > 1) ||
	//         !subDivisionValue ||
	//         subDivisionValue === ""
	//     ) {
	//         return;
	//     }
	//     setSectionValue(null);
	//     setSectionList([]);
	//     try {
	//         await axios
	//             .get("/api/master/section", {
	//                 params: {
	//                     page: sectionPage,
	//                     subdivision_id: subDivisionValue
	//                         .toString()
	//                         .split(" ")[0],
	//                 },
	//             })
	//             .then((response) => {
	//                 const { data } = response.data;
	//                 if (data && data.items) {
	//                     setSectionLastPage(data.info.lastPage);
	//                     if (sectionPage === 1) {
	//                         setSectionList(data.items);
	//                     } else {
	//                         setSectionList((prev) => [...prev, ...data.items]);
	//                     }
	//                 } else {
	//                     setSectionList([]);
	//                 }
	//             });
	//     } catch (error: any) {
	//         Alert.alert(
	//             "Alert!",
	//             error.message ? error.message : "Something went wrong!",
	//             [
	//                 {
	//                     text: "Cancel",
	//                     style: "cancel",
	//                 },
	//                 {
	//                     text: "OK",
	//                 },
	//             ]
	//         );
	//     }
	// };

	// useEffect(() => {
	//     if (subDivisionValue !== null && sectionPage <= sectionLastPage) {
	//         getSectionList(false);
	//     }
	// }, [sectionPage, subDivisionValue]);

	//district

	const getDistrictList = async (refresh: boolean, subDivision_value: any, check: boolean) => {
		if (refresh) {
			setDistrictPage(1);
		}
		if ((refresh && districtPage > 1) || !subDivision_value) {
			return;
		}
		setBlockPage(1);
		setVillagePage(1);
		setRiverPage(1);
		setBasinPage(1);
		setDistrictValue(null);
		setBlockList([]);
		setBlockValue(null);
		setVillageList([]);
		setVillageValue(null);
		setRiverList([]);
		setRiverValue(null);
		setBasinList([]);
		setBasinValue(null);
		setRiverTypeValue(null);
		try {
			await axios
				.get('/api/master/district', {
					params: {
						page: districtPage,
						// zone_id: zoneValue.toString().split(" ")[0],
						sub_division_id:
							subDivision_value && subDivision_value.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setDistrictLastPage(data.info.lastPage);
						if (districtPage === 1) {
							setDistrictList(data.items);
						} else {
							setDistrictList((prev) => [...prev, ...data.items]);
						}
						if (!districtValue && JurisDetails && JurisDetails.districtId && check) {
							setDistrictValue(JurisDetails.districtId);
							if (JurisLabels && JurisLabels.district) {
								setDistrictLabel(JurisLabels.district);
							}
							getBlockList(false, JurisDetails.districtId, subDivision_value, true);
							getRiverList(false, JurisDetails.districtId, true);
						}
					} else {
						setDistrictList([]);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const getAllDistrictList = async (refresh: boolean, check: boolean) => {
		if (refresh) {
			setDistrictPage(1);
		}
		if (refresh && districtPage > 1) {
			return;
		}
		setBlockPage(1);
		setVillagePage(1);
		setRiverPage(1);
		setBasinPage(1);
		setDistrictValue(null);
		setBlockList([]);
		setBlockValue(null);
		setVillageList([]);
		setVillageValue(null);
		setRiverList([]);
		setRiverValue(null);
		setBasinList([]);
		setBasinValue(null);
		setRiverTypeValue(null);
		try {
			await axios
				.get('/api/master/district', {
					params: {
						page: districtPage,
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setDistrictLastPage(data.info.lastPage);
						if (districtPage === 1) {
							setDistrictList(data.items);
						} else {
							setDistrictList((prev) => [...prev, ...data.items]);
						}
						if (!districtValue && JurisDetails && JurisDetails.districtId && check) {
							setDistrictValue(JurisDetails.districtId);
							if (JurisLabels && JurisLabels.district) {
								setDistrictLabel(JurisLabels.district);
							}
							getAllBlockList(false, JurisDetails.districtId, true);
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (user === COMMON && districtPage <= districtLastPage) {
			setShowNext(true);
			if (districtValue === null) {
				if (JurisDetails.districtId) {
					getAllDistrictList(false, true);
				} else {
					getAllDistrictList(false, false);
				}
			}
		} else {
			if (subDivisionValue !== null && districtPage <= districtLastPage) {
				if (districtValue === null) {
					getDistrictList(false, subDivisionValue, false);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [districtPage, props.openState]);

	//block

	const getBlockList = async (
		refresh: boolean,
		district_value: string,
		subDivision_value: string,
		check: boolean
	) => {
		if (refresh) {
			setBlockPage(1);
		}
		if ((refresh && blockPage > 1) || !district_value) {
			return;
		}
		setVillagePage(1);
		setBlockValue(null);
		setVillageList([]);
		setVillageValue(null);
		try {
			await axios
				.get('/api/master/block', {
					params: {
						page: blockPage,
						sub_division_id:
							subDivision_value && subDivision_value.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setBlockLastPage(data.info.lastPage);
						if (blockPage === 1) {
							setBlockList(data.items);
						} else {
							setBlockList((prev) => [...prev, ...data.items]);
						}
						if (!blockValue && JurisDetails && JurisDetails.blockId && check) {
							setBlockValue(JurisDetails.blockId);
							if (JurisLabels && JurisLabels.block) {
								setBlockLabel(JurisLabels.block);
							}
							getVillageList(false, JurisDetails.blockId);
						}
					} else {
						setBlockList([]);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const getAllBlockList = async (refresh: boolean, district_value: string, check: boolean) => {
		if (refresh) {
			setBlockPage(1);
		}
		if (refresh && blockPage > 1) {
			return;
		}
		setVillagePage(1);
		setBlockValue(null);
		setVillageList([]);
		setVillageValue(null);
		try {
			await axios
				.get('/api/incident/block', {
					params: {
						page: blockPage,
						district_id: district_value && district_value.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setBlockLastPage(data.info.lastPage);
						if (blockPage === 1) {
							setBlockList(data.items);
						} else {
							setBlockList((prev) => [...prev, ...data.items]);
						}
						if (!blockValue && JurisDetails && JurisDetails.blockId && check) {
							setBlockValue(JurisDetails.blockId);
							if (JurisLabels && JurisLabels.block) {
								setBlockLabel(JurisLabels.block);
							}
							getVillageList(false, JurisDetails.blockId);
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (user === COMMON && blockPage <= blockLastPage) {
			getAllBlockList(false, districtValue, false);
		} else {
			if (districtValue !== null && blockPage <= blockLastPage) {
				getBlockList(false, districtValue, subDivisionValue, false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blockPage]);

	//village

	const getVillageList = async (refresh: boolean, block_value: string) => {
		if (refresh) {
			setVillagePage(1);
		}
		if ((refresh && villagePage > 1) || !block_value) {
			return;
		}
		setVillageValue(null);
		try {
			await axios
				.get('/api/master/village', {
					params: {
						page: villagePage,
						block_id: block_value && block_value.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setVillageLastPage(data.info.lastPage);
						if (villagePage === 1) {
							setVillageList(data.items);
						} else {
							setVillageList((prev) => [...prev, ...data.items]);
						}
					} else {
						setVillageList([]);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (blockValue !== null && villagePage <= villageLastPage) {
			getVillageList(false, blockValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [villagePage]);

	//river
	// useEffect(() => {
	//     const getRiverList = async () => {
	//         try {
	//             await axios
	//                 .get("/api/master/river", {
	//                     params: { page: riverPage },
	//                 })
	//                 .then((response) => {
	//                     if (!isMounted.current) {
	//                         const { data } = response.data;
	//                         setRiverLastPage(data.info.lastPage);
	//                         if (riverPage === 1) {
	//                             setRiverList(data.items);
	//                         } else {
	//                             setRiverList((prev) => [
	//                                 ...prev,
	//                                 ...data.items,
	//                             ]);
	//                         }
	//                     }
	//                 });
	//         } catch (error: any) {
	//             Alert.alert(
	//                 "Alert!",
	//                 error.message ? error.message : "Something went wrong!",
	//                 [
	//                     {
	//                         text: "Cancel",
	//                         style: "cancel",
	//                     },
	//                     {
	//                         text: "OK",
	//                     },
	//                 ]
	//             );
	//         }
	//     };
	//     if (riverPage <= riverLastPage && props.openState) {
	//         // getRiverList();
	//     }
	// }, [riverPage, props.openState]);

	const getRiverList = async (refresh: boolean, district_value: string, check: boolean) => {
		if (refresh) {
			setRiverPage(1);
		}
		if ((refresh && riverPage > 1) || !district_value) {
			return;
		}
		setBasinPage(1);
		setRiverValue(null);
		setBasinList([]);
		setBasinValue(null);
		setRiverTypeValue(null);
		try {
			await axios
				.get('/api/master/river', {
					params: {
						page: riverPage,
						district_id: district_value && district_value.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setRiverLastPage(data.info.lastPage);
						if (riverPage === 1) {
							setRiverList(data.items);
						} else {
							setRiverList((prev) => [...prev, ...data.items]);
						}
						if (!riverValue && JurisDetails && JurisDetails.river_id && check) {
							setRiverValue(JurisDetails.river_id);
							if (JurisDetails && JurisDetails.riverName) {
								setRiverName(JurisDetails.riverName);
							}
							getBasinList(false, JurisDetails.river_id);
							if (JurisDetails.riverType) {
								setRiverTypeValue(JurisDetails.riverType);
							}
						}
					} else {
						setRiverList([]);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (districtValue !== null && riverPage <= riverLastPage) {
			getRiverList(false, districtValue, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [riverPage]);

	//basin

	const getBasinList = async (refresh: boolean, river_value: string) => {
		if (refresh) {
			setBasinPage(1);
		}
		if ((refresh && basinPage > 1) || !river_value || river_value === '') {
			return;
		}
		setBasinValue(null);
		try {
			await axios
				.get('/api/master/basin', {
					params: {
						page: basinPage,
						river_id: river_value && river_value.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setBasinLastPage(data.info.lastPage);
						if (basinPage === 1) {
							setBasinList(data.items);
						} else {
							setBasinList((prev) => [...prev, ...data.items]);
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (riverValue !== null && basinPage <= basinLastPage) {
			getBasinList(false, riverValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [basinPage]);

	//sub Basin

	// const getSubBasinList = async (refresh: boolean) => {
	//     if (refresh) {
	//         setSubBasinPage(1);
	//     }
	//     if ((refresh && subBasinPage > 1) || !basinValue || basinValue === "") {
	//         return;
	//     }
	//     try {
	//         await axios
	//             .get("/api/master/sub-basin", {
	//                 params: {
	//                     page: subBasinPage,
	//                 },
	//             })
	//             .then((response) => {
	//                 const { data } = response.data;
	//                 if (data && data.items) {
	//                     setSubBasinLastPage(data.info.lastPage);
	//                     if (subBasinPage === 1) {
	//                         setSubBasinList(data.items);
	//                     } else {
	//                         setSubBasinList((prev) => [...prev, ...data.items]);
	//                     }
	//                 }
	//             });
	//     } catch (error: any) {
	//         Alert.alert(
	//             "Alert!",
	//             error.message ? error.message : "Something went wrong!",
	//             [
	//                 {
	//                     text: "Cancel",
	//                     style: "cancel",
	//                 },
	//                 {
	//                     text: "OK",
	//                 },
	//             ]
	//         );
	//     }
	// };

	// useEffect(() => {
	//     if (basinValue !== null && subBasinPage <= subBasinLastPage) {
	//         getSubBasinList(false);
	//     }
	// }, [subBasinPage]);

	const handleNext = () => {
		if (zoneValue === null) {
			Alert.alert('Alert!', 'Please select a zone!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else {
			setShowNext(true);
		}
	};
	const handleClose = () => {
		if (details && details.designation && details.designation.label !== USER) {
			setShowNext(false);
		}

		props.handleClose();
	};

	const handleApply = () => {
		if (
			districtValue === null ||
			blockValue === null ||
			riverValue === null ||
			riverTypeValue === null
		) {
			Alert.alert('Alert!', 'Please select the required fields!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else {
			dispatch(
				locationLabelDetails({
					district: districtLabel,
					block: blockLabel,
					village: villageLabel,
				})
			);
			if (basinValue === null) {
				dispatch(
					locationDetails({
						zoneId: zoneValue,
						circleId: circleValue,
						divisionId: divisionValue,
						sub_divisionId: subDivisionValue,
						districtId: districtValue,
						blockId: blockValue,
						river_id: riverValue,
						riverType: riverTypeValue,
						riverName: riverName,
						refreshLocation: true,
					})
				);

				if (details && details.designation && details.designation.label !== USER) {
					setShowNext(false);
				}
				props.handleClose();
			} else {
				dispatch(
					locationDetails({
						zoneId: zoneValue,
						circleId: circleValue,
						divisionId: divisionValue,
						sub_divisionId: subDivisionValue,
						districtId: districtValue,
						blockId: blockValue,
						river_id: riverValue,
						riverType: riverTypeValue,
						riverName: riverName,
						basin_id: basinValue,
						refreshLocation: true,
					})
				);
				if (details && details.designation && details.designation.label !== USER) {
					setShowNext(false);
				}
				props.handleClose();
			}
		}
	};

	return (
		<Modal
			animationType={props.openState ? 'slide' : 'fade'}
			visible={props.openState}
			transparent
			statusBarTranslucent={true}
		>
			<View style={styles.container}>
				<View
					style={StyleSheet.flatten([
						styles.jurisWrapper,
						{ height: !showNext ? 470 : 550 },
					])}
				>
					<View style={styles.headWrap}>
						<Text style={styles.jurisHeading}>Choose Jurisdiction Details</Text>
						<Pressable onPress={props.handleClose}>
							<EvilIcons name="close" size={24} color={PRIMARY_COLOR} />
						</Pressable>
					</View>

					{!showNext && user !== COMMON ? (
						<>
							{/* {details && details.designation.label !== DM && ( */}
							<>
								<Text style={styles.locationHeading}>Zone</Text>

								<View
									style={StyleSheet.flatten([
										styles.dropDownWrapper,
										{
											zIndex: 10,
											height: openZone ? 180 : 80,
										},
									])}
								>
									<Text style={styles.selectTitle}>
										Zone
										<Text style={styles.textRed}>*</Text>
									</Text>
									<DropDownPicker
										listMode="FLATLIST"
										dropDownDirection="BOTTOM"
										style={sharedStyles.input}
										open={openZone}
										items={zoneList}
										onOpen={() => {
											setOpenCircle(false);
											setOpenDivision(false);
											setOpenSubDivision(false);
											setOpenSection(false);
										}}
										setOpen={setOpenZone}
										value={zoneValue}
										labelProps={{ numberOfLines: 1 }}
										setValue={setZoneValue}
										setItems={setZoneList}
										zIndex={1000}
										zIndexInverse={3000}
										placeholder="Select"
										flatListProps={{
											onEndReached: () => {
												if (zonePage < zoneLastPage) {
													setZonePage(zonePage + 1);
												}
											},
											onEndReachedThreshold: 0.9,
											keyExtractor: (item, index) => index.toString(),
										}}
										onSelectItem={(val) => {
											getCircleList(true, val.value, false);
										}}
										// onChangeValue={() => {
										//     getCircleList(true, false);
										// getDistrictList(
										//     false,
										//     zoneValue
										// );
										// }}
										placeholderStyle={styles.placeholder}
										dropDownContainerStyle={styles.dropDown}
									/>
								</View>
							</>
							{/* )} */}
							<Text style={styles.locationHeading}>Department</Text>
							<View style={styles.selectWrapper}>
								<View
									style={StyleSheet.flatten([
										styles.dropDownWrapper,
										{
											zIndex: 9,
											height: openCircle ? 180 : 'auto',
											marginBottom: circleValue && 5,
										},
									])}
								>
									<Text style={styles.selectTitle}>Circle</Text>

									<DropDownPicker
										listMode="FLATLIST"
										dropDownDirection="BOTTOM"
										style={sharedStyles.input}
										open={openCircle}
										value={circleValue}
										labelProps={{ numberOfLines: 1 }}
										setValue={setCircleValue}
										items={circleList}
										onOpen={() => {
											setOpenZone(false);
											setOpenDivision(false);
											setOpenSubDivision(false);
											setOpenSection(false);
										}}
										setOpen={setOpenCircle}
										setItems={setCircleList}
										zIndex={1000}
										zIndexInverse={3000}
										placeholder="Select"
										flatListProps={{
											onEndReached: () => {
												if (circlePage < circleLastPage) {
													setCirclePage(circlePage + 1);
												}
											},
											onEndReachedThreshold: 0.9,
											keyExtractor: (item, index) => index.toString(),
										}}
										disabled={zoneValue === null ? true : false}
										onSelectItem={(val) => {
											getDivisionList(true, val.value, false);
										}}
										// onChangeValue={() => {
										//     getDivisionList(true);
										// }}
										placeholderStyle={styles.placeholder}
										dropDownContainerStyle={styles.dropDown}
										listItemContainerStyle={styles.listItemContainer}
									/>
								</View>
								<View
									style={StyleSheet.flatten([
										styles.dropDownWrapper,
										{
											zIndex: 8,
											height: openDivision ? 180 : 70,
										},
									])}
								>
									<Text style={styles.selectTitle}>Division</Text>
									<DropDownPicker
										listMode="FLATLIST"
										dropDownDirection="BOTTOM"
										style={sharedStyles.input}
										open={openDivision}
										value={divisionValue}
										labelProps={{ numberOfLines: 1 }}
										setValue={setDivisionValue}
										onOpen={() => {
											setOpenCircle(false);
											setOpenZone(false);
											setOpenSubDivision(false);
											setOpenSection(false);
										}}
										items={divisionList}
										setOpen={setOpenDivision}
										setItems={setDivisionList}
										zIndex={1000}
										zIndexInverse={3000}
										placeholder="Select"
										flatListProps={{
											onEndReached: () => {
												if (divisionPage < divisionLastPage) {
													setDivisionPage(divisionPage + 1);
												}
											},
											onEndReachedThreshold: 0.9,
											keyExtractor: (item, index) => index.toString(),
										}}
										disabled={circleValue === null ? true : false}
										onSelectItem={(val) => {
											getSubDivisionList(true, val.value, false);
										}}
										// onChangeValue={() => {
										//     getSubDivisionList(true);
										// }}
										placeholderStyle={styles.placeholder}
										dropDownContainerStyle={styles.dropDown}
										listItemContainerStyle={styles.listItemContainer}
									/>
								</View>
								<View
									style={StyleSheet.flatten([
										styles.dropDownWrapper,
										{
											zIndex: 7,
											height: openSubDivision ? 180 : 80,
										},
									])}
								>
									<Text style={styles.selectTitle}>Sub-division</Text>

									<DropDownPicker
										listMode="FLATLIST"
										dropDownDirection="BOTTOM"
										style={sharedStyles.input}
										open={openSubDivision}
										value={subDivisionValue}
										labelProps={{ numberOfLines: 1 }}
										setValue={setSubDivisionValue}
										onOpen={() => {
											setOpenCircle(false);
											setOpenDivision(false);
											setOpenZone(false);
											setOpenSection(false);
										}}
										items={subDivisionList}
										setOpen={setOpenSubDivision}
										setItems={setSubDivisionList}
										zIndex={1000}
										zIndexInverse={3000}
										placeholder="Select"
										flatListProps={{
											onEndReached: () => {
												if (subDivisionPage < subDivisionLastPage) {
													setSubDivisionPage(subDivisionPage + 1);
												}
											},
											onEndReachedThreshold: 0.9,
											keyExtractor: (item, index) => index.toString(),
										}}
										disabled={divisionValue === null ? true : false}
										onSelectItem={(val) => {
											getDistrictList(true, val.value, false);
										}}
										// onChangeValue={() => {
										//     // getSectionList(true);
										//     getDistrictList(
										//         true,
										//         subDivisionValue
										//     );
										// }}
										placeholderStyle={styles.placeholder}
										dropDownContainerStyle={styles.dropDown}
										listItemContainerStyle={StyleSheet.flatten([
											styles.dropDown,
											{ marginVertical: 5 },
										])}
									/>
								</View>
								{/* <View
                                    style={[
                                        styles.dropDownWrapper,
                                        {
                                            zIndex: 6,
                                            height: openSection ? 180 : 80,
                                        },
                                    ]}
                                >
                                    <Text style={styles.selectTitle}>
                                        Section
                                    </Text>
                                    <DropDownPicker
                                        listMode="FLATLIST"
                                        style={sharedStyles.input}
                                        open={openSection}
                                        value={sectionValue}
                                        setValue={setSectionValue}
                                        items={sectionList}
                                        setOpen={setOpenSection}
                                        setItems={setSectionList}
                                        zIndex={1000}
                                        zIndexInverse={3000}
                                        onOpen={() => {
                                            setOpenCircle(false);
                                            setOpenDivision(false);
                                            setOpenSubDivision(false);
                                            setOpenZone(false);
                                        }}
                                        placeholder="Select"
                                        flatListProps={{
                                            onEndReached: () => {
                                                if (
                                                    sectionPage <
                                                    sectionLastPage
                                                ) {
                                                    setSectionPage(
                                                        sectionPage + 1
                                                    );
                                                }
                                            },
                                            onEndReachedThreshold: 0.9,
                                            keyExtractor: (item, index) =>
                                                index.toString(),
                                        }}
                                        placeholderStyle={{
                                            color: "#A7A7A7",
                                            fontSize: 12,
                                        }}
                                        dropDownContainerStyle={{
                                            borderColor: "#A7A7A7",
                                            zIndex: 54324,
                                            width: "100%",
                                            maxHeight: 120,
                                        }}
                                    />
                                </View> */}
							</View>
						</>
					) : (
						<>
							<>
								<Text style={styles.locationHeading}>District</Text>
								<View style={styles.selectWrapper}>
									<View
										style={StyleSheet.flatten([
											styles.dropDownWrapper,
											{
												zIndex: 10,
												height: openDistrict ? 180 : 80,
											},
										])}
									>
										<Text style={styles.selectTitle}>
											District
											<Text style={styles.textRed}>*</Text>
										</Text>
										<DropDownPicker
											listMode="FLATLIST"
											dropDownDirection="BOTTOM"
											style={sharedStyles.input}
											open={openDistrict}
											onOpen={() => {
												setOpenBlock(false);
												setOpenVillage(false);
												setOpenRiver(false);
												setOpenBasin(false);
												setOpenSubBasin(false);
												setOpenRiverType(false);
											}}
											items={districtList}
											setOpen={setOpenDistrict}
											value={districtValue}
											labelProps={{ numberOfLines: 1 }}
											setValue={setDistrictValue}
											setItems={setDistrictList}
											zIndex={1000}
											zIndexInverse={3000}
											placeholder="Select"
											flatListProps={{
												onEndReached: () => {
													if (districtPage < districtLastPage) {
														setDistrictPage(districtPage + 1);
													}
												},
												onEndReachedThreshold: 0.9,
												keyExtractor: (item, index) => index.toString(),
											}}
											disabled={
												user !== COMMON &&
												(subDivisionValue === null ? true : false)
											}
											onSelectItem={(val) => {
												if (val) {
													let lb = val.label?.toString();
													if (lb) {
														setDistrictLabel(lb);
													}
													if (user === COMMON) {
														getAllBlockList(true, val.value, false);
													} else {
														getBlockList(
															true,
															val.value,
															subDivisionValue,
															false
														);
													}
													getRiverList(true, val.value, false);
												}
											}}
											// onChangeValue={(label) => {
											//     if (user === COMMON) {
											//         getAllBlockList(true);
											//     } else {
											//         getBlockList(true);
											//     }
											//     getRiverList(true);
											// }}
											placeholderStyle={styles.placeholder}
											dropDownContainerStyle={styles.dropDown}
										/>
									</View>
									<View
										style={StyleSheet.flatten([
											styles.dropDownWrapper,
											{
												zIndex: openBlock ? 11 : 9,
												height: openBlock ? 180 : 80,
											},
										])}
									>
										<Text style={styles.selectTitle}>
											Block
											<Text style={styles.textRed}>*</Text>
										</Text>
										<DropDownPicker
											listMode="FLATLIST"
											dropDownDirection="BOTTOM"
											style={sharedStyles.input}
											open={openBlock}
											items={blockList}
											setOpen={setOpenBlock}
											value={blockValue}
											labelProps={{ numberOfLines: 1 }}
											setValue={setBlockValue}
											setItems={setBlockList}
											zIndex={1000}
											onOpen={() => {
												setOpenDistrict(false);
												setOpenVillage(false);
												setOpenRiver(false);
												setOpenBasin(false);
												setOpenSubBasin(false);
												setOpenRiverType(false);
											}}
											zIndexInverse={3000}
											placeholder="Select"
											flatListProps={{
												onEndReached: () => {
													if (blockPage < blockLastPage) {
														setBlockPage(blockPage + 1);
													}
												},
												onEndReachedThreshold: 0.9,
												keyExtractor: (item, index) => index.toString(),
											}}
											disabled={districtValue === null ? true : false}
											onSelectItem={(val) => {
												if (val) {
													let lb = val.label?.toString();
													if (lb) {
														setBlockLabel(lb);
													}
													getVillageList(true, val.value);
												}
											}}
											// onChangeValue={() => {
											//     getVillageList(true);
											// }}
											placeholderStyle={styles.placeholder}
											dropDownContainerStyle={styles.dropDown}
										/>
									</View>
									<View
										style={StyleSheet.flatten([
											styles.dropDownWrapper,
											{
												zIndex: 9,
												height: openVillage ? 180 : 80,
												marginTop: openBlock ? -100 : 0,
											},
										])}
									>
										<Text style={styles.selectTitle}>Village</Text>
										<DropDownPicker
											listMode="FLATLIST"
											dropDownDirection="BOTTOM"
											style={sharedStyles.input}
											open={openVillage}
											items={villageList}
											setOpen={setOpenVillage}
											value={villageValue}
											labelProps={{ numberOfLines: 1 }}
											setValue={setVillageValue}
											onOpen={() => {
												setOpenDistrict(false);
												setOpenBlock(false);
												setOpenRiver(false);
												setOpenBasin(false);
												setOpenSubBasin(false);
												setOpenRiverType(false);
											}}
											setItems={setVillageList}
											zIndex={1000}
											zIndexInverse={3000}
											placeholder="Select"
											flatListProps={{
												onEndReached: () => {
													if (villagePage < villageLastPage) {
														setVillagePage(villagePage + 1);
													}
												},
												onEndReachedThreshold: 0.9,
												keyExtractor: (item, index) => index.toString(),
											}}
											disabled={blockValue === null ? true : false}
											onSelectItem={(e) => {
												if (e) {
													let lb = e.label?.toString();
													if (lb) {
														setVillageLabel(lb);
													}
												}
											}}
											placeholderStyle={styles.placeholder}
											dropDownContainerStyle={styles.dropDown}
										/>
									</View>
								</View>
							</>

							<View style={styles.riverContainer}>
								<Text style={styles.locationHeading}>Choose River -</Text>
								<View style={styles.selectWrapper}>
									<View
										style={StyleSheet.flatten([
											styles.dropDownWrapper,
											{
												zIndex: 10,
												height: openRiver ? 180 : 80,
											},
										])}
									>
										<Text style={styles.selectTitle}>
											River
											<Text style={styles.textRed}>*</Text>
										</Text>
										<DropDownPicker
											listMode="FLATLIST"
											dropDownDirection="BOTTOM"
											style={sharedStyles.input}
											open={openRiver}
											items={riverList}
											setOpen={setOpenRiver}
											onOpen={() => {
												setOpenDistrict(false);
												setOpenBlock(false);
												setOpenVillage(false);
												setOpenBasin(false);
												setOpenSubBasin(false);
												setOpenRiverType(false);
											}}
											value={riverValue}
											labelProps={{ numberOfLines: 1 }}
											setValue={setRiverValue}
											setItems={setRiverList}
											zIndex={1000}
											zIndexInverse={3000}
											placeholder="Select"
											flatListProps={{
												onEndReached: () => {
													if (riverPage < riverLastPage) {
														setRiverPage(riverPage + 1);
													}
												},
												onEndReachedThreshold: 0.9,
												keyExtractor: (item, index) => index.toString(),
											}}
											disabled={districtValue === null ? true : false}
											onSelectItem={(val) => {
												if (val) {
													let lb = val.label?.toString();
													if (lb) {
														setRiverName(lb);
													}
													getBasinList(true, val.value);
												}
											}}
											placeholderStyle={styles.placeholder}
											dropDownContainerStyle={styles.dropDown}
										/>
									</View>
									<View
										style={StyleSheet.flatten([
											styles.dropDownWrapper,
											{
												zIndex: 9,
												height: openBasin ? 180 : 80,
											},
										])}
									>
										<Text style={styles.selectTitle}>Basin</Text>
										<DropDownPicker
											listMode="FLATLIST"
											dropDownDirection="BOTTOM"
											style={sharedStyles.input}
											open={openBasin}
											value={basinValue}
											labelProps={{ numberOfLines: 1 }}
											setValue={setBasinValue}
											items={basinList}
											onOpen={() => {
												setOpenDistrict(false);
												setOpenBlock(false);
												setOpenVillage(false);
												setOpenRiver(false);
												setOpenSubBasin(false);
												setOpenRiverType(false);
											}}
											setOpen={setOpenBasin}
											setItems={setBasinList}
											zIndex={1000}
											zIndexInverse={3000}
											placeholder="Select"
											flatListProps={{
												onEndReached: () => {
													if (basinPage < basinLastPage) {
														setBasinPage(basinPage + 1);
													}
												},
												onEndReachedThreshold: 0.9,
												keyExtractor: (item, index) => index.toString(),
											}}
											disabled={riverValue === null ? true : false}
											placeholderStyle={styles.placeholder}
											dropDownContainerStyle={styles.dropDown}
										/>
									</View>
									<View
										style={StyleSheet.flatten([
											styles.dropDownWrapper,
											{
												zIndex: 10,
												height: openRiverType ? 180 : 80,
											},
										])}
									>
										<Text style={styles.selectTitle}>
											Type
											<Text style={styles.textRed}>*</Text>
										</Text>
										<DropDownPicker
											listMode="FLATLIST"
											dropDownDirection="BOTTOM"
											style={sharedStyles.input}
											open={openRiverType}
											items={riverTypeList}
											setOpen={setOpenRiverType}
											onOpen={() => {
												setOpenDistrict(false);
												setOpenBlock(false);
												setOpenVillage(false);
												setOpenRiver(false);
												setOpenBasin(false);
												setOpenSubBasin(false);
											}}
											value={riverTypeValue}
											labelProps={{ numberOfLines: 1 }}
											setValue={setRiverTypeValue}
											setItems={setRiverTypeList}
											zIndex={1000}
											zIndexInverse={3000}
											placeholder="Select"
											flatListProps={{
												onEndReached: () => {
													if (riverTypePage < riverTypeLastPage) {
														setRiverTypePage(riverTypePage + 1);
													}
												},
												onEndReachedThreshold: 0.9,
												keyExtractor: (item, index) => index.toString(),
											}}
											disabled={riverValue === null ? true : false}
											placeholderStyle={styles.placeholder}
											dropDownContainerStyle={styles.dropDown}
										/>
									</View>
									{/* <View
                                        style={[
                                            styles.dropDownWrapper,
                                            {
                                                zIndex: 9,
                                                height: openSubBasin ? 180 : 80,
                                                marginTop: openBasin ? -100 : 0,
                                            },
                                        ]}
                                    >
                                        <Text style={styles.selectTitle}>
                                            Sub-Basin
                                        </Text>
                                        <DropDownPicker
                                            listMode="FLATLIST"
                                            dropDownDirection="BOTTOM"
                                            style={sharedStyles.input}
                                            open={openSubBasin}
                                            value={subBasinValue}
                                            setValue={setSubBasinValue}
                                            items={subBasinList}
                                            setOpen={setOpenSubBasin}
                                            onOpen={() => {
                                                setOpenBlock(false);
                                                setOpenDistrict(false);
                                                setOpenRiver(false);
                                                setOpenBasin(false);
                                                setOpenDistrict(false);
                                                setOpenVillage(false);
                                            }}
                                            setItems={setSubBasinList}
                                            zIndex={1000}
                                            zIndexInverse={3000}
                                            placeholder="Select"
                                            flatListProps={{
                                                onEndReached: () => {
                                                    if (
                                                        subBasinPage <
                                                        subBasinLastPage
                                                    ) {
                                                        setSubBasinPage(
                                                            subBasinPage + 1
                                                        );
                                                    }
                                                },
                                                onEndReachedThreshold: 0.9,
                                                keyExtractor: (item, index) =>
                                                    index.toString(),
                                            }}
                                            // onChangeValue={() => {
                                            //     if (
                                            //         generalDetails.designation !== CE &&
                                            //         generalDetails.designation !== SE
                                            //     ) {
                                            //         getDivisionList(true);
                                            //     }
                                            // }}
                                            placeholderStyle={{
                                                color: "#A7A7A7",
                                                fontSize: 12,
                                            }}
                                            dropDownContainerStyle={{
                                                borderColor: "#A7A7A7",
                                                zIndex: 54324,
                                                width: "100%",
                                                maxHeight: 120,
                                            }}
                                        />
                                    </View> */}
									{/* <View
                                        style={[
                                            styles.dropDownWrapper,
                                            { zIndex: 9 },
                                        ]}
                                    >
                                        <Text style={styles.selectTitle}>
                                            Barrage
                                        </Text>
                                        <DropDownPicker
                                            listMode="FLATLIST"
                                            dropDownDirection="BOTTOM"
                                            style={sharedStyles.input}
                                            open={openBarrage}
                                            value={barrageValue}
                                            setValue={setBarrageValue}
                                            items={barrageList}
                                            setOpen={setOpenBarrage}
                                            setItems={setBarrageList}
                                            zIndex={1000}
                                            zIndexInverse={3000}
                                            placeholder="Select"
                                            flatListProps={{
                                                onEndReached: () => {
                                                    if (
                                                        barragePage <
                                                        barrageLastPage
                                                    ) {
                                                        setBarragePage(
                                                            barragePage + 1
                                                        );
                                                    }
                                                },
                                                onEndReachedThreshold: 0.9,
                                                keyExtractor: (item, index) =>
                                                    index.toString(),
                                            }}
                                            // onChangeValue={() => {
                                            //     if (
                                            //         generalDetails.designation !== CE &&
                                            //         generalDetails.designation !== SE
                                            //     ) {
                                            //         getDivisionList(true);
                                            //     }
                                            // }}
                                            placeholderStyle={{
                                                color: "#A7A7A7",
                                                fontSize: 12,
                                            }}
                                            dropDownContainerStyle={{
                                                borderColor: "#A7A7A7",
                                                zIndex: 54324,
                                                width: "100%",
                                            }}
                                        />
                                    </View>
                                    <View
                                        style={[
                                            styles.dropDownWrapper,
                                            { zIndex: 8 },
                                        ]}
                                    >
                                        <Text style={styles.selectTitle}>
                                            Site/Station
                                        </Text>
                                        <DropDownPicker
                                            listMode="FLATLIST"
                                            dropDownDirection="BOTTOM"
                                            style={sharedStyles.input}
                                            open={openSite}
                                            value={siteValue}
                                            setValue={setSiteValue}
                                            items={siteList}
                                            setOpen={setOpenSite}
                                            setItems={setSiteList}
                                            zIndex={1000}
                                            zIndexInverse={3000}
                                            placeholder="Select"
                                            flatListProps={{
                                                onEndReached: () => {
                                                    if (
                                                        divisionPage <
                                                        divisionLastPage
                                                    ) {
                                                        setDivisionPage(
                                                            divisionPage + 1
                                                        );
                                                    }
                                                },
                                                onEndReachedThreshold: 0.9,
                                                keyExtractor: (item, index) =>
                                                    index.toString(),
                                            }}
                                            // onChangeValue={() => {
                                            //     if (
                                            //         generalDetails.designation !==
                                            //             CE &&
                                            //         generalDetails.designation !==
                                            //             SE &&
                                            //         generalDetails.designation !==
                                            //             EE
                                            //     ) {
                                            //         getSubDivisionList(true);
                                            //     }
                                            // }}
                                            placeholderStyle={{
                                                color: "#A7A7A7",
                                                fontSize: 12,
                                            }}
                                            dropDownContainerStyle={{
                                                borderColor: "#A7A7A7",
                                                zIndex: 54324,
                                                width: "100%",
                                            }}
                                        />
                                    </View> */}
								</View>
							</View>
						</>
					)}

					<View style={sharedStyles.btnWrapper}>
						{details && details.designation && details.designation.label !== COMMON ? (
							!showNext ? (
								<Pressable style={sharedStyles.skipButton} onPress={handleClose}>
									<Text
										style={[sharedStyles.skipButtonText, styles.skipButtonText]}
									>
										Cancel
									</Text>
								</Pressable>
							) : (
								<Pressable
									style={sharedStyles.skipButton}
									onPress={() => setShowNext(false)}
								>
									<Text
										style={[sharedStyles.skipButtonText, styles.skipButtonText]}
									>
										Back
									</Text>
								</Pressable>
							)
						) : (
							showNext && (
								<Pressable style={sharedStyles.skipButton} onPress={handleClose}>
									<Text
										style={[sharedStyles.skipButtonText, styles.skipButtonText]}
									>
										Cancel
									</Text>
								</Pressable>
							)
						)}
						{showNext ? (
							<Pressable style={styles.applyButton} onPress={handleApply}>
								<Text style={styles.applyButtonText}>Apply</Text>
							</Pressable>
						) : (
							<Pressable style={styles.applyButton} onPress={handleNext}>
								<Text style={styles.applyButtonText}>Next</Text>
							</Pressable>
						)}
					</View>
				</View>
			</View>
		</Modal>
	);
}

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
		flex: 1,
		height: deviceHeight,
		justifyContent: 'center',
		width: deviceWidth,
	},
	jurisHeading: {
		fontSize: 16,
		lineHeight: 20,
		color: PRIMARY_COLOR,
	},
	jurisWrapper: {
		paddingHorizontal: 10,
		paddingVertical: 20,
		backgroundColor: '#F5F7FB',
		marginHorizontal: 15,
		borderRadius: 4,
		height: 550,
		overflow: 'hidden',
	},
	locationHeading: {
		fontSize: 15,
		lineHeight: 18,
		fontWeight: '600',
		marginTop: 15,
	},
	dropDownWrapper: {
		marginTop: 6,
		backgroundColor: '#F5F7FB',
		paddingHorizontal: 8,
		width: '50%',
		height: 80,
	},
	selectWrapper: {
		backgroundColor: '#F5F7FB',
		flexDirection: 'row',
		flexWrap: 'wrap',
		zIndex: 10,
	},
	selectTitle: {
		fontSize: 11,
		lineHeight: 13,
		fontWeight: '300',
		marginTop: 16,
	},
	riverContainer: {
		backgroundColor: '#F5F7FB',
		zIndex: 5,
	},
	skipButtonText: {
		fontSize: 15,
	},
	applyButton: {
		backgroundColor: PRIMARY_COLOR,
		padding: 16,
		borderRadius: 4,
		width: '48%',
		color: 'white',
	},
	applyButtonText: {
		color: 'white',
		fontSize: 15,
		fontFamily: FONT_BOLD,
		textAlign: 'center',
	},
	headWrap: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	textRed: {
		color: 'red',
	},
	placeholder: {
		color: '#A7A7A7',
		fontSize: 12,
	},
	dropDown: {
		borderColor: '#A7A7A7',
		zIndex: 54324,
		width: '100%',
		maxHeight: 120,
	},
	listItemContainer: {
		height: 'auto',
		marginVertical: 2,
	},
});
