import { useState } from 'react';
import {
	ActivityIndicator,
	Alert,
	Keyboard,
	Pressable,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

//components
import CVerifyContact from '../../../../assets/images/svg/CVerifyContact';
import PCVerifyContact from '../../../../assets/images/svg/PCVerifyContact';
import PverifyContact from '../../../../assets/images/svg/PverifyContact';
import Colors, { PRIMARY_COLOR } from '../../../common/constants/Colors';
import {
	COMMON,
	CONTRACT,
	EMAIL,
	LOGIN,
	OFFICIAL,
	PERMANENT,
	PHONE,
	REGISTER,
} from '../../../common/constants/Constants';
import { FONT_BOLD } from '../../../common/constants/Fonts';
import { sharedStyles } from '../../../common/constants/SharedStyles';
import useAuth from '../../../common/hooks/useAuth';
import axios from '../../../common/utils/axios';
import Header from '../../../components/OnboardingHeader';
import { View } from '../../../components/Themed';
import { authType } from '../../../redux/slices/auth';
import { useDispatch, useSelector } from '../../../redux/store';

export default function VerifyContactScreen({ navigation }: { navigation: any }) {
	const [loading, setLoading] = useState(false);
	const authDetails = useSelector((state) => state.auth.value);
	const userDetails = useSelector((state) => state.user.value);
	const [auth, setAuth] = useState<string>(PHONE);
	const [email, onChangeEmail] = useState<string>('');
	const [password, onChangePassword] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const [secureEntry, setSecureEntry] = useState(true);
	const { emailLogin } = useAuth();

	const dispatch = useDispatch();

	const handleBack = () => {
		navigation.pop();
	};

	const requestOtp = async () => {
		if (auth === PHONE) {
			if (phone.length === 10) {
				let testphone = /^\d+$/.test(phone);
				if (testphone === true) {
					let firstNumber = phone.charAt(0);
					if (
						firstNumber === '6' ||
						firstNumber === '7' ||
						firstNumber === '8' ||
						firstNumber === '9'
					) {
						let formattedNum = '+91' + phone;
						dispatch(
							authType({
								phoneValue: formattedNum,
								userType: authDetails.userType,
								officialType: authDetails.officialType,
								type: authDetails.type,
							})
						);
						try {
							setLoading(true);
							const response = await axios.post('/api/auth/send-otp', {
								account_type: authDetails.userType,
								account_sub_type: authDetails.officialType,
								action: authDetails.type,
								phone_number: formattedNum,
								email: userDetails.email,
							});
							setLoading(false);
							if (response.data.status) {
								navigation.navigate('VerifyOTP');
							} else {
								Alert.alert('Alert!', response.data.message, [
									{
										text: 'Cancel',
										style: 'cancel',
									},
									{
										text: 'OK',
									},
								]);
								navigation.navigate('VerifyOTP');
							}
						} catch (error: any) {
							setLoading(false);
							Alert.alert(
								'Alert!',
								error.message ? error.message : 'Something went wrong!',
								[
									{
										text: 'Cancel',
										style: 'cancel',
									},
									{
										text: 'OK',
									},
								]
							);
						}
					} else {
						Alert.alert('Alert!', 'Please enter a valid phone number!', [
							{
								text: 'Cancel',
								style: 'cancel',
							},
							{
								text: 'OK',
							},
						]);
						setLoading(false);
					}
				} else {
					Alert.alert('Alert!', 'Please enter a valid phone number!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
					setLoading(false);
				}
			} else {
				Alert.alert('Alert!', 'Phone number should be of 10 char', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
				setLoading(false);
			}
		} else {
			if (email === '' || password === '') {
				Alert.alert('Alert!', 'Please enter required fields!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			} else {
				let testEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
				if (testEmail === false) {
					Alert.alert('Alert!', 'Please enter a valid email!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				} else {
					try {
						setLoading(true);
						await emailLogin(email, password);
						setLoading(false);
						navigation.navigate('Loading');
					} catch (error: any) {
						setLoading(false);
						Alert.alert(
							'Alert!',
							error.message ? error.message : 'Something went wrong!',
							[
								{
									text: 'Cancel',
									style: 'cancel',
								},
								{
									text: 'OK',
								},
							]
						);
					}
				}
			}
		}
	};

	const onPhoneChange = (value: any) => {
		var reg = /^\d+$/;
		if (reg.test(value) === true) {
			setPhone(value);
			if (value.length === 10) {
				Keyboard.dismiss();
			}
		} else {
			setPhone(value.slice(0, -1));
		}
	};

	return (
		<View style={styles.mainWrapper}>
			<Header />
			<ScrollView contentContainerStyle={styles.contentContainer} bounces={false}>
				<View style={[styles.imageContainer]}>
					{authDetails.userType === OFFICIAL &&
						authDetails.officialType === PERMANENT &&
						authDetails.type === REGISTER && <PverifyContact />}
					{authDetails.userType === OFFICIAL &&
						authDetails.officialType === CONTRACT &&
						authDetails.type === REGISTER && <PCVerifyContact />}
					{authDetails.userType === COMMON && authDetails.type === REGISTER && (
						<CVerifyContact />
					)}
				</View>
				<View style={styles.container}>
					<Text style={styles.headingText}>
						{authDetails.userType === OFFICIAL &&
							auth === PHONE &&
							'Verify Contact Number'}
						{authDetails.userType === OFFICIAL && auth === EMAIL && 'Verify Email'}
						{authDetails.userType === COMMON &&
							authDetails.type === REGISTER &&
							'Verification'}
						{authDetails.userType === COMMON &&
							authDetails.type === LOGIN &&
							'Welcome back,'}
					</Text>
					{auth === PHONE ? (
						<>
							<Text style={styles.title}>
								Phone number
								<Text style={styles.textRed}>*</Text>
							</Text>
							<View style={styles.phoneContainer}>
								<View style={[sharedStyles.generalInput, styles.codeField]}>
									<Text style={styles.codeFieldText}>+91</Text>
								</View>
								<TextInput
									autoFocus={true}
									style={[sharedStyles.generalInput, styles.phoneField]}
									value={phone}
									onChangeText={(value) => onPhoneChange(value)}
									maxLength={10}
									keyboardType="number-pad"
									selectionColor={Colors.light.tabIconDefault}
									contextMenuHidden={true}
								/>
							</View>
						</>
					) : (
						<>
							<Text style={styles.title}>
								Email
								<Text style={styles.textRed}>*</Text>
							</Text>
							<TextInput
								style={[sharedStyles.generalInput, styles.input]}
								onChangeText={(value) => {
									onChangeEmail(value);
								}}
								value={email}
								maxLength={40}
								keyboardType="email-address"
								autoCapitalize="none"
								selectionColor={Colors.light.tabIconDefault}
							/>
							<Text style={styles.title}>
								Password
								<Text style={styles.textRed}>*</Text>
							</Text>
							<View
								style={[
									sharedStyles.generalInput,
									styles.input,
									sharedStyles.passwordField,
								]}
							>
								<TextInput
									style={styles.textInput}
									onChangeText={(value) => {
										onChangePassword(value);
									}}
									value={password}
									maxLength={8}
									secureTextEntry={secureEntry}
									selectionColor={Colors.light.tabIconDefault}
								/>
								<Pressable onPress={() => setSecureEntry(!secureEntry)}>
									<Ionicons name={secureEntry ? 'eye-off' : 'eye'} size={24} />
								</Pressable>
							</View>
						</>
					)}
					{authDetails.userType === OFFICIAL &&
						authDetails.officialType === PERMANENT &&
						authDetails.type === LOGIN && (
							<View style={styles.wrapper}>
								<Text>or</Text>
								<Pressable
									onPress={() => {
										if (auth === PHONE) {
											setAuth(EMAIL);
										} else {
											setAuth(PHONE);
										}
									}}
								>
									<Text style={styles.loginText}>
										{auth === EMAIL
											? 'Login with Phone number'
											: 'Login with Email'}
									</Text>
								</Pressable>
							</View>
						)}
				</View>
				<View style={styles.btnWrapper}>
					<Pressable style={sharedStyles.skipButton} onPress={handleBack}>
						<Text style={sharedStyles.skipButtonText}>Back</Text>
					</Pressable>
					{!loading ? (
						<Pressable style={styles.done} onPress={requestOtp}>
							<Text style={styles.doneText}>
								{auth === EMAIL
									? 'Login'
									: authDetails.officialType === CONTRACT &&
									  authDetails.type === REGISTER
									? 'Proceed'
									: authDetails.userType === COMMON
									? 'Next'
									: 'Verify'}
							</Text>
						</Pressable>
					) : (
						<View style={styles.done}>
							<ActivityIndicator animating={loading} color="white" />
						</View>
					)}
				</View>
			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	mainWrapper: {
		flex: 1,
		backgroundColor: 'white',
	},
	contentContainer: {
		flexGrow: 1,
		padding: 16,
	},
	container: {
		flex: 2,
		paddingBottom: 16,
		width: '100%',
	},
	title: {
		fontWeight: '600',
		fontSize: 15,
		lineHeight: 18,
	},
	imageContainer: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 20,
		height: 90,
	},
	headingText: {
		fontSize: 18,
		fontWeight: '700',
		lineHeight: 22,
		marginBottom: 20,
		color: PRIMARY_COLOR,
	},
	btnWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 20,
		width: '100%',
	},
	done: {
		backgroundColor: PRIMARY_COLOR,
		padding: 16,
		borderRadius: 4,
		width: '47%',
		alignItems: 'center',
	},
	doneText: {
		color: '#FFFFFF',
		fontSize: 18,
		fontFamily: FONT_BOLD,
	},
	wrapper: {
		marginTop: '20%',
		alignItems: 'center',
		width: '100%',
	},
	loginText: {
		fontSize: 15,
		color: PRIMARY_COLOR,
		marginTop: 20,
		textDecorationLine: 'underline',
	},
	input: {
		marginVertical: 16,
	},
	codeField: {
		alignItems: 'flex-end',
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		justifyContent: 'center',
		marginVertical: 16,
		width: '15%',
	},
	codeFieldText: {
		color: 'black',
		fontSize: 14,
	},
	phoneField: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		marginVertical: 16,
		paddingLeft: 0,
		width: '80%',
	},
	phoneContainer: {
		flexDirection: 'row',
	},
	passwordField: {
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	textRed: {
		color: 'red',
	},
	textInput: {
		width: '90%',
	},
});
