import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

// slices
import authReducer from './slices/auth';
import currentLocationReducer from './slices/currentLocation';
import designationReducer from './slices/details';
import locationReducer from './slices/location';
import locationLabelReducer from './slices/locationLabel';
import nepalReducer from './slices/nepal';
import userReducer from './slices/user';

// ----------------------------------------------------------------------

const rootPersistConfig = {
	key: 'root',
	storage: AsyncStorage,
	keyPrefix: 'redux-',
	whitelist: [],
};

const authConfig = {
	key: 'auth',
	storage: AsyncStorage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const designationConfig = {
	key: 'details',
	storage: AsyncStorage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const locationConfig = {
	key: 'location',
	storage: AsyncStorage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const currentLocationConfig = {
	key: 'currentLocation',
	storage: AsyncStorage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const locationLabelConfig = {
	key: 'locationLabel',
	storage: AsyncStorage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const nepalConfig = {
	key: 'nepal',
	storage: AsyncStorage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const userConfig = {
	key: 'user',
	storage: AsyncStorage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const rootReducer = combineReducers({
	auth: persistReducer(authConfig, authReducer),
	details: persistReducer(designationConfig, designationReducer),
	location: persistReducer(locationConfig, locationReducer),
	currentLocation: persistReducer(currentLocationConfig, currentLocationReducer),
	locationLabel: persistReducer(locationLabelConfig, locationLabelReducer),
	nepal: persistReducer(nepalConfig, nepalReducer),
	user: persistReducer(userConfig, userReducer),
});

export { rootPersistConfig, rootReducer };
