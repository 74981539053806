import Svg, { Path, SvgProps } from 'react-native-svg';

function Cloudy(props: SvgProps) {
	return (
		<Svg width={15} height={15} viewBox="0 0 30 23" fill="none" {...props}>
			<Path
				d="M25.992 10.495c0 .755-.183 1.481-.53 2.128h-.056c-.886-1.795-2.766-2.995-4.795-2.995-.465 0-.924.061-1.374.183a6.093 6.093 0 00-5.306-3.122c-3.318 0-6.023 2.677-6.065 5.977a5.513 5.513 0 00-3.84 2.358A4.56 4.56 0 010 10.495 4.567 4.567 0 014.56 5.93c.1 0 .202.004.306.014A5.122 5.122 0 019.924 0a5.118 5.118 0 014.823 3.351 4.368 4.368 0 011.86-.412 4.42 4.42 0 014.187 3.037 4.21 4.21 0 01.632-.047 4.572 4.572 0 014.566 4.566z"
				fill="#346499"
			/>
			<Path
				d="M30 18.126a4.566 4.566 0 01-4.566 4.556H8.564a4.562 4.562 0 01-4.56-4.556 4.567 4.567 0 014.56-4.566c.099 0 .202.005.31.01a4.856 4.856 0 01-.07-.825c0-2.822 2.301-5.119 5.128-5.119a5.121 5.121 0 014.818 3.356 4.389 4.389 0 011.861-.417 4.425 4.425 0 014.186 3.042c.22-.033.427-.047.638-.047A4.571 4.571 0 0130 18.126z"
				fill="#346499"
			/>
		</Svg>
	);
}

export default Cloudy;
