import { StyleSheet } from 'react-native';
import WebView from 'react-native-webview';

export default function Map({ latitude, longitude }: any) {
	function onMessage(data: any) {
		const mapPoint = data && data.nativeEvent && data.nativeEvent.data;
		const x = mapPoint.split(',')[0];
		const y = mapPoint.split(',')[1];

		return { x, y };
	}

	const htmlData = `
    <html>

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="initial-scale=1, maximum-scale=1, user-scalable=no">
    <title>Basemap tutorial</title>
    <style>
        html,
        body,
        #viewDiv {
            padding: 0;
            margin: 0;
            height: 100%;
            width: 100%;
        }
    </style>

    <link rel="stylesheet" href="https://js.arcgis.com/4.5/esri/themes/light/main.css">
    <script src="https://js.arcgis.com/4.5/"></script>

    <script>
        require([
            "esri/config",
            "esri/Map",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/Basemap"
            ], (esriConfig, Map, MapView, FeatureLayer, Basemap) => {

            esriConfig.apiKey= "AAPK96f82862821d40848bbf69fd76661ef9bQmlYGtdOhLVlSDtYCC2lzjoCl9KzbNaNxehZu-QOs8ymXCR1USMnKG1VIbG5TjS";

            const map = new Map({
                basemap: "topo" // Basemap layer
            });

            const view = new MapView({
                container: "viewDiv",
                map: map,
                center: [${longitude}, ${latitude}],
                zoom: 7,
                constraints: {
                    snapToZoom: false,
                    maxZoom : 14,
                    minZoom: 5,
                    rotationEnabled: false,
                  },
            });
            //Trailheads feature layer (points)
            const trailheadsLayer = new FeatureLayer({
                url: "https://gis.fmiscwrdbihar.gov.in/arcgis/rest/services/GisAtlas/GIS_Atlas_2023/MapServer/22"
            });
            map.add(trailheadsLayer, 0);

            //Trailheads feature layer (points)
            const trailheadsLayer2 = new FeatureLayer({
                url: "https://gis.fmiscwrdbihar.gov.in/arcgis/rest/services/GisAtlas/GIS_Atlas_2023/MapServer/18"
            });
            map.add(trailheadsLayer2, 0);
          
          
        // changing coordinates
        view.on("drag", function(event) {
         sendDataToReactNativeApp(event)
        });

        const sendDataToReactNativeApp = async (event) => {
          const data= event.x+','+event.y
          window.ReactNativeWebView.postMessage(data);
        };
        });
    </script>
</head>

<body>
    <div id="viewDiv"></div>
</body>

</html>
    `;

	return (
		<WebView
			style={styles.container}
			nestedScrollEnabled={true}
			javaScriptEnabled={true}
			source={{ html: htmlData }}
			onMessage={onMessage}
		/>
	);
}

const styles = StyleSheet.create({
	container: {
		height: 300,
		width: '100%',
		opacity: 0.99,
		overflow: 'hidden',
	},
});
