import { useEffect, useState } from 'react';
import {
	ActivityIndicator,
	Alert,
	Pressable,
	SafeAreaView,
	StyleSheet,
	Text,
	View,
} from 'react-native';
import {
	CodeField,
	Cursor,
	useBlurOnFulfill,
	useClearByFocusCell,
} from 'react-native-confirmation-code-field';
//components
import { AntDesign } from '@expo/vector-icons';

import CVerifyContact from '../../../../assets/images/svg/CVerifyContact';
import PCVerifyContact from '../../../../assets/images/svg/PCVerifyContact';
import PverifyContact from '../../../../assets/images/svg/PverifyContact';
import { PRIMARY_COLOR } from '../../../common/constants/Colors';
import {
	COMMON,
	CONTRACT,
	LOGIN,
	OFFICIAL,
	PERMANENT,
	REGISTER,
} from '../../../common/constants/Constants';
import { FONT_BOLD, FONT_SEMI_BOLD } from '../../../common/constants/Fonts';
import { sharedStyles } from '../../../common/constants/SharedStyles';
import useAuth from '../../../common/hooks/useAuth';
import axios from '../../../common/utils/axios';
import Header from '../../../components/OnboardingHeader';
import { userType } from '../../../redux/slices/user';
import { useDispatch, useSelector } from '../../../redux/store';

const CELL_COUNT = 6;
export default function VerifyOTPScreen({ navigation }: { navigation: any }) {
	const [disableBtn, setDisableBtn] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState('');
	const { verifyphoneotp } = useAuth();
	const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
	const [properties, getCellOnLayoutHandler] = useClearByFocusCell({
		value,
		setValue,
	});
	const AuthDetails = useSelector((state) => state.auth.value);
	const userDetails = useSelector((state) => state.user.value);
	const [timer, setTimer] = useState(0);
	const handleBack = () => {
		navigation.pop();
	};
	const [startTime, setStartTime] = useState(new Date());
	const dispatch = useDispatch();

	const handleVerify = async () => {
		if (value) {
			if (value.length === 6) {
				try {
					setLoading(true);
					if (AuthDetails.type === REGISTER) {
						if (AuthDetails.userType === OFFICIAL) {
							if (AuthDetails.officialType === CONTRACT) {
								await axios.post('/api/auth/verify-otp', {
									account_type: AuthDetails.userType,
									account_sub_type: AuthDetails.officialType,
									action: AuthDetails.type,
									phone_number: AuthDetails.phoneValue,
									otp: value,
								});
								dispatch(
									userType({
										phoneno: AuthDetails.phoneValue,
									})
								);

								navigation.navigate('CGDetails');
							} else if (AuthDetails.officialType === PERMANENT) {
								const response = await axios.post('/api/auth/verify-otp', {
									account_type: AuthDetails.userType,
									account_sub_type: AuthDetails.officialType,
									action: AuthDetails.type,
									email: userDetails.email,
									phone_number: AuthDetails.phoneValue,
									otp: value,
								});
								if (response.data) {
									if (response.data.data) {
										dispatch(
											userType({
												email: userDetails.email,
												phoneno: AuthDetails.phoneValue,
												id: response.data.data.id,
											})
										);
									}
								}
								navigation.navigate('PGeneralDetails');
							}
						} else if (AuthDetails.userType === COMMON) {
							const response = await axios.post('/api/auth/verify-otp', {
								account_type: AuthDetails.userType,
								account_sub_type: AuthDetails.officialType,
								action: AuthDetails.type,
								phone_number: AuthDetails.phoneValue,
								otp: value,
							});
							if (response.data) {
								if (response.data.data) {
									dispatch(
										userType({
											phoneno: AuthDetails.phoneValue,
										})
									);
								}
							}
							navigation.navigate('PGeneralDetails');
						}

						setLoading(false);
					} else if (AuthDetails.type === LOGIN) {
						await verifyphoneotp(
							AuthDetails.userType,
							AuthDetails.officialType,
							AuthDetails.type,
							AuthDetails.phoneValue,
							value
						);
						setLoading(false);
						navigation.navigate('Loading');
					}
				} catch (error: any) {
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
					setValue('');
					setLoading(false);
				}
			} else {
				Alert.alert('Alert!', 'Please enter 6 digit OTP!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			}
		} else {
			Alert.alert('Alert!', 'Please enter the OTP!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const handleResend = async () => {
		try {
			await axios.post('/api/auth/send-otp', {
				account_type: AuthDetails.userType,
				account_sub_type: AuthDetails.officialType,
				action: AuthDetails.type,
				phone_number: AuthDetails.phoneValue,
				email: userDetails.email,
			});
			setDisableBtn(true);
			setStartTime(new Date());
			setTimer(30);
		} catch (error: any) {
			setLoading(false);
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (disableBtn) {
			const interval = setInterval(() => {
				let d = new Date();
				let diff = Math.floor((d.getTime() - startTime.getTime()) / 1000);
				if (timer <= 0) {
					clearInterval(interval);
					setDisableBtn(false);
				} else {
					setTimer(30 - diff);
				}
			}, 1000);

			return () => {
				clearInterval(interval);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timer]);

	return (
		<>
			<Header />
			<View style={styles.imageContainer}>
				{AuthDetails.userType === OFFICIAL &&
					AuthDetails.officialType === PERMANENT &&
					AuthDetails.type === REGISTER && <PverifyContact />}
				{AuthDetails.userType === OFFICIAL &&
					AuthDetails.officialType === CONTRACT &&
					AuthDetails.type === REGISTER && <PCVerifyContact />}
				{AuthDetails.userType === COMMON && AuthDetails.type === REGISTER && (
					<CVerifyContact />
				)}
			</View>
			<View style={styles.container}>
				<Text style={styles.headingText}>Verify Contact Number</Text>
				<Text style={styles.title}>
					We have sent a one time password (OTP) to your number{' '}
					<Text style={styles.textBold}>{AuthDetails.phoneValue}</Text>{' '}
				</Text>
				<Text style={styles.heading}>
					Enter OTP
					<Text style={styles.textRed}>*</Text>
				</Text>
				<SafeAreaView>
					<CodeField
						ref={ref}
						{...properties}
						value={value}
						onChangeText={setValue}
						cellCount={CELL_COUNT}
						rootStyle={styles.codeFieldRoot}
						keyboardType="number-pad"
						textContentType="oneTimeCode"
						renderCell={({ index, symbol, isFocused }) => (
							<Text
								key={index}
								style={[styles.cell, isFocused && styles.focusCell]}
								onLayout={getCellOnLayoutHandler(index)}
							>
								{symbol || (isFocused ? <Cursor /> : null)}
							</Text>
						)}
					/>
				</SafeAreaView>
				<View style={styles.info}>
					<View style={styles.left}>
						{timer > 0 && (
							<>
								<AntDesign name="clockcircleo" size={20} color="#55575A" />

								<Text style={styles.timer}>
									00:{timer < 10 && '0'}
									{timer}
								</Text>
							</>
						)}
					</View>
					<View style={styles.right}>
						<Text style={styles.recieveText}>Didn’t receive the code?</Text>
						<Pressable
							style={styles.resendButton}
							onPress={handleResend}
							disabled={disableBtn}
						>
							<Text style={[styles.resendText, disableBtn && styles.disabledText]}>
								Resend
							</Text>
						</Pressable>
					</View>
				</View>
				<View style={[sharedStyles.btnWrapper, styles.btnWrapper]}>
					<Pressable style={sharedStyles.skipButton} onPress={handleBack}>
						<Text style={sharedStyles.skipButtonText}>Back</Text>
					</Pressable>
					{!loading ? (
						<Pressable
							style={[sharedStyles.skipButton, sharedStyles.done]}
							onPress={handleVerify}
						>
							<Text style={[sharedStyles.skipButtonText, sharedStyles.doneText]}>
								{AuthDetails.type === LOGIN ? 'Login' : 'Verify'}
							</Text>
						</Pressable>
					) : (
						<View style={[sharedStyles.skipButton, sharedStyles.done]}>
							<ActivityIndicator animating={loading} color="white" />
						</View>
					)}
				</View>
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		padding: 16,
		backgroundColor: 'white',
	},
	imageContainer: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 20,
		backgroundColor: 'white',
		height: 90,
	},
	info: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		width: '100%',
		paddingVertical: 16,
	},
	textBold: {
		fontFamily: FONT_SEMI_BOLD,
	},
	left: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row',
	},
	right: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		flexDirection: 'row',
	},
	title: {
		fontSize: 15,
		paddingBottom: 16,
	},
	heading: {
		fontSize: 18,
		fontFamily: FONT_BOLD,
	},
	text: {
		color: '#FFFFFF',
		fontSize: 18,
		fontFamily: FONT_BOLD,
		textAlign: 'center',
	},
	timer: {
		color: '#55575A',
		paddingLeft: 5,
	},
	recieveText: {
		color: '#55575A',
		fontSize: 15,
		fontWeight: '300',
	},
	resendText: {
		color: '#346499',
		fontFamily: FONT_SEMI_BOLD,
		paddingLeft: 4,
	},
	disabledText: {
		color: '#55575A',
	},
	resendButton: {
		padding: 0,
	},
	codeFieldRoot: { marginTop: 15 },
	cell: {
		lineHeight: 45,
		fontSize: 15,
		borderWidth: 2,
		textAlign: 'center',
		width: 45,
		height: 45,
		borderColor: '#F5F7FB',
		backgroundColor: '#F5F7FB',
		color: '#346499',
		borderRadius: 4,
		marginRight: 10,
	},
	focusCell: {
		borderColor: '#000',
	},
	headingText: {
		fontSize: 18,
		fontWeight: '700',
		lineHeight: 22,
		marginBottom: 20,
		color: PRIMARY_COLOR,
	},
	btnWrapper: {
		flex: 1,
		width: '100%',
		marginBottom: 20,
	},
	textRed: {
		color: 'red',
	},
});
