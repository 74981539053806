import Svg, { Path, SvgProps } from 'react-native-svg';

function NormalRainfall(props: SvgProps) {
	return (
		<Svg width={14} height={13} viewBox="0 0 14 13" fill="none" {...props}>
			<Path
				d="M11.88 3.29a4.165 4.165 0 00-8.1-.182H2.603a2.603 2.603 0 100 5.206h8.33a2.603 2.603 0 00.947-5.023zM4.686 8.835a.52.52 0 00-.521.52v3.124a.52.52 0 101.041 0V9.356a.52.52 0 00-.52-.521zM6.768 8.835a.52.52 0 00-.52.52v3.124a.52.52 0 101.04 0V9.356a.52.52 0 00-.52-.521zM8.85 8.835a.52.52 0 00-.52.52v3.124a.52.52 0 101.04 0V9.356a.52.52 0 00-.52-.521z"
				fill="#DFE0EB"
			/>
		</Svg>
	);
}

export default NormalRainfall;
