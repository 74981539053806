import { useEffect, useRef, useState } from 'react';
import { Alert, Pressable, StyleSheet, Text } from 'react-native';

//component
import { PRIMARY_COLOR } from '../../common/constants/Colors';
//constant
import { BARRAGE, MAPDATA } from '../../common/constants/Constants';
import { FONT_BOLD, FONT_LIGHT } from '../../common/constants/Fonts';
import axios from '../../common/utils/axios';
import { useSelector } from '../../redux/store';
import { View } from '../Themed';

import Map from './Map';
import RiverTabs from './RiverTabs';

type Props = {
	open: () => void;
	waterLevelRoute?: any;
	currRiver: string;
};

export type DistrictType = {
	area_km: string | null;
	description: string | null;
	district_code: string | null;
	id: number | null;
	name: string | null;
	population: string | null;
	reported_incident: number | null;
	river_count: number | null;
	d_m: string | null;
};

export default function DistrictMap(props: Props) {
	const isMounted = useRef(false);
	const [districtDescription, setDistrictDescription] = useState<DistrictType | any>(null);
	const { districtId, refreshLocation, riverName, riverType } = useSelector(
		(state: any) => state.location.value
	);
	const { district } = useSelector((state: any) => state.locationLabel.value);
	const [mapCenter] = useState({
		longitude: 85.368752,
		latitude: 26.121473,
	});

	const getDistrictData = async () => {
		try {
			await axios
				.post('api/dashboard/district-desc', {
					district_id: districtId && districtId.toString(),
				})
				.then((response) => {
					if (!isMounted.current) {
						const { data } = response.data;
						if (Object.keys(data).length !== 0) {
							setDistrictDescription(data);
						} else {
							setDistrictDescription(null);
						}
					}
				});
		} catch (error: any) {
			setDistrictDescription(null);
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (districtId && districtId !== '') {
			getDistrictData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshLocation]);

	return (
		<>
			<View
				style={[
					styles.container,
					districtDescription &&
						districtDescription?.description !== '' &&
						districtDescription.description !== null &&
						styles.districtDescription,
				]}
			>
				<View style={StyleSheet.flatten([styles.mapView, { zIndex: 3000 }])}>
					{/* <MapView
                        style={styles.img}
                        provider={PROVIDER_GOOGLE}
                        showsUserLocation={true}
                        initialRegion={{
                            latitude: (27.52083333 + 24.33611111) / 2,
                            longitude: (88.29444444 + 83.33055556) / 2,
                            latitudeDelta: 27.52083333 - 24.33611111,
                            longitudeDelta: 88.29444444 - 83.33055556,
                        }}
                        minZoomLevel={6}
                        region={{
                            latitude: (27.52083333 + 24.33611111) / 2,
                            longitude: (88.29444444 + 83.33055556) / 2,
                            latitudeDelta: 27.52083333 - 24.33611111,
                            longitudeDelta: 88.29444444 - 83.33055556,
                        }}
                    /> */}
					<Map latitude={mapCenter.latitude} longitude={mapCenter.longitude} />
				</View>
				<View style={styles.textWrapper}>
					<View style={styles.wrapper}>
						<Text style={styles.selectTitle}>
							District - {district ? district : ''}
						</Text>
						<Pressable onPress={props.open}>
							<Text style={styles.editText}>Edit Location Details</Text>
						</Pressable>
					</View>
					<View style={styles.flexWrapper}>
						{MAPDATA.map((data) => {
							return (
								<View style={styles.WrapperSmall} key={data.id}>
									<Text style={styles.lightText}>{data.title}</Text>
									<Text style={styles.boldText}>
										{districtDescription && districtDescription[data?.type]
											? districtDescription[data?.type]
											: '-'}
									</Text>
								</View>
							);
						})}
					</View>
				</View>
				{riverType === BARRAGE && (
					<>
						<View
							style={StyleSheet.flatten([
								styles.wrapper,
								{
									marginTop: 8,
									width: '100%',
									backgroundColor: 'white',
								},
							])}
						>
							<Text style={styles.selectTitle}>
								River {riverName ? '- ' + riverName : '-'}
							</Text>
							<Pressable onPress={props.open}>
								<Text style={styles.editText}>Edit Location Details</Text>
							</Pressable>
						</View>
						<RiverTabs
							waterLevelRoute={props.waterLevelRoute}
							currRiver={props.currRiver}
						/>
					</>
				)}

				{districtDescription &&
					districtDescription?.description !== '' &&
					districtDescription.description !== null && (
						<View>
							<Text style={styles.heading}>Your area at a glance</Text>
							<Text style={styles.sub}>
								{districtDescription?.description !== '' &&
								districtDescription.description !== null
									? districtDescription?.description
									: 'No Data Found!'}
							</Text>
						</View>
					)}
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		borderRadius: 4,
		marginTop: 14,
		// marginBottom: 14,
	},
	img: {
		height: '100%',
		width: '100%',
	},
	districtDescription: {
		// height: "40%",
	},
	mapView: {
		width: '100%',
		marginBottom: 10,
	},
	textWrapper: {
		width: '100%',
		backgroundColor: '#FAFAFA',
		padding: 8,
	},
	flexWrapper: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		backgroundColor: '#FAFAFA',
		marginBottom: 8,
		flexWrap: 'wrap',
	},
	WrapperSmall: {
		alignItems: 'flex-start',
		backgroundColor: '#FAFAFA',
		marginHorizontal: 8,
		marginVertical: 6,
	},
	lightText: {
		fontFamily: FONT_LIGHT,
		fontSize: 11,
		marginBottom: 3,
	},
	boldText: {
		fontFamily: FONT_BOLD,
		fontSize: 11,
	},
	heading: {
		color: '#55575A',
		fontSize: 16,
		marginVertical: 12,
	},
	sub: {
		color: '#55575A',
		fontSize: 11,
		fontFamily: FONT_LIGHT,
	},
	wrapper: {
		backgroundColor: '#FAFAFA',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	selectTitle: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: '600',
	},
	editText: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: '600',
		color: PRIMARY_COLOR,
		textDecorationLine: 'underline',
	},
});
