import { useRef, useState } from 'react';
import { Animated, Easing, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';

import { PRIMARY_COLOR } from '../../common/constants/Colors';
import { FONT_MEDIUM } from '../../common/constants/Fonts';

const AccordionListItem = ({ title, children }: any) => {
	const [open, setOpen] = useState(false);
	const animatedController = useRef(new Animated.Value(0)).current;
	const [bodySectionHeight, setBodySectionHeight] = useState(0);

	const bodyHeight = animatedController.interpolate({
		inputRange: [0, 1],
		outputRange: [0, bodySectionHeight],
	});

	const arrowAngle = animatedController.interpolate({
		inputRange: [0, 1],
		outputRange: ['0rad', `${Math.PI}rad`],
	});

	const toggleListItem = () => {
		if (open) {
			Animated.timing(animatedController, {
				duration: 300,
				toValue: 0,
				easing: Easing.bezier(0.4, 0.0, 0.2, 1),
				useNativeDriver: false,
			}).start();
		} else {
			Animated.timing(animatedController, {
				duration: 300,
				toValue: 1,
				easing: Easing.bezier(0.4, 0.0, 0.2, 1),
				useNativeDriver: false,
			}).start();
		}
		setOpen(!open);
	};

	return (
		<>
			<TouchableWithoutFeedback onPress={() => toggleListItem()}>
				<View style={styles.titleContainer}>
					<Text style={styles.title}>{title}</Text>
					<Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
						<MaterialIcons name="keyboard-arrow-down" size={20} color="#346499" />
					</Animated.View>
				</View>
			</TouchableWithoutFeedback>
			<Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
				<View
					style={styles.bodyContainer}
					onLayout={(event) => setBodySectionHeight(event.nativeEvent.layout.height)}
				>
					{children}
				</View>
			</Animated.View>
		</>
	);
};
export default AccordionListItem;

const styles = StyleSheet.create({
	bodyBackground: {
		backgroundColor: '#EFEFEF',
		overflow: 'hidden',
		marginTop: 10,
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: '#FFFFFF',
		width: '100%',
		borderRadius: 4,
		color: PRIMARY_COLOR,
	},
	bodyContainer: {
		// padding: 16,
		// paddingLeft: 24,
		position: 'absolute',
		backgroundColor: '#FFFFFF',
		bottom: 0,
		width: '100%',
	},
	title: {
		fontSize: 16,
		fontFamily: FONT_MEDIUM,
		color: '#346499',
	},
});
