import { createSlice } from '@reduxjs/toolkit';

export const locationSlice = createSlice({
	name: 'location',
	initialState: {
		value: {
			zoneId: '',
			circleId: '',
			divisionId: '',
			sub_divisionId: '',
			districtId: '',
			blockId: '',
			river_id: '',
			riverType: '',
			riverName: '',
			basin_id: '',
			refreshLocation: false,
		},
	},
	reducers: {
		locationDetails: (state, action) => {
			state.value = action.payload;
		},
	},
});
export const { locationDetails } = locationSlice.actions;

export default locationSlice.reducer;
