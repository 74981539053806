import { useState } from 'react';
import { StyleSheet } from 'react-native';
import { FontAwesome, Ionicons } from '@expo/vector-icons';

//constants
import { FONT_BOLD, FONT_LIGHT, FONT_NORMAL } from '../../common/constants/Fonts';
import { Text, View } from '../Themed';

export type InicidentProps = {
	title: string;
	status: string;
	assigned: string;
	reported_by: string;
	assigned_by: string;
	official_name?: string;
	district: null | {
		id: number;
		name: string;
	};
	block: null | {
		id: number;
		block: string;
	};
	village: null | {
		id: number;
		village: string;
	};
	created_at: string;
	updated_at: string;
	history?:
		| null
		| {
				assigned_by: string;
				assignee: string;
				id: number;
		  }[];
};

const marginRight = 5;

export default function IncidentCard(props: InicidentProps) {
	const [collapsed, setCollapsed] = useState(true);
	let myIncident = false;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const toggleDesc = () => {
		if (collapsed === false) {
			setCollapsed(true);
		}
	};

	const t = (date: string) => {
		const d = new Date(date);
		let hours = d.getHours();
		let minutes = d.getMinutes(); // Check whether AM or PM
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		var newformat = hours >= 12 ? 'PM' : 'AM'; // Find current hour in AM-PM Format
		hours = hours % 12; // To display "0" as "12"
		hours = hours ? hours : 12;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const m = minutes < 10 ? '0' + minutes : minutes;

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		var alertDate = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) {
			month = '0' + month;
		}
		if (day.length < 2) {
			day = '0' + day;
		}

		return (
			<Text style={StyleSheet.flatten([styles.lightText, { fontSize: 11 }])}>
				{/* {hours}:{m} {newformat} |  */}
				{[day, month, year].join('-')}
			</Text>
		);
	};

	return (
		<>
			<View
				style={StyleSheet.flatten([
					styles.container,
					{
						backgroundColor: '#F5F7FB',
					},
				])}
			>
				<View style={styles.flexWrapper}>
					<Text style={styles.title}>{props.title}</Text>
					<Text
						style={StyleSheet.flatten([
							styles.reviewText,
							{
								color: props.status === 'Under Review' ? '#E0A800' : '#007B55',
							},
						])}
					>
						{props.status}
					</Text>
					{props && props.status === 'Active' ? (
						<Text style={styles.currentDate}>
							{props && props.created_at && t(props.created_at.split(' ')[0])} -
							current
						</Text>
					) : (
						<Text style={styles.currentDate}>
							{props && props.created_at && t(props.created_at.split(' ')[0])}{' '}
							{props &&
								props.created_at &&
								props.updated_at &&
								props.created_at.split(' ')[0] !== props.updated_at.split(' ')[0] &&
								'-' +
									(props &&
										props.updated_at &&
										t(props.updated_at.split(' ')[0]))}
						</Text>
					)}
				</View>
				{props.assigned !== '' && (
					<View style={styles.imgContainer}>
						{/* <Image
                        style={styles.image}
                        source={require("../../../assets/images/User1.png")}
                    /> */}

						<Text style={{ marginRight }}>
							{props.history &&
							props.history.length > 0 &&
							props.assigned === 'Assigned'
								? props.history[0].assignee
								: props.reported_by}
						</Text>

						{props.assigned === 'Assigned' && (
							<FontAwesome name="bell" size={15} color="#FFC107" />
						)}
					</View>
				)}
				<View style={styles.flexWrapper}>
					<View style={styles.flexWrapperSmall}>
						<Ionicons name="location-sharp" size={15} color="#346499" />
						{props && props.district && (
							<Text
								style={StyleSheet.flatten([
									styles.normalText,
									{
										marginLeft: 5,
										marginBottom: 1,
										fontSize: 11,
									},
								])}
							>
								{props.village && `${props.village.village}, `}{' '}
								{props.block && `${props.block.block}, `}
								{props.district && props.district.name}
							</Text>
						)}
					</View>
				</View>
				{myIncident && props.assigned_by && (
					<Text style={styles.assignedByFont}>{props.assigned_by} </Text>
				)}
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		padding: 10,
		borderRadius: 4,
		marginVertical: 8,
	},
	flexWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		backgroundColor: '#F5F7FB',
		marginBottom: 8,
	},
	flexWrapperSmall: {
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#F5F7FB',
	},
	title: {
		fontFamily: FONT_BOLD,
		fontSize: 13,
		marginTop: 2,
		marginBottom: 4,
		marginRight: 5,
	},
	normalText: {
		fontFamily: FONT_NORMAL,
		fontSize: 13,
	},
	lightText: {
		fontFamily: FONT_LIGHT,
		fontSize: 13,
	},
	reviewText: {
		fontSize: 11,
		color: '#007B55',
		lineHeight: 13,
		fontWeight: '300',
	},
	image: {
		height: 15,
		width: 15,
		marginRight: 5,
	},
	imgContainer: {
		backgroundColor: '#F5F7FB',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 5,
	},
	currentDate: {
		paddingLeft: 20,
		flex: 1,
		flexWrap: 'wrap',
		fontSize: 12,
	},
	assignedByFont: {
		fontSize: 10,
	},
});
