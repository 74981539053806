/* eslint-disable react/no-unstable-nested-components */
import { useCallback, useState } from 'react';
import {
	Alert,
	Pressable,
	ScrollView,
	StyleProp,
	StyleSheet,
	TextStyle,
	useWindowDimensions,
	ViewStyle,
} from 'react-native';
import {
	NavigationState,
	Route,
	SceneMap,
	SceneRendererProps,
	TabBar,
	TabBarIndicatorProps,
	TabBarItemProps,
	TabView,
} from 'react-native-tab-view';
import { Event, Scene } from 'react-native-tab-view/lib/typescript/types';
import { useFocusEffect } from '@react-navigation/native';

import Cloudy from '../../../assets/images/svg/Cloudy';
import DangerRainfall from '../../../assets/images/svg/DangerRainfall';
import HeavyRainfall from '../../../assets/images/svg/HeavyRainfall';
import NormalRainfall from '../../../assets/images/svg/NormalRainfall';
import VeryHeavyRainfall from '../../../assets/images/svg/VeryHeavyRainfall';
import Colors, { PRIMARY_COLOR } from '../../common/constants/Colors';
import {
	DANGERRAINFALL,
	HEAVYRAINFALL,
	LIGHTRAINFALL,
	MOSTLYCLOUDY,
	NORMALRAINFALL,
	VERYHEAVYRAINFALL,
	VERYLIGHTRAINFALL,
} from '../../common/constants/Constants';
import { FONT_BOLD, FONT_LIGHT } from '../../common/constants/Fonts';
import axios from '../../common/utils/axios';
import { locationDetails } from '../../redux/slices/location';
import { dispatch, useSelector } from '../../redux/store';
import { Text, View } from '../Themed';

type Props = {
	open: () => void;
};

type CurrentType = {
	block: string;
	details: {
		current_rainfall: string;
		departure: string;
		normal_rainfall: string;
		rainfall_date: string;
		rainfall_day: string;
		rainfall_status: string;
	};
	last_record_rainfall: { date: string; time: string };
};

type PredictionType = {
	rainfall_date: string;
	rainfall_day: string;
	rainfall: string;
	rainfall_status: string;
};

type HistoryType = {
	rainfall_date: string;
	rainfall_day: string;
	actual_rainfall: string;
	rainfall_status: string;
};
export default function RainfallData(props: Props) {
	const [index, setIndex] = useState(0);
	const [routes] = useState([
		{ key: 'first', title: 'Current' },
		{ key: 'second', title: 'Prediction' },
		{ key: 'third', title: 'History' },
	]);
	const [blockName, setBlockName] = useState<string>('');

	const [currentData, setCurrentData] = useState<CurrentType | null>(null);
	const [predictionData, setPredictionData] = useState<PredictionType[]>([]);
	const [historyData, setHistoryData] = useState<HistoryType[]>([]);

	const JurisDetails = useSelector((state) => state.location.value);

	useFocusEffect(
		useCallback(() => {
			const getTodayDetails = async () => {
				try {
					await axios
						.get('/api/dashboard/rainfall/current', {
							params: {
								district_id: JurisDetails.districtId,
								block_id: JurisDetails.blockId,
							},
						})
						.then((response) => {
							const { data } = response.data;

							if (data && data.block) {
								setCurrentData(data);

								setBlockName(data.block);
							}
							dispatch(
								locationDetails({
									zoneId: JurisDetails.zoneId,
									circleId: JurisDetails.circleId,
									divisionId: JurisDetails.divisionId,
									sub_divisionId: JurisDetails.sub_divisionId,
									districtId: JurisDetails.districtId,
									blockId: JurisDetails.blockId,
									river_id: JurisDetails.river_id,
									riverType: JurisDetails.riverType,
									riverName: JurisDetails.riverName,
									basin_id: JurisDetails.blockId,
									refreshLocation: false,
								})
							);
						});
				} catch (error: any) {
					dispatch(
						locationDetails({
							zoneId: JurisDetails.zoneId,
							circleId: JurisDetails.circleId,
							divisionId: JurisDetails.divisionId,
							sub_divisionId: JurisDetails.sub_divisionId,
							districtId: JurisDetails.districtId,
							blockId: JurisDetails.blockId,
							river_id: JurisDetails.river_id,
							riverType: JurisDetails.riverType,
							riverName: JurisDetails.riverName,
							basin_id: JurisDetails.blockId,
							refreshLocation: false,
						})
					);
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			};
			if (index === 0 && JurisDetails.districtId !== '' && JurisDetails.blockId !== '') {
				getTodayDetails();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [index, JurisDetails.refreshLocation])
	);

	useFocusEffect(
		useCallback(() => {
			const getFutureDetails = async () => {
				try {
					await axios
						.get('/api/dashboard/rainfall/prediction', {
							params: {
								district_id: JurisDetails.districtId,
								block_id: JurisDetails.blockId,
							},
						})
						.then((response) => {
							const { data } = response.data;

							if (data && data.block) {
								setPredictionData(data.details);

								setBlockName(data.block);
							}
							dispatch(
								locationDetails({
									zoneId: JurisDetails.zoneId,
									circleId: JurisDetails.circleId,
									divisionId: JurisDetails.divisionId,
									sub_divisionId: JurisDetails.sub_divisionId,
									districtId: JurisDetails.districtId,
									blockId: JurisDetails.blockId,
									river_id: JurisDetails.river_id,
									riverType: JurisDetails.riverType,
									riverName: JurisDetails.riverName,
									basin_id: JurisDetails.blockId,
									refreshLocation: false,
								})
							);
						});
				} catch (error: any) {
					dispatch(
						locationDetails({
							zoneId: JurisDetails.zoneId,
							circleId: JurisDetails.circleId,
							divisionId: JurisDetails.divisionId,
							sub_divisionId: JurisDetails.sub_divisionId,
							districtId: JurisDetails.districtId,
							blockId: JurisDetails.blockId,
							river_id: JurisDetails.river_id,
							riverType: JurisDetails.riverType,
							riverName: JurisDetails.riverName,
							basin_id: JurisDetails.blockId,
							refreshLocation: false,
						})
					);
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			};
			if (index === 1 && JurisDetails.districtId !== '' && JurisDetails.blockId !== '') {
				getFutureDetails();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [index, JurisDetails.refreshLocation])
	);

	useFocusEffect(
		useCallback(() => {
			const getHistoryDetails = async () => {
				try {
					await axios
						.get('/api/dashboard/rainfall/history', {
							params: {
								district_id: JurisDetails.districtId,
								block_id: JurisDetails.blockId,
							},
						})
						.then((response) => {
							const { data } = response.data;
							if (data && data.block) {
								setHistoryData(data.details);

								setBlockName(data.block);
							}
							dispatch(
								locationDetails({
									zoneId: JurisDetails.zoneId,
									circleId: JurisDetails.circleId,
									divisionId: JurisDetails.divisionId,
									sub_divisionId: JurisDetails.sub_divisionId,
									districtId: JurisDetails.districtId,
									blockId: JurisDetails.blockId,
									river_id: JurisDetails.river_id,
									riverType: JurisDetails.riverType,
									riverName: JurisDetails.riverName,
									basin_id: JurisDetails.blockId,
									refreshLocation: false,
								})
							);
						});
				} catch (error: any) {
					dispatch(
						locationDetails({
							zoneId: JurisDetails.zoneId,
							circleId: JurisDetails.circleId,
							divisionId: JurisDetails.divisionId,
							sub_divisionId: JurisDetails.sub_divisionId,
							districtId: JurisDetails.districtId,
							blockId: JurisDetails.blockId,
							river_id: JurisDetails.river_id,
							riverType: JurisDetails.riverType,
							riverName: JurisDetails.riverName,
							basin_id: JurisDetails.blockId,
							refreshLocation: false,
						})
					);
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			};
			if (index === 2 && JurisDetails.districtId !== '' && JurisDetails.blockId !== '') {
				getHistoryDetails();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [index, JurisDetails.refreshLocation])
	);

	const layout = useWindowDimensions();

	const renderTabBar = (
		tabBarProps: JSX.IntrinsicAttributes &
			SceneRendererProps & {
				navigationState: NavigationState<Route>;
				scrollEnabled?: boolean | undefined;
				bounces?: boolean | undefined;
				activeColor?: string | undefined;
				inactiveColor?: string | undefined;
				pressColor?: string | undefined;
				pressOpacity?: number | undefined;
				getLabelText?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getAccessible?: ((scene: Scene<Route>) => boolean | undefined) | undefined;
				getAccessibilityLabel?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getTestID?: ((scene: Scene<Route>) => string | undefined) | undefined;
				renderLabel?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderIcon?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderBadge?: ((scene: Scene<Route>) => React.ReactNode) | undefined;
				renderIndicator?:
					| ((props: TabBarIndicatorProps<Route>) => React.ReactNode)
					| undefined;
				renderTabBarItem?:
					| ((
							props: TabBarItemProps<Route> & { key: string }
					  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
					| undefined;
				onTabPress?: ((scene: Scene<Route> & Event) => void) | undefined;
				onTabLongPress?: ((scene: Scene<Route>) => void) | undefined;
				tabStyle?: StyleProp<ViewStyle>;
				indicatorStyle?: StyleProp<ViewStyle>;
				indicatorContainerStyle?: StyleProp<ViewStyle>;
				labelStyle?: StyleProp<TextStyle>;
				contentContainerStyle?: StyleProp<ViewStyle>;
				style?: StyleProp<ViewStyle>;
				gap?: number | undefined;
			}
	) => (
		<TabBar
			{...tabBarProps}
			scrollEnabled
			indicatorStyle={{ backgroundColor: Colors.light.background }}
			style={{ backgroundColor: Colors.light.background }}
			tabStyle={styles.tabBar}
		/>
	);

	const FirstRoute = () =>
		currentData !== null ? (
			<View style={styles.outerCard}>
				<View style={styles.innerCard}>
					<View style={styles.currentRainfallWrapperBG}>
						{currentData.details && currentData.details.current_rainfall !== '' && (
							<View style={styles.WrapperSmall}>
								<Text style={styles.lightText}>Current Rainfall</Text>
								<Text style={styles.boldText}>
									{currentData.details.current_rainfall}{' '}
								</Text>
							</View>
						)}
						{currentData.details && currentData.details.normal_rainfall !== '' && (
							<View style={styles.WrapperSmall}>
								<Text style={styles.lightText}>Normal Rainfall</Text>
								<Text style={styles.boldText}>
									{currentData.details.normal_rainfall}
									{/* mm */}
								</Text>
							</View>
						)}
						{currentData.details && currentData.details.departure !== '' && (
							<View style={styles.WrapperSmall}>
								<Text style={styles.lightText}>Departure</Text>
								<Text style={styles.boldText}>{currentData.details.departure}</Text>
							</View>
						)}
					</View>

					{currentData.details.rainfall_status === DANGERRAINFALL && (
						<View style={styles.redCard}>
							<DangerRainfall />
							<Text style={styles.redText}>
								{currentData.details.rainfall_status}
							</Text>
						</View>
					)}
					{currentData.details.rainfall_status === VERYHEAVYRAINFALL && (
						<View style={styles.redCard}>
							<VeryHeavyRainfall />
							<Text style={styles.redText}>
								{currentData.details.rainfall_status}
							</Text>
						</View>
					)}
					{currentData.details.rainfall_status === HEAVYRAINFALL && (
						<View style={styles.yellowCard}>
							<HeavyRainfall />
							<Text style={styles.yellowText}>
								{currentData.details.rainfall_status}
							</Text>
						</View>
					)}
					{currentData.details.rainfall_status === NORMALRAINFALL ||
						currentData.details.rainfall_status === LIGHTRAINFALL ||
						(currentData.details.rainfall_status === VERYLIGHTRAINFALL && (
							<View style={styles.whiteCard}>
								<NormalRainfall />
								<Text style={styles.whiteText}>
									{currentData.details.rainfall_status}
								</Text>
							</View>
						))}
					{currentData.details.rainfall_status === MOSTLYCLOUDY && (
						<View style={styles.blueCard}>
							<Cloudy />
							<Text style={styles.blueText}>
								{currentData.details.rainfall_status}
							</Text>
						</View>
					)}
				</View>
			</View>
		) : (
			<View style={styles.empty}>
				<Text style={styles.emptyText}>No rainfall data found</Text>
				{(JurisDetails.districtId === '' || JurisDetails.blockId === '') && (
					<Pressable onPress={props.open}>
						<Text style={styles.editText}>Edit Location Details</Text>
					</Pressable>
				)}
			</View>
		);

	const SecondRoute = () => (
		<View style={styles.outerCard}>
			<View style={styles.innerCard2} key={index}>
				<ScrollView>
					<View style={styles.innerCardContainer}>
						{predictionData && predictionData.length > 0 ? (
							<>
								{predictionData.map((data: PredictionType) => {
									return (
										<View style={styles.dataWrap}>
											<View style={styles.flexWrap}>
												<Text style={styles.dateText}>
													{data.rainfall_date}
												</Text>
												<Text style={styles.dateText}>
													{data.rainfall_day.slice(0, 3)}
												</Text>
											</View>
											{data.rainfall_status === DANGERRAINFALL && (
												<>
													<View style={styles.flex}>
														<DangerRainfall />
														<Text style={styles.redText}>
															{data.rainfall} {/* mm */}
														</Text>
													</View>
													<Text style={styles.dateText}>
														{data.rainfall_status}
													</Text>
												</>
											)}

											{data.rainfall_status === VERYHEAVYRAINFALL && (
												<>
													<View style={styles.flex}>
														<VeryHeavyRainfall />
														<Text style={styles.redText}>
															{data.rainfall} {/* mm */}
														</Text>
													</View>
													<Text style={styles.dateText}>
														{data.rainfall_status}
													</Text>
												</>
											)}
											{data.rainfall_status === HEAVYRAINFALL && (
												<>
													<View style={styles.flex}>
														<HeavyRainfall />
														<Text style={styles.yellowText}>
															{data.rainfall} {/* mm */}
														</Text>
													</View>
													<Text style={styles.dateText}>
														{data.rainfall_status}
													</Text>
												</>
											)}
											{data.rainfall_status === NORMALRAINFALL ||
												data.rainfall_status === LIGHTRAINFALL ||
												(data.rainfall_status === VERYLIGHTRAINFALL && (
													<>
														<View style={styles.flex}>
															<NormalRainfall />
															<Text style={styles.whiteText}>
																{data.rainfall} {/* mm */}
															</Text>
														</View>
														<Text style={styles.dateText}>
															{data.rainfall_status}
														</Text>
													</>
												))}
											{data.rainfall_status === MOSTLYCLOUDY && (
												<>
													<View style={styles.flex}>
														<Cloudy />
														<Text style={styles.blueText}>
															{data.rainfall} {/* mm */}
														</Text>
													</View>
													<Text style={styles.dateText}>
														{data.rainfall_status}
													</Text>
												</>
											)}
										</View>
									);
								})}
							</>
						) : (
							<View style={styles.empty}>
								<Text style={styles.emptyText}>No rainfall data found</Text>
								{(JurisDetails.districtId === '' ||
									JurisDetails.blockId === '') && (
									<Pressable onPress={props.open}>
										<Text style={styles.editText}>Edit Location Details</Text>
									</Pressable>
								)}
							</View>
						)}
					</View>
				</ScrollView>
			</View>
		</View>
	);

	const ThirdRoute = () => (
		<View style={styles.outerCard}>
			<View style={styles.innerCard2}>
				<ScrollView>
					<View style={styles.innerCardContainer}>
						{historyData && historyData.length > 0 ? (
							<>
								{historyData.map((data: HistoryType, historyIndex: number) => {
									return (
										<View style={styles.dataWrap} key={historyIndex}>
											<View style={styles.flexWrap}>
												<Text style={styles.dateText}>
													{data.rainfall_date}
												</Text>
												<Text style={styles.dateText}>
													{data.rainfall_day.slice(0, 3)}
												</Text>
											</View>
											{data.rainfall_status === DANGERRAINFALL && (
												<>
													<View style={styles.flex}>
														<DangerRainfall />
														<Text style={styles.redText}>
															{data.actual_rainfall} {/* mm */}
														</Text>
													</View>
													<Text style={styles.dateText}>
														{data.rainfall_status}
													</Text>
												</>
											)}

											{data.rainfall_status === VERYHEAVYRAINFALL && (
												<>
													<View style={styles.flex}>
														<VeryHeavyRainfall />
														<Text style={styles.redText}>
															{data.actual_rainfall} {/* mm */}
														</Text>
													</View>
													<Text style={styles.dateText}>
														{data.rainfall_status}
													</Text>
												</>
											)}
											{data.rainfall_status === HEAVYRAINFALL && (
												<>
													<View style={styles.flex}>
														<HeavyRainfall />
														<Text style={styles.yellowText}>
															{data.actual_rainfall} {/* mm */}
														</Text>
													</View>
													<Text style={styles.dateText}>
														{data.rainfall_status}
													</Text>
												</>
											)}
											{data.rainfall_status === NORMALRAINFALL ||
												data.rainfall_status === LIGHTRAINFALL ||
												(data.rainfall_status === VERYLIGHTRAINFALL && (
													<>
														<View style={styles.flex}>
															<NormalRainfall />
															<Text style={styles.whiteText}>
																{data.actual_rainfall} {/* mm */}
															</Text>
														</View>
														<Text style={styles.dateText}>
															{data.rainfall_status}
														</Text>
													</>
												))}
											{data.rainfall_status === MOSTLYCLOUDY && (
												<>
													<View style={styles.flex}>
														<Cloudy />
														<Text style={styles.blueText}>
															{data.actual_rainfall} {/* mm */}
														</Text>
													</View>
													<Text style={styles.dateText}>
														Mostly Cloudy
													</Text>
												</>
											)}
										</View>
									);
								})}
							</>
						) : (
							<View style={styles.empty}>
								<Text style={styles.emptyText}>No rainfall data found</Text>
								{(JurisDetails.districtId === '' ||
									JurisDetails.blockId === '') && (
									<Pressable onPress={props.open}>
										<Text style={styles.editText}>Edit Location Details</Text>
									</Pressable>
								)}
							</View>
						)}
					</View>
				</ScrollView>
			</View>
		</View>
	);
	const renderScene = SceneMap({
		first: FirstRoute,
		second: SecondRoute,
		third: ThirdRoute,
	});

	return (
		<View style={styles.container}>
			{currentData && currentData.last_record_rainfall && (
				<Text style={styles.lightGreyText}>
					{`last recorded at ${
						currentData &&
						currentData.last_record_rainfall &&
						currentData.last_record_rainfall.time &&
						currentData.last_record_rainfall.time
					} of ${
						currentData &&
						currentData.last_record_rainfall &&
						currentData.last_record_rainfall.date &&
						currentData.last_record_rainfall.date
					}`}
				</Text>
			)}
			{currentData && currentData.block && (
				<View style={styles.wrapper}>
					<Text style={styles.selectTitle}>{`Block - ${blockName}`}</Text>
					<Pressable onPress={props.open}>
						<Text style={styles.editText}>Edit Location Details</Text>
					</Pressable>
				</View>
			)}
			<View style={styles.content}>
				<TabView
					navigationState={{ index, routes }}
					renderScene={renderScene}
					onIndexChange={setIndex}
					initialLayout={{ width: layout.width }}
					style={styles.tab}
					renderTabBar={renderTabBar}
				/>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		borderRadius: 4,
		marginTop: 3,
	},
	wrapper: {
		backgroundColor: 'white',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginVertical: 8,
		width: '100%',
	},
	selectTitle: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: '600',
	},
	editText: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: '600',
		color: PRIMARY_COLOR,
		textDecorationLine: 'underline',
	},
	WrapperSmall: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		backgroundColor: '#F5F7FB',
		marginVertical: 3,
	},
	innerCard: {
		backgroundColor: '#F5F7FB',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		borderRadius: 4,
	},
	outerCard: {
		backgroundColor: '#F5F7FB',
		height: 130,
		marginTop: 10,
		padding: 8,
	},
	innerCard2: {
		backgroundColor: '#F5F7FB',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		borderRadius: 4,
	},
	lightText: {
		fontFamily: FONT_LIGHT,
		fontSize: 11,
	},
	lightGreyText: {
		fontFamily: FONT_LIGHT,
		fontSize: 11,
		color: '#55575A',
	},
	boldText: {
		fontFamily: FONT_BOLD,
		fontSize: 11,
		color: '#346499',
		marginLeft: 6,
	},
	redCard: {
		padding: 8,
		backgroundColor: '#F9E9E5',
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: 4,
	},
	redText: {
		fontFamily: FONT_BOLD,
		fontSize: 12,
		color: '#D73030',
		marginLeft: 8,
	},
	yellowCard: {
		padding: 8,
		backgroundColor: '#FFFCEA',
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: 4,
	},
	yellowText: {
		fontFamily: FONT_BOLD,
		fontSize: 12,
		color: '#E0A800',
		marginLeft: 8,
	},
	whiteCard: {
		padding: 8,
		backgroundColor: '#FAFAFA',
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: 4,
	},
	whiteText: {
		fontFamily: FONT_BOLD,
		fontSize: 12,
		color: '#55575A',
		marginLeft: 8,
	},
	blueCard: {
		padding: 8,
		backgroundColor: '#F5F7FB',
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: 4,
	},
	blueText: {
		fontFamily: FONT_BOLD,
		fontSize: 12,
		color: '#346499',
		marginLeft: 8,
	},
	tab: {
		width: '100%',
		height: 200,
		marginTop: 12,
	},
	tabBar: {
		backgroundColor: '#346499',
		borderRadius: 4,
		marginHorizontal: 5,
	},
	content: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: 200,
	},
	empty: {
		padding: 8,
		backgroundColor: '#F5F7FB',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		marginTop: 10,
		borderRadius: 4,
		height: 130,
	},
	emptyText: {
		fontFamily: FONT_BOLD,
		fontSize: 18,
		color: '#346499',
		marginLeft: 6,
	},
	dataWrap: {
		margin: 4,
		padding: 8,
		borderRadius: 10,
		width: 130,
	},
	flexWrap: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	dateText: {
		fontSize: 13,
		lineHeight: 16,
		fontWeight: '300',
	},
	flex: {
		flexDirection: 'row',
		alignItems: 'center',
		marginVertical: 10,
		justifyContent: 'center',
	},
	currentRainfallWrapperBG: {
		backgroundColor: '#F5F7FB',
	},
	innerCardContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		backgroundColor: '#F5F7FB',
	},
});
