import { useMemo, useState } from 'react';
import { Alert, Pressable, StyleSheet, Text } from 'react-native';

//constant
import { PRIMARY_COLOR } from '../../../common/constants/Colors';
import { COMMON, CONTRACT, OFFICIAL, PERMANENT } from '../../../common/constants/Constants';
import { FONT_BOLD } from '../../../common/constants/Fonts';
import { sharedStyles } from '../../../common/constants/SharedStyles';
import Header from '../../../components/OnboardingHeader';
//components
import { View } from '../../../components/Themed';
import { authType } from '../../../redux/slices/auth';
import { useDispatch } from '../../../redux/store';

export default function IdentifyScreen({ navigation }: { navigation: any }) {
	const [selectedUserType, setUserType] = useState<string | null>(null);
	const [officialType, setOfficialType] = useState<string | null>(null);
	const dispatch = useDispatch();

	const handleBack = () => {
		navigation.pop();
	};

	const handleNext = () => {
		if (selectedUserType === null) {
			Alert.alert('Alert!', 'Please select the type of user', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else if (selectedUserType === OFFICIAL && officialType === null) {
			Alert.alert('Alert!', 'Please select the type of official', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else {
			dispatch(
				authType({
					userType: selectedUserType,
					officialType: selectedUserType === OFFICIAL ? officialType : '',
				})
			);
			navigation.navigate('Choose');
		}
	};

	const opacity = useMemo(
		() => ({ opacity: selectedUserType === OFFICIAL ? 1 : 0 }),
		[selectedUserType]
	);

	return (
		<View style={styles.mainWrapper}>
			<Header />
			<View style={styles.container}>
				<Text style={styles.textStyle}>
					Please identify yourself <Text style={styles.textRed}>*</Text>
				</Text>
				<Pressable
					style={selectedUserType === OFFICIAL ? styles.selected : styles.selectButton}
					onPress={() => setUserType(OFFICIAL)}
				>
					<Text
						style={
							selectedUserType === OFFICIAL ? styles.selectedText : styles.selectText
						}
					>
						Official
					</Text>
				</Pressable>
				<Pressable
					style={selectedUserType === COMMON ? styles.selected : styles.selectButton}
					onPress={() => {
						setUserType(COMMON);
						setOfficialType(null);
					}}
				>
					<Text
						style={
							selectedUserType === COMMON ? styles.selectedText : styles.selectText
						}
					>
						Citizen
					</Text>
				</Pressable>

				<View style={opacity}>
					<Text style={styles.questionText}>
						Which kind of Official you are
						<Text style={styles.textRed}>*</Text>
					</Text>
					<Pressable
						style={officialType === PERMANENT ? styles.selected : styles.selectButton}
						onPress={() => setOfficialType(PERMANENT)}
					>
						<Text
							style={
								officialType === PERMANENT ? styles.selectedText : styles.selectText
							}
						>
							Permanent
						</Text>
					</Pressable>
					<Pressable
						style={officialType === CONTRACT ? styles.selected : styles.selectButton}
						onPress={() => setOfficialType(CONTRACT)}
					>
						<Text
							style={
								officialType === CONTRACT ? styles.selectedText : styles.selectText
							}
						>
							Contract Basis
						</Text>
					</Pressable>
				</View>
			</View>
			<View style={[sharedStyles.btnWrapper, styles.btnWrapper]}>
				<Pressable style={sharedStyles.skipButton} onPress={handleBack}>
					<Text style={sharedStyles.skipButtonText}>Back</Text>
				</Pressable>
				<Pressable style={styles.button} onPress={handleNext}>
					<Text style={styles.buttonText}>Next</Text>
				</Pressable>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	mainWrapper: {
		flex: 1,
		flexDirection: 'column',
	},
	container: {
		width: '100%',
		padding: 16,
		flex: 3,
		justifyContent: 'center',
		marginTop: 35,
	},
	textStyle: {
		fontSize: 18,
		lineHeight: 22,
		color: PRIMARY_COLOR,
		fontWeight: '700',
		marginBottom: 10,
	},
	button: {
		backgroundColor: PRIMARY_COLOR,
		padding: 16,
		borderRadius: 4,
		width: '46%',
		color: 'white',
	},
	buttonText: {
		color: 'white',
		fontSize: 18,
		fontFamily: FONT_BOLD,
		textAlign: 'center',
	},
	btnWrapper: {
		flex: 1,
		width: '100%',
		padding: 16,
		marginBottom: 16,
	},
	selectButton: {
		width: '100%',
		backgroundColor: '#F5F7FB',
		padding: 16,
		borderRadius: 4,
		marginVertical: 8,
	},
	selectText: {
		fontSize: 18,
		fontWeight: '700',
		lineHeight: 22,
		color: '#55575A',
	},
	selected: {
		width: '100%',
		backgroundColor: PRIMARY_COLOR,
		padding: 16,
		borderRadius: 4,
		marginVertical: 10,
	},
	selectedText: {
		fontSize: 18,
		fontWeight: '700',
		lineHeight: 22,
		color: 'white',
	},
	questionText: {
		fontWeight: '600',
		fontSize: 15,
		lineHeight: 18,
		marginTop: 35,
		marginBottom: 10,
	},
	textRed: {
		color: 'red',
	},
});
