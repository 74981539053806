import { useState } from 'react';
import {
	ActivityIndicator,
	Alert,
	Pressable,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import PVerifyDetails from '../../../../assets/images/svg/PVerifyDetails';
import Colors, { PRIMARY_COLOR } from '../../../common/constants/Colors';
import { FONT_MEDIUM } from '../../../common/constants/Fonts';
import { sharedStyles } from '../../../common/constants/SharedStyles';
import axios from '../../../common/utils/axios';
import Header from '../../../components/OnboardingHeader';
import { View } from '../../../components/Themed';
import { userType } from '../../../redux/slices/user';
import { useDispatch, useSelector } from '../../../redux/store';

//constants

export default function VerifyDetailsScreen({ navigation }: { navigation: any }) {
	const [loading, setLoading] = useState(false);
	const [email, onChangeEmail] = useState<string>('');
	const [password, onChangePassword] = useState<string>('');
	const [secureEntry, setSecureEntry] = useState(true);
	const [employeeCode, onChangeEmployeeCode] = useState<string>('');
	const AuthDetails = useSelector((state) => state.auth.value);

	const dispatch = useDispatch();

	const handleSubmit = async () => {
		if (email === '' || password === '' || employeeCode === '') {
			Alert.alert('Alert!', 'Please add required fields!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else {
			let testEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
			if (testEmail === false) {
				Alert.alert('Alert!', 'Please enter a valid email!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			} else {
				try {
					setLoading(true);
					const response = await axios.post('/api/auth/verify-user', {
						account_type: AuthDetails.userType,
						account_sub_type: AuthDetails.officialType,
						action: AuthDetails.type,
						email: email,
						password: password,
						employee_code: employeeCode,
					});
					setLoading(false);

					if (response.data.status) {
						if (response.data.data.is_new_user) {
							dispatch(
								userType({
									email: email,
								})
							);
							navigation.navigate('VerifyContact');
						} else {
							Alert.alert(
								'Alert!',
								'You are already registered as a permanent official, please try to login instead!',
								[
									{
										text: 'Cancel',
										style: 'cancel',
									},
									{
										text: 'OK',
									},
								]
							);
						}
					} else {
						Alert.alert(
							'Alert!',
							'This account has not been listed for signup, please contact admin!',
							[
								{
									text: 'Cancel',
									style: 'cancel',
								},
								{
									text: 'OK',
								},
							]
						);
					}
				} catch (error: any) {
					setLoading(false);
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			}
		}
	};

	const handleBack = () => {
		navigation.pop();
	};

	return (
		<View style={styles.mainWrapper}>
			<Header />
			<ScrollView contentContainerStyle={styles.contentContainer} bounces={false}>
				<View style={styles.imageContainer}>
					<PVerifyDetails />
				</View>
				<View style={styles.container}>
					<Text style={styles.headingText}>Verify Email</Text>
					<View style={styles.form}>
						<Text style={styles.title}>
							Email
							<Text style={styles.textRed}>*</Text>
						</Text>
						<TextInput
							style={sharedStyles.generalInput}
							onChangeText={(value) => {
								onChangeEmail(value);
							}}
							value={email}
							maxLength={40}
							keyboardType="email-address"
							autoCapitalize="none"
							selectionColor={Colors.light.tabIconDefault}
						/>
						<Text style={styles.title}>
							Password received on email
							<Text style={styles.textRed}>*</Text>
						</Text>
						<View style={[sharedStyles.generalInput, sharedStyles.passwordField]}>
							<TextInput
								style={styles.textInput}
								onChangeText={(value) => {
									onChangePassword(value);
								}}
								value={password}
								maxLength={8}
								secureTextEntry={secureEntry}
								selectionColor={Colors.light.tabIconDefault}
							/>
							<Pressable onPress={() => setSecureEntry(!secureEntry)}>
								<Ionicons name={secureEntry ? 'eye-off' : 'eye'} size={24} />
							</Pressable>
						</View>
						<Text style={styles.title}>
							Employee Code
							<Text style={styles.textRed}>*</Text>
						</Text>
						<TextInput
							style={sharedStyles.generalInput}
							onChangeText={onChangeEmployeeCode}
							value={employeeCode}
							maxLength={30}
							selectionColor={Colors.light.tabIconDefault}
						/>
					</View>
				</View>
				<View style={[sharedStyles.btnWrapper, styles.btnWrapper]}>
					<Pressable style={sharedStyles.skipButton} onPress={handleBack}>
						<Text style={sharedStyles.skipButtonText}>Back</Text>
					</Pressable>
					{!loading ? (
						<Pressable
							style={[sharedStyles.skipButton, sharedStyles.done]}
							onPress={handleSubmit}
						>
							<Text style={[sharedStyles.skipButtonText, sharedStyles.doneText]}>
								Verify
							</Text>
						</Pressable>
					) : (
						<View style={[sharedStyles.skipButton, sharedStyles.done]}>
							<ActivityIndicator animating={loading} color="white" />
						</View>
					)}
				</View>
			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	contentContainer: {
		flexGrow: 1,
	},
	mainWrapper: {
		flex: 1,
		backgroundColor: 'white',
	},
	container: {
		flex: 2,
		padding: 16,
	},
	imageContainer: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 20,
		height: 90,
	},
	title: {
		fontWeight: '600',
		fontSize: 15,
		lineHeight: 18,
	},
	cameraText: {
		paddingLeft: 16,
		color: '#637381',
	},
	form: {
		paddingVertical: 16,
		width: '100%',
	},
	text: {
		fontSize: 16,
		color: '#55575A',
		fontFamily: FONT_MEDIUM,
		marginLeft: 8,
	},
	headingText: {
		fontSize: 18,
		fontWeight: '700',
		lineHeight: 22,
		marginBottom: 20,
		color: PRIMARY_COLOR,
	},
	btnWrapper: {
		width: '100%',
		marginBottom: 20,
		paddingHorizontal: 16,
	},
	textRed: {
		color: 'red',
	},
	textInput: {
		width: '90%',
	},
});
