/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';
import {
	Pressable,
	StyleProp,
	StyleSheet,
	TextStyle,
	useWindowDimensions,
	ViewStyle,
} from 'react-native';
import {
	NavigationState,
	Route,
	SceneMap,
	SceneRendererProps,
	TabBar,
	TabBarIndicatorProps,
	TabBarItemProps,
	TabView,
} from 'react-native-tab-view';
import { Event, Scene } from 'react-native-tab-view/lib/typescript/types';
import { Foundation, MaterialIcons } from '@expo/vector-icons';
import moment from 'moment';

import { PRIMARY_COLOR } from '../../common/constants/Colors';
import { FALLING, RISING, STEADY } from '../../common/constants/Constants';
//constants
import { FONT_BOLD, FONT_LIGHT, FONT_SEMI_BOLD } from '../../common/constants/Fonts';
import { Text, View } from '../Themed';

type Props = {
	open: () => void;
	waterLevelData?: Details;
	waterLevelRoute?: any;
	currRiver: string;
	onTabChange: (id: any) => void;
};

type RouteType = {
	key: string;
	title: string;
};

interface Details {
	CurrentWaterLevel: {
		downstream_water_level: string;
		upstream_water_level: string;
		water_level_date: string;
	};
	YesterdayWaterLevel: {
		downstream_water_level: string;
		upstream_water_level: string;
		water_level_date: string;
	};
	danger_level: string;
	secondLastWaterLevel: number;
	trend: string;
}

export default function WaterLevel({
	open,
	waterLevelRoute,
	waterLevelData,
	currRiver,
	onTabChange,
}: Props) {
	const [routes, setRoutes] = useState<RouteType[]>([]);
	useEffect(() => {
		setRoutes(waterLevelRoute);
	}, [waterLevelRoute]);

	// const todayMoment = moment();

	// const [prediction, setPrediction]= useState([]);

	const [index, setIndex] = useState(0);

	const layout = useWindowDimensions();
	const t = (date: string) => {
		const currDate = moment(date).format('DD-M-YYYY');
		const currTime = moment(date).format('H:mm');

		return (
			<Text style={StyleSheet.flatten([styles.lightText, { fontSize: 11 }])}>
				last recorded at {currTime} hrs of {currDate}
			</Text>
		);
	};

	const dateFormat = (date: string) => {
		const check = date.split('-');
		return [check[2], check[1], check[0]].join('-');
	};

	const getSceneMap = () => {
		let sceneMap: any = {};
		routes.length &&
			routes.map((item: any, routeIndex: any) => {
				sceneMap[item.key] = () => {
					return <FirstRoute key={routeIndex} />;
				};
			});

		return sceneMap;
	};

	const sceneRendering = SceneMap(getSceneMap());
	const FirstRoute = () => (
		<>
			{waterLevelData === undefined || Object.keys(waterLevelData).length === 0 ? (
				<View style={styles.errorContainer}>
					<View style={styles.empty}>
						<Text style={styles.emptyText}>No data found</Text>
					</View>
				</View>
			) : (
				<View style={styles.innerCard}>
					<View style={styles.flexWrapper}>
						<View style={styles.WrapperSmall}>
							<Text style={styles.semiBoldText}>
								{waterLevelData &&
									waterLevelData.CurrentWaterLevel &&
									waterLevelData.CurrentWaterLevel.water_level_date &&
									waterLevelData.CurrentWaterLevel.water_level_date.split(
										' '
									)[0] &&
									dateFormat(
										waterLevelData.CurrentWaterLevel.water_level_date.split(
											' '
										)[0]
									)}{' '}
								(in m)
							</Text>
							<View style={styles.innerWrapper}>
								<Text
									style={[
										styles.bigYellowText,
										{
											marginTop: 2,
											color: waterLevelData?.trend
												? waterLevelData?.trend === FALLING
													? '#31B231'
													: waterLevelData?.trend === RISING
													? '#CE8601'
													: waterLevelData?.trend === STEADY
													? '#CFB101'
													: '#D73030'
												: '#E0A800',
										},
									]}
								>
									{waterLevelData?.CurrentWaterLevel?.upstream_water_level}
									{waterLevelData &&
										waterLevelData?.CurrentWaterLevel &&
										waterLevelData?.CurrentWaterLevel?.downstream_water_level &&
										'/ ' +
											waterLevelData?.CurrentWaterLevel
												?.downstream_water_level}
								</Text>
							</View>
						</View>
						<View style={styles.divider} />
						<View style={styles.WrapperSmall}>
							<Text style={styles.lightGreyText}>
								{waterLevelData &&
									waterLevelData.YesterdayWaterLevel &&
									waterLevelData.YesterdayWaterLevel.water_level_date &&
									waterLevelData.YesterdayWaterLevel.water_level_date.split(
										' '
									)[0] &&
									dateFormat(
										waterLevelData.YesterdayWaterLevel.water_level_date.split(
											' '
										)[0]
									)}{' '}
								(in m)
							</Text>
							<Text style={[styles.lightGreyText, { marginTop: 2 }]}>
								{waterLevelData?.YesterdayWaterLevel?.upstream_water_level}
								{waterLevelData &&
									waterLevelData?.YesterdayWaterLevel &&
									waterLevelData?.YesterdayWaterLevel?.downstream_water_level &&
									'/ ' +
										waterLevelData?.YesterdayWaterLevel?.downstream_water_level}
							</Text>
						</View>
						<View style={styles.divider} />
						<View style={styles.WrapperSmall}>
							<Text style={styles.lightGreyText}>Danger Level</Text>
							<Text style={[styles.lightText, { color: '#D73030', marginTop: 2 }]}>
								{waterLevelData?.danger_level}
							</Text>
						</View>
						<View style={styles.divider} />
						<View style={styles.WrapperSmall}>
							<Text style={styles.lightText}>Trend</Text>
							<View style={styles.innerWrapper}>
								{waterLevelData &&
								waterLevelData.trend &&
								waterLevelData.trend === RISING ? (
									<MaterialIcons
										name={'trending-up'}
										size={12}
										color={'#E0A800'}
									/>
								) : waterLevelData.trend === FALLING ? (
									<MaterialIcons
										name={'trending-down'}
										size={12}
										color={'#31B231'}
									/>
								) : waterLevelData.trend === STEADY ? (
									<MaterialIcons
										name={'trending-flat'}
										size={12}
										color={'#CFB101'}
									/>
								) : (
									<Foundation name="alert" color={'#D73030'} size={12} />
								)}

								<Text
									style={[
										styles.bigYellowText,
										{
											textTransform: 'capitalize',
											fontSize: 12,
											marginLeft: 2,
											color:
												waterLevelData &&
												waterLevelData?.trend &&
												waterLevelData?.trend === FALLING
													? '#31B231'
													: waterLevelData?.trend === RISING
													? '#CE8601'
													: waterLevelData?.trend === STEADY
													? '#CFB101'
													: '#D73030',
										},
									]}
								>
									{waterLevelData &&
										waterLevelData.trend &&
										waterLevelData.trend.charAt(0).toUpperCase() +
											waterLevelData.trend.slice(1).toLowerCase()}
								</Text>
							</View>
						</View>
					</View>
					{/* {prediction.length!==0 &&
                <>
            <Text
                style={styles.semiBoldText}>
                Prediction (5D)
            </Text>
            <View style={styles.flexWrapper}>
                {prediction && prediction.length!==0 && prediction.map((val)=>{
                    return(
                <View style={[styles.predictionContainer, {
                    backgroundColor:
                    val &&
                    val?.trend &&
                    val?.trend === FALLING ? "#DFF1D8" :
                    val?.trend === RISING ? "#FFDA95" :
                    val?.trend === STEADY ? "#FDFECD" :
                    "#FFE4DE",
                }]} key={val.key}>
                            <Text style={styles.lightGreyText}>
                                {val.water_level_date}
                            </Text>
                            <Text
                                style={[
                                    styles.lightText,
                                    {
                                    color:
                                        val &&
                                        val?.trend &&
                                        val?.trend === FALLING ? "#31B231" :
                                        val?.trend === RISING ? "#CE8601" :
                                        val?.trend === STEADY ? "#CFB101" :
                                        "#D73030",
                                    marginTop: 2 },
                                ]}
                            >
                                {val.water_level_data}
                            </Text>
                </View>
                    )
                })}

            </View>
            </>
            } */}
				</View>
			)}
		</>
	);

	const renderTabBar = (
		props: JSX.IntrinsicAttributes &
			SceneRendererProps & {
				navigationState: NavigationState<Route>;
				scrollEnabled?: boolean | undefined;
				bounces?: boolean | undefined;
				activeColor?: string | undefined;
				inactiveColor?: string | undefined;
				pressColor?: string | undefined;
				pressOpacity?: number | undefined;
				getLabelText?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getAccessible?: ((scene: Scene<Route>) => boolean | undefined) | undefined;
				getAccessibilityLabel?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getTestID?: ((scene: Scene<Route>) => string | undefined) | undefined;
				renderLabel?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderIcon?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderBadge?: ((scene: Scene<Route>) => React.ReactNode) | undefined;
				renderIndicator?:
					| ((props: TabBarIndicatorProps<Route>) => React.ReactNode)
					| undefined;
				renderTabBarItem?:
					| ((
							props: TabBarItemProps<Route> & { key: string }
					  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
					| undefined;
				onTabPress?: ((scene: Scene<Route> & Event) => void) | undefined;
				onTabLongPress?: ((scene: Scene<Route>) => void) | undefined;
				tabStyle?: StyleProp<ViewStyle>;
				indicatorStyle?: StyleProp<ViewStyle>;
				indicatorContainerStyle?: StyleProp<ViewStyle>;
				labelStyle?: StyleProp<TextStyle>;
				contentContainerStyle?: StyleProp<ViewStyle>;
				style?: StyleProp<ViewStyle>;
				gap?: number | undefined;
			}
	) => (
		<TabBar
			{...props}
			scrollEnabled
			indicatorStyle={styles.white}
			style={styles.white}
			tabStyle={styles.tabBar}
		/>
	);

	return (
		<View
			style={[
				styles.container,
				(waterLevelData === undefined || Object.keys(waterLevelData).length === 0) &&
					styles.noDataHeight,
				(!waterLevelRoute || waterLevelRoute.length === 0) && styles.noRouteHeight,
			]}
		>
			{!waterLevelRoute || waterLevelRoute.length === 0 ? (
				<View style={styles.errorContainer}>
					<View style={[styles.empty, styles.routeEmpty]}>
						<Text style={styles.emptyText}>No data found</Text>
					</View>
				</View>
			) : (
				<>
					{waterLevelData !== undefined && Object.keys(waterLevelData).length !== 0 && (
						<>
							<Text style={styles.lightGreyText}>
								{t(waterLevelData?.CurrentWaterLevel.water_level_date)}
							</Text>
							<View style={[styles.wrapper]}>
								<Text style={styles.selectTitle}>River - {currRiver}</Text>
								<Pressable onPress={open}>
									<Text style={styles.editText}>Edit Location Details</Text>
								</Pressable>
							</View>
						</>
					)}
					<TabView
						navigationState={{ index, routes }}
						renderScene={sceneRendering}
						// renderScene={renderScene}
						// onIndexChange={setIndex}
						onIndexChange={(index) => {
							setIndex(index);
							onTabChange(routes[index].key);
							// setDetails(undefined);
							// getWaterLevel(routes[index].key);
						}}
						initialLayout={{ width: layout.width }}
						style={styles.tab}
						renderTabBar={renderTabBar}
					/>
				</>
			)}
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		borderRadius: 4,
		marginTop: 3,
		// height: "11%",
		height: 270,
		width: '100%',
	},
	noPrediction: {
		height: 220,
	},
	noDataHeight: {
		height: 180,
	},
	noRouteHeight: {
		height: 140,
	},
	flexWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		backgroundColor: '#F5F7FB',
	},
	innerWrapper: {
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#F5F7FB',
	},
	WrapperSmall: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
		backgroundColor: '#F5F7FB',
		height: 20,
	},
	divider: {
		height: 50,
		width: 1,
		backgroundColor: '#DFE0EB',
	},
	innerCard: {
		padding: 8,
		backgroundColor: '#F5F7FB',
		width: '100%',
		marginTop: 8,
		borderRadius: 4,
	},
	semiBoldText: {
		fontFamily: FONT_SEMI_BOLD,
		fontSize: 12,
	},
	bigYellowText: {
		fontFamily: FONT_SEMI_BOLD,
		fontSize: 14,
		color: '#E0A800',
	},
	lightText: {
		fontFamily: FONT_LIGHT,
		fontSize: 11,
	},
	lightGreyText: {
		fontFamily: FONT_LIGHT,
		fontSize: 11,
		color: '#55575A',
	},
	tab: {
		width: '100%',
		height: 140,
		marginTop: 12,
	},
	wrapper: {
		backgroundColor: 'white',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginVertical: 8,
		width: '100%',
	},
	selectTitle: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: '600',
	},
	editText: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: '600',
		color: PRIMARY_COLOR,
		textDecorationLine: 'underline',
	},
	errorContainer: {
		width: '100%',
		// alignItems: "flex-end",
	},
	empty: {
		backgroundColor: '#F5F7FB',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		marginTop: 10,
		borderRadius: 4,
		height: 80,
	},
	routeEmpty: {
		height: 100,
	},
	emptyText: {
		fontFamily: FONT_BOLD,
		fontSize: 18,
		color: '#346499',
		marginLeft: 6,
	},
	predictionContainer: {
		borderRadius: 5,
		padding: 5,
		marginTop: 5,
	},
	white: {
		backgroundColor: 'white',
	},
	tabBar: {
		backgroundColor: '#346499',
		borderRadius: 4,
		marginHorizontal: 5,
	},
});
