import { useEffect, useState } from 'react';
import {
	Alert,
	Pressable,
	StyleProp,
	StyleSheet,
	Text,
	TextStyle,
	useWindowDimensions,
	View,
	ViewStyle,
} from 'react-native';
import DropDownPicker, { ItemType, ValueType } from 'react-native-dropdown-picker';
import {
	NavigationState,
	Route,
	SceneMap,
	SceneRendererProps,
	TabBar,
	TabBarIndicatorProps,
	TabBarItemProps,
	TabView,
} from 'react-native-tab-view';
import { Event, Scene } from 'react-native-tab-view/lib/typescript/types';
import { useDispatch, useSelector } from 'react-redux';
import { AntDesign } from '@expo/vector-icons';

import Colors, { PRIMARY_COLOR } from '../../common/constants/Colors';
import { FONT_NORMAL } from '../../common/constants/Fonts';
import { sharedStyles } from '../../common/constants/SharedStyles';
import axios from '../../common/utils/axios';
import { nepalDetails } from '../../redux/slices/nepal';

type Option = {
	label: string;
	value: number;
};

type BasinType = {
	basin_name: string;
	water_level_in_m: string;
};

type StationType = {
	basin_name: string;
	district: string;
	hour_1: string;
	hour_3: string;
	hour_6: string;
	hour_12: string;
	hour_24: string;
	id: number;
	rainfall_date: string;
	station_name: string;
	status: string;
};

const UNFOCUSED_TAB_FONT_COLOR = '#A7A7A7';

const ArrowDown = () => <AntDesign name="down" size={12} color="white" />;
const ArrowUp = () => <AntDesign name="up" size={12} color="white" />;
const EmptyList = () => <Text style={styles.emptyList}>There's nothing to show!</Text>;

export default function NepalNews() {
	const [basinList, setBasinList] = useState<Option[]>([]);
	const [basinPage, setBasinPage] = useState<number>(1);
	const [basinLastPage] = useState<number>(1);
	const [basinValue, setBasinValue] = useState<Option | any>(null);
	const [basinLabel, setBasinLabel] = useState<Option | any>('');
	const [openBasin, setOpenBasin] = useState(false);
	const [basinData, setBasinData] = useState<BasinType>();
	const [stationData, setStationData] = useState<StationType>();

	//river
	const [riverList, setRiverList] = useState<Option[]>([]);
	const [riverPage, setRiverPage] = useState<number>(1);
	const [riverLastPage, setRiverLastPage] = useState<number>(1);
	const [riverValue, setRiverValue] = useState<Option | any>(null);
	const [riverLabel, setRiverLabel] = useState<Option | any>('');
	const [openRiver, setOpenRiver] = useState(false);
	const dispatch = useDispatch();
	const { basinId, stationId, basinName, stationName } = useSelector(
		(state: any) => state.nepal.value
	);

	const [routes] = useState([
		{ key: '1', title: '1 hr' },
		{ key: '3', title: '3 hr' },
		{ key: '6', title: '6 hr' },
		{ key: '12', title: '12 hr' },
		{ key: '24', title: '24 hr' },
	]);

	const getBasin = async (refresh: boolean) => {
		if (refresh) {
			setBasinPage(1);
		}
		if (refresh && basinPage > 1) {
			return;
		}
		try {
			await axios
				.get('/api/dashboard/river-basin', {
					params: {
						page: basinPage,
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data.length !== 0) {
						setBasinList(data);
					} else {
						setBasinList([]);
					}
					if (basinId !== '' && basinName !== '') {
						setBasinValue(basinId);
						getBasinData(basinName);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const getBasinData = async (value: any) => {
		try {
			await axios
				.get('/api/dashboard/river-watch', {
					params: {
						basin_name: value,
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.length !== 0) {
						setBasinData(data);
					} else {
						setBasinData(undefined);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const getStation = async (refresh: boolean) => {
		if (refresh) {
			setRiverPage(1);
		}
		if (refresh && riverPage > 1) {
			return;
		}
		try {
			await axios
				.get('/api/dashboard/rainfall-station', {
					params: {
						page: riverPage,
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setRiverLastPage(data.info.lastPage);
						if (riverPage === 1) {
							setRiverList(data.items);
						} else {
							setRiverList((prev) => [...prev, ...data.items]);
						}

						if (stationId !== '' && stationName !== '') {
							setRiverValue(stationId);
							getStationData(stationName);
						}
					} else {
						setRiverList([]);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const getStationData = async (value: any) => {
		try {
			await axios
				.get('/api/dashboard/rainfall-watch', {
					params: {
						station_name: value,
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.length !== 0) {
						setStationData(data);
					} else {
						setStationData(undefined);
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (riverPage <= riverLastPage) {
			getStation(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [riverPage]);

	useEffect(() => {
		if (basinPage <= basinLastPage) {
			getBasin(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [basinPage]);

	const [index, setIndex] = useState(0);

	const layout = useWindowDimensions();
	const item = () => (
		<View style={styles.outLinedWrapper}>
			<Text style={styles.name}>
				{stationData && stationData !== undefined ? stationData.station_name : '-'}
			</Text>
			<Text style={styles.value}>
				{stationData &&
					stationData !== undefined &&
					(index === 0
						? stationData.hour_1
						: index === 1
						? stationData.hour_3
						: index === 2
						? stationData.hour_6
						: index === 3
						? stationData.hour_12
						: stationData.hour_24)}
			</Text>
		</View>
	);

	const renderScene = SceneMap({
		1: item,
		3: item,
		6: item,
		12: item,
		24: item,
	});

	const renderTabBar = (
		props: JSX.IntrinsicAttributes &
			SceneRendererProps & {
				navigationState: NavigationState<Route>;
				scrollEnabled?: boolean | undefined;
				bounces?: boolean | undefined;
				activeColor?: string | undefined;
				inactiveColor?: string | undefined;
				pressColor?: string | undefined;
				pressOpacity?: number | undefined;
				getLabelText?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getAccessible?: ((scene: Scene<Route>) => boolean | undefined) | undefined;
				getAccessibilityLabel?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getTestID?: ((scene: Scene<Route>) => string | undefined) | undefined;
				renderLabel?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderIcon?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderBadge?: ((scene: Scene<Route>) => React.ReactNode) | undefined;
				renderIndicator?:
					| ((props: TabBarIndicatorProps<Route>) => React.ReactNode)
					| undefined;
				renderTabBarItem?:
					| ((
							props: TabBarItemProps<Route> & { key: string }
					  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
					| undefined;
				onTabPress?: ((scene: Scene<Route> & Event) => void) | undefined;
				onTabLongPress?: ((scene: Scene<Route>) => void) | undefined;
				tabStyle?: StyleProp<ViewStyle>;
				indicatorStyle?: StyleProp<ViewStyle>;
				indicatorContainerStyle?: StyleProp<ViewStyle>;
				labelStyle?: StyleProp<TextStyle>;
				contentContainerStyle?: StyleProp<ViewStyle>;
				style?: StyleProp<ViewStyle>;
				gap?: number | undefined;
			}
	) => (
		<View>
			<TabBar
				{...props}
				indicatorStyle={{
					backgroundColor: Colors.light.background,
				}}
				scrollEnabled
				style={{
					backgroundColor: PRIMARY_COLOR,
				}}
				tabStyle={styles.tabWidth}
				renderLabel={({ route, focused }) => {
					return (
						<Text
							style={{
								color: focused ? Colors.light.background : UNFOCUSED_TAB_FONT_COLOR,
							}}
						>
							{route.title}
						</Text>
					);
				}}
			/>
		</View>
	);

	return (
		<View style={styles.container}>
			<View style={styles.flexWrapper}>
				<Text style={styles.heading}>Nepal Rainfall Watch</Text>
				<Pressable
					style={styles.button}
					// onPress={() => navigation.navigate("IncidentTab")}
				>
					<Text style={styles.buttonText}>View all</Text>
					<AntDesign name="doubleright" size={15} color="#346499" />
				</Pressable>
			</View>
			<Text style={styles.watchText}>River Watch</Text>

			<DropDownPicker
				listMode="SCROLLVIEW"
				dropDownDirection="BOTTOM"
				style={[sharedStyles.input, styles.input]}
				open={openBasin}
				value={basinValue}
				setValue={setBasinValue}
				activityIndicatorColor="white"
				ArrowDownIconComponent={ArrowDown}
				ArrowUpIconComponent={ArrowUp}
				ListEmptyComponent={EmptyList}
				items={basinList}
				setOpen={setOpenBasin}
				setItems={setBasinList}
				onOpen={() => setOpenRiver(false)}
				onSelectItem={(basinItem: ItemType<ValueType>) => {
					if (basinItem) {
						getBasinData(basinItem.label);
						setBasinLabel(basinItem.label);
						dispatch(
							nepalDetails({
								basinId: basinItem.value,
								basinName: basinItem.label,
								stationId: riverValue,
								stationName: riverLabel,
							})
						);
					}
				}}
				placeholder="Select Basin"
				scrollViewProps={{
					nestedScrollEnabled: true,
					onMomentumScrollEnd: ({ nativeEvent }) => {
						const paddingToBottom = 30;
						if (
							nativeEvent.layoutMeasurement.height + nativeEvent.contentOffset.y >=
							nativeEvent.contentSize.height - paddingToBottom
						) {
							if (basinPage < basinLastPage) {
								setBasinPage(basinPage + 1);
							}
						}
					},
					scrollEventThrottle: 0.1,
				}}
				placeholderStyle={styles.basinPlaceholder}
				zIndex={1999}
				dropDownContainerStyle={styles.dropdownContainer}
				listItemLabelStyle={styles.itemLabel}
				textStyle={styles.text}
			/>

			<View style={[styles.outLinedWrapper, styles.topSpace]}>
				<Text style={styles.name}>
					{basinData && basinData !== undefined ? basinData.basin_name : '-'}
				</Text>
				<Text style={styles.value}>
					{basinData && basinData !== undefined && basinData.water_level_in_m}
				</Text>
			</View>
			<Text style={styles.watchText}>Rainfall Watch</Text>
			<DropDownPicker
				listMode="SCROLLVIEW"
				dropDownDirection="BOTTOM"
				style={[sharedStyles.input, styles.input]}
				open={openRiver}
				value={riverValue}
				labelProps={{ numberOfLines: 1 }}
				setValue={setRiverValue}
				onOpen={() => setOpenBasin(false)}
				setOpen={setOpenRiver}
				setItems={setRiverList}
				zIndex={999}
				items={riverList}
				ArrowDownIconComponent={ArrowDown}
				ArrowUpIconComponent={ArrowUp}
				onSelectItem={(riverItem: ItemType<ValueType>) => {
					if (riverItem) {
						getStationData(riverItem.label);
						setRiverLabel(riverItem.label);
						dispatch(
							nepalDetails({
								basinId: basinValue,
								basinName: basinLabel,
								stationId: riverItem.value,
								stationName: riverItem.label,
							})
						);
					}
				}}
				placeholder="Select Station"
				scrollViewProps={{
					nestedScrollEnabled: true,
					disableIntervalMomentum: true,
					onMomentumScrollEnd: ({ nativeEvent }) => {
						if (riverList.length !== 0) {
							const paddingToBottom = 200;
							if (
								nativeEvent.layoutMeasurement.height +
									nativeEvent.contentOffset.y >=
								nativeEvent.contentSize.height - paddingToBottom
							) {
								if (riverPage < riverLastPage) {
									setRiverPage(riverPage + 1);
								}
							}
						}
					},
					scrollEventThrottle: 1,
				}}
				ListEmptyComponent={EmptyList}
				placeholderStyle={styles.text}
				dropDownContainerStyle={styles.riverDropdownContainer}
				listItemLabelStyle={styles.itemLabel}
				textStyle={styles.text}
			/>
			<TabView
				navigationState={{ index, routes }}
				renderScene={renderScene}
				onIndexChange={setIndex}
				initialLayout={{ width: layout.width }}
				style={styles.tab}
				renderTabBar={renderTabBar}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: PRIMARY_COLOR,
		marginHorizontal: 16,
		padding: 12,
		borderRadius: 4,
	},
	heading: {
		fontSize: 18,
		color: 'white',
		fontWeight: '600',
		lineHeight: 22,
	},
	name: {
		fontSize: 13,
		color: 'white',
		fontWeight: '300',
		lineHeight: 16,
	},
	value: {
		fontSize: 15,
		color: 'white',
		fontWeight: '300',
		lineHeight: 18,
	},
	flexWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	outLinedWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 4,
		borderWidth: 1,
		borderColor: 'white',
		padding: 8,
		marginTop: 30,
		marginBottom: 10,
		height: 40,
	},
	topSpace: {
		marginTop: 40,
	},
	button: {
		backgroundColor: 'transparent',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
	},
	buttonText: {
		fontFamily: FONT_NORMAL,
		color: PRIMARY_COLOR,
		fontSize: 13,
		marginRight: 6,
	},
	watchText: {
		fontSize: 11,
		color: 'white',
		fontWeight: '700',
		lineHeight: 13,
		marginVertical: 8,
	},
	input: {
		backgroundColor: PRIMARY_COLOR,
		borderWidth: 1,
		borderColor: '#fff',
		color: 'white',
		padding: 5,
		width: 150,
	},
	tab: {
		marginTop: 40,
		height: 130,
	},
	emptyList: {
		color: 'black',
		fontSize: 12,
		marginHorizontal: 5,
		marginVertical: 10,
	},
	basinPlaceholder: {
		fontSize: 12,
		backgroundColor: PRIMARY_COLOR,
		color: 'white',
	},
	dropdownContainer: {
		borderColor: '#A7A7A7',
		zIndex: 2000,
		width: 150,
		maxHeight: 150,
	},
	itemLabel: {
		fontSize: 12,
		color: 'black',
	},
	text: {
		fontSize: 12,
		color: 'white',
	},
	riverDropdownContainer: {
		borderColor: '#A7A7A7',
		width: 150,
		maxHeight: 150,
		zIndex: 1000,
	},
	tabWidth: {
		width: 65,
	},
});
