import { Image, StyleSheet, Text } from 'react-native';

//constant
import { PRIMARY_COLOR } from '../common/constants/Colors';
import { FONT_NORMAL } from '../common/constants/Fonts';

import { View } from './Themed';

export default function Header() {
	return (
		<View style={styles.container}>
			<Image style={styles.logo} source={require('../../assets/images/icon.png')} />
			<View style={styles.containerRight}>
				<Text style={styles.text}>An Initiative by Bihar Government</Text>
				<Image style={styles.user} source={require('../../assets/images/User1.png')} />
				<Image style={styles.user} source={require('../../assets/images/User2.png')} />
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		backgroundColor: PRIMARY_COLOR,
		paddingTop: 50,
		paddingHorizontal: 14,
		paddingBottom: 14,
	},
	containerRight: {
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		backgroundColor: PRIMARY_COLOR,
	},
	logo: {
		width: 64,
		height: 34,
	},
	user: {
		width: 30,
		height: 30,
	},
	text: {
		fontSize: 11,
		color: '#ffffff',
		fontWeight: '300',
		paddingRight: 13,
		fontFamily: FONT_NORMAL,
	},
});
