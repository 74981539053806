import { useMemo, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { FontAwesome, FontAwesome5, Ionicons } from '@expo/vector-icons';

//constants
import { PRIMARY_COLOR } from '../../common/constants/Colors';
import {
	ABOVE_DANGER,
	ALERT,
	BELOW_WARNING,
	DANGER,
	FALLING,
	HEAVYRAINFALL,
	HFL,
	NOWARNING,
	RAINFALL,
	RISING,
	WARNING,
	WATCH,
	WATERDISCHARGE,
	WATERLEVEL,
} from '../../common/constants/Constants';
import { FONT_BOLD, FONT_LIGHT, FONT_MEDIUM, FONT_NORMAL } from '../../common/constants/Fonts';
import { AlertProps } from '../../screens/domain/HomeTabScreen';
import { Text, View } from '../Themed';

type Props = {
	alert: AlertProps;
};

export default function AlertCard({ alert }: Props) {
	const [collapsed, setCollapsed] = useState(true);
	const [maxLines] = useState(2);
	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const toggleDesc = () => {
		if (collapsed === false) {
			setCollapsed(true);
		}
	};

	const t = (date: string) => {
		const d = new Date(date);
		let hours = d.getHours();
		let minutes = d.getMinutes(); // Check whether AM or PM
		var newformat = hours >= 12 ? 'PM' : 'AM'; // Find current hour in AM-PM Format
		hours = hours % 12; // To display "0" as "12"
		hours = hours ? hours : 12;
		const m = minutes < 10 ? '0' + minutes : minutes;

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		var alertDate = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) {
			month = '0' + month;
		}
		if (day.length < 2) {
			day = '0' + day;
		}

		return (
			<Text style={StyleSheet.flatten([styles.lightText, styles.smallFont])}>
				{hours}:{m} {newformat} | {[day, month, year].join('-')}
			</Text>
		);
	};

	const alertTrendColor = useMemo(
		() => ({
			color:
				alert && alert?.trend
					? alert?.trend === FALLING
						? '#31B231'
						: alert?.trend === RISING
						? '#D20000'
						: '#E0A800'
					: '#E0A800',
		}),
		[alert]
	);

	return (
		<>
			<View
				style={StyleSheet.flatten([
					styles.container,
					{
						backgroundColor:
							(alert && alert.severity === WARNING) || alert.severity === HFL
								? '#FFE4DE'
								: (alert && alert.severity === ALERT) || alert.severity === DANGER
								? '#FFDA95'
								: (alert && alert.severity === WATCH) ||
								  (alert.alerttype &&
										alert.alerttype === WATERLEVEL &&
										alert.severity === WARNING)
								? '#FDFECD'
								: '#DFF1D8',
					},
				])}
			>
				<View style={styles.flexWrapper}>
					<View style={[styles.flexWrapperSmall]}>
						{alert && alert.severity && (
							<>
								<Ionicons
									name="alert-circle"
									size={15}
									color={
										(alert && alert.severity === WARNING) ||
										alert.severity === HFL
											? '#D20000'
											: (alert && alert.severity === ALERT) ||
											  alert.severity === DANGER
											? '#CE8601'
											: (alert && alert.severity === WATCH) ||
											  (alert.alerttype &&
													alert.alerttype === WATERLEVEL &&
													alert.severity === WARNING)
											? '#CFB101'
											: '#31B231'
									}
								/>
								<Text
									style={StyleSheet.flatten([
										styles.lightText,
										{
											color:
												(alert && alert.severity === WARNING) ||
												alert.severity === HFL
													? '#D20000'
													: (alert && alert.severity === ALERT) ||
													  alert.severity === DANGER
													? '#CE8601'
													: (alert && alert.severity === WATCH) ||
													  (alert.alerttype &&
															alert.alerttype === WATERLEVEL &&
															alert.severity === WARNING)
													? '#CFB101'
													: '#31B231',
											marginLeft: 3,
											marginBottom: 1,
										},
									])}
								>
									{alert && alert.severity === NOWARNING
										? 'No Warning'
										: alert && alert.severity === HFL
										? alert.severity
										: alert.severity &&
										  alert.severity.charAt(0).toUpperCase() +
												alert.severity.slice(1).toLowerCase()}
								</Text>
							</>
						)}
					</View>
					<Text style={StyleSheet.flatten([styles.lightText, styles.smallFont])}>
						{t(alert && alert.created_at)}
					</Text>
				</View>
				{alert && alert.title && (
					<Text style={styles.title}>
						{alert && alert.alerttype && alert.alerttype === WATERDISCHARGE && (
							<FontAwesome
								name="circle"
								size={15}
								color={
									alert &&
									alert?.title &&
									(alert?.title).toLowerCase() === BELOW_WARNING
										? '#31B231'
										: (alert?.title).toLowerCase() === ABOVE_DANGER
										? '#D20000'
										: '#CE8601'
								}
							/>
						)}{' '}
						{alert && alert.title}
						{alert &&
							alert.alerttype &&
							alert.alerttype === WATERDISCHARGE &&
							alert.trend &&
							' and '}
						{alert &&
							alert.alerttype &&
							alert.alerttype === WATERDISCHARGE &&
							alert.trend && (
								<Text style={alertTrendColor}>{alert && alert.trend}</Text>
							)}
					</Text>
				)}
				<View style={styles.flexWrapper}>
					<View style={styles.flexWrapperSmall}>
						{alert && alert.alertArea && alert.alertArea[0] && (
							<>
								<Ionicons name="location-sharp" size={15} color="#346499" />
								<Text
									style={StyleSheet.flatten([
										styles.normalText,
										{ marginLeft: 5, marginBottom: 1 },
									])}
								>
									{alert.alertArea[0].village &&
										`${alert.alertArea[0].village}, `}{' '}
									{alert.alertArea[0].block && `${alert.alertArea[0].block}, `}
									{alert.alertArea[0].district && alert.alertArea[0].district}
								</Text>
							</>
						)}
					</View>
					{alert &&
						alert.alerttype &&
						alert.alerttype !== HEAVYRAINFALL &&
						alert.alertArea[0] &&
						alert.alertArea[0].river &&
						alert.alertArea[0].river.name && (
							<View style={styles.flexWrapperSmall}>
								<FontAwesome5 name="water" size={15} color="#346499" />
								<Text
									style={StyleSheet.flatten([
										styles.normalText,
										{ marginLeft: 8, marginBottom: 1 },
									])}
								>
									{alert &&
										alert.alertArea[0] &&
										alert.alertArea[0].river &&
										alert.alertArea[0].river.name}
								</Text>
							</View>
						)}
				</View>

				<View style={styles.flexWrapper}>
					{alert && alert.alerttype && alert.alerttype === RAINFALL && alert.rainfall && (
						<Text
							style={StyleSheet.flatten([styles.lightSmallText, { marginBottom: 1 }])}
						>
							Recorded rainfall - {alert && alert.rainfall}
						</Text>
					)}
					{alert &&
						alert.alerttype &&
						alert.alerttype === WATERLEVEL &&
						alert.water_level && (
							<Text
								style={StyleSheet.flatten([
									styles.lightSmallText,
									{ marginBottom: 1 },
								])}
							>
								Recorded water level - {alert && alert.water_level}
							</Text>
						)}
					{alert &&
						alert.alerttype &&
						alert.alerttype === WATERDISCHARGE &&
						alert.water_discharge && (
							<Text
								style={StyleSheet.flatten([
									styles.lightSmallText,
									{ marginBottom: 1 },
								])}
							>
								Recorded water discharge - {alert && alert.water_discharge}
							</Text>
						)}
					{/* {alert &&
                        alert.alerttype &&
                        alert.alerttype === WATERDISCHARGE &&
                        alert.trend && (
                            <Text
                                style={[
                                    styles.normalText,
                                    {
                                        color:
                                            alert && alert?.trend
                                                ? alert?.trend === FALLING
                                                    ? "#31B231"
                                                    : alert?.trend === RISING
                                                    ? "#D20000"
                                                    : "#E0A800"
                                                : "#E0A800",
                                    },
                                ]}
                            >
                                <Feather
                                    name="trending-up"
                                    size={15}
                                    color={
                                        alert && alert?.trend
                                            ? alert?.trend === FALLING
                                                ? "#31B231"
                                                : alert?.trend === RISING
                                                ? "#D20000"
                                                : "#E0A800"
                                            : "#E0A800"
                                    }
                                />{" "}
                                {alert && alert.trend}
                            </Text>
                        )} */}
				</View>
				<View
					style={StyleSheet.flatten([
						styles.flexContainer,
						{
							flexDirection: 'column',
							alignItems: 'flex-start',
							height: 'auto',
						},
					])}
				>
					{alert && alert.description && (
						<>
							<Pressable onPress={toggleCollapsed}>
								<Text
									style={styles.lightText}
									numberOfLines={collapsed ? maxLines : undefined}
								>
									{alert && alert.description}
								</Text>
							</Pressable>
							{alert.description.length > 60 && (
								<Pressable style={styles.textButton} onPress={toggleCollapsed}>
									<Text style={[styles.mediumText, { color: PRIMARY_COLOR }]}>
										{collapsed ? 'Continue reading' : 'View Less'}
									</Text>
								</Pressable>
							)}
						</>
					)}
				</View>
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		padding: 10,
		borderRadius: 4,
		marginVertical: 8,
	},
	flexWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		backgroundColor: 'transparent',
		marginBottom: 8,
	},
	flexContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		backgroundColor: 'transparent',
		marginBottom: 8,
	},
	flexWrapperSmall: {
		alignItems: 'center',
		backgroundColor: 'transparent',
		borderRadius: 5,
		flexDirection: 'row',
	},
	title: {
		fontFamily: FONT_BOLD,
		fontSize: 18,
		marginTop: 2,
		marginBottom: 8,
	},
	normalText: {
		fontFamily: FONT_NORMAL,
		fontSize: 13,
	},
	lightText: {
		fontFamily: FONT_LIGHT,
		fontSize: 13,
	},
	smallFont: {
		fontSize: 11,
	},
	lightSmallText: {
		fontFamily: FONT_LIGHT,
		fontSize: 10,
		lineHeight: 10,
	},
	mediumText: {
		fontFamily: FONT_MEDIUM,
		fontSize: 13,
	},
	textButton: {
		borderBottomColor: PRIMARY_COLOR,
		borderBottomWidth: 1,
		borderStyle: 'solid',
	},
});
