import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	ActivityIndicator,
	Alert,
	Image,
	Pressable,
	ScrollView,
	StyleSheet,
	Text,
	TextInput,
	View,
} from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
//axios
import { AntDesign } from '@expo/vector-icons';
//library import
import * as ImagePicker from 'expo-image-picker';

//componnets
import PCGeneralDetaills from '../../../../assets/images/svg/PCGeneralDetaills';
//constants
import Colors from '../../../common/constants/Colors';
import { FONT_BOLD, FONT_MEDIUM } from '../../../common/constants/Fonts';
import { sharedStyles } from '../../../common/constants/SharedStyles';
import axios from '../../../common/utils/axios';
import Header from '../../../components/OnboardingHeader';
import { generalDetails } from '../../../redux/slices/details';
//Redux
import { useDispatch } from '../../../redux/store';

type FormValuesProps = {
	full_name: string;
	employee_code: string;
	role: string;
};

type Option = {
	label: string;
	value: number;
};

export default function CGeneralDetails({ navigation }: { navigation: any }) {
	const [image, setImage] = useState<string | any>('');
	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useState(false);
	const isMounted = useRef(false);
	const [designationList, setDesignationList] = useState<Option[]>([]);
	const [designationValue, setDesignationValue] = useState<Option | any>(null);
	const [designationLabel, setDesignationLabel] = useState<Option | any>(null);
	const dispatch = useDispatch();

	useEffect(() => {
		const getDesignationList = async () => {
			try {
				await axios.get('/api/user/designation').then((response) => {
					if (!isMounted.current) {
						const { data } = response.data;
						setDesignationList(data);
					}
				});
			} catch (error: any) {
				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			}
		};

		getDesignationList();
	}, []);

	useEffect(
		() => () => {
			isMounted.current = true;
		},
		[]
	);

	const { handleSubmit, control } = useForm({
		defaultValues: {
			full_name: '',
			employee_code: '',
			role: '',
		},
	});
	const onSubmit = async (data: FormValuesProps) => {
		if (data.full_name === '' || data.employee_code === '' || designationValue === null) {
			Alert.alert('Alert!', 'Please add required fields!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else {
			const testName = /^[a-z|A-Z]+(?:[ |_][a-z|A-Z]+)*$/.test(data.full_name);
			if (testName === false) {
				Alert.alert(
					'Alert!',
					'Please make sure that name contains only small letters, capital letters, space or underscore',
					[
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]
				);
			} else {
				try {
					setLoading(true);
					designationValue &&
						dispatch(
							generalDetails({
								designation: designationValue.toString().split(' ')[0],
								image: image,
								fullName: data.full_name,
								employeecode: data.employee_code,
								designationLabel: designationLabel,
							})
						);

					setLoading(false);
					navigation.navigate('CJDetails');
				} catch (error: any) {
					setLoading(false);
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			}
		}
	};

	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 0.6,
		});

		if (!result.cancelled) {
			let ext = result.uri.slice(-4);
			if (
				// ext === ".png" ||
				ext === '.jpg' ||
				ext === 'jpeg' ||
				ext === '.svg'
			) {
				setImage(result.uri);
			} else {
				Alert.alert(
					'Alert!',
					'Please check the image type only jpeg, jpg and svg are allowed!',
					[
						{
							text: 'OK',
						},
					]
				);
			}
		}
	};

	const deleteImage = () => {
		setImage('');
	};

	return (
		<View style={styles.container}>
			<Header />
			<ScrollView contentContainerStyle={styles.contentContainer}>
				<View style={styles.imageWrapper}>
					<PCGeneralDetaills />
				</View>

				<Text style={styles.title1}>General Details</Text>

				<View style={styles.imageUpload}>
					{!image ? (
						<>
							<Pressable style={styles.imageContainer} onPress={pickImage}>
								<AntDesign name="camera" size={32} color="#346499" />
							</Pressable>
							<Pressable onPress={pickImage}>
								<Text style={styles.text}>Upload your profile picture</Text>
							</Pressable>
						</>
					) : (
						<>
							<Image source={{ uri: image }} style={styles.image} />
							<View style={styles.imageChange}>
								<Pressable onPress={pickImage}>
									<Text style={styles.text}>Change</Text>
								</Pressable>
								<Pressable onPress={deleteImage} style={styles.deleteWrapper}>
									<AntDesign name="delete" size={24} color="#346499" />
								</Pressable>
							</View>
						</>
					)}
				</View>
				<View style={styles.form}>
					<Text style={styles.title}>
						Full Name
						<Text style={styles.textRed}>*</Text>
					</Text>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<TextInput
								selectionColor={Colors.light.tabIconDefault}
								value={value}
								onBlur={onBlur}
								style={sharedStyles.generalInput}
								onChangeText={onChange}
								maxLength={30}
							/>
						)}
						name="full_name"
					/>
					<Text style={styles.title}>
						Employee Code
						<Text style={styles.textRed}>*</Text>
					</Text>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value } }) => (
							<TextInput
								selectionColor={Colors.light.tabIconDefault}
								value={value}
								onBlur={onBlur}
								maxLength={30}
								style={sharedStyles.generalInput}
								onChangeText={onChange}
							/>
						)}
						name="employee_code"
					/>
					<Text style={styles.title}>
						Designation in the department
						<Text style={styles.textRed}>*</Text>
					</Text>
					<DropDownPicker
						listMode="SCROLLVIEW"
						dropDownDirection="BOTTOM"
						style={sharedStyles.generalInput}
						open={open}
						value={designationValue}
						items={designationList}
						setOpen={setOpen}
						setValue={setDesignationValue}
						setItems={setDesignationList}
						onSelectItem={(e) => {
							if (e) {
								let lb = e.label?.toString();
								if (lb) {
									setDesignationLabel(lb);
								}
							}
						}}
						zIndex={2000}
						zIndexInverse={3000}
						placeholder="Select"
						dropDownContainerStyle={styles.dropDownContainer}
						placeholderStyle={styles.placeholder}
					/>
				</View>

				<View style={[sharedStyles.btnWrapper, styles.btnWrapper]}>
					<Pressable
						style={sharedStyles.skipButton}
						onPress={() => {
							designationValue &&
								dispatch(
									generalDetails({
										designation: designationValue.toString().split(' ')[0],
									})
								);
							navigation.pop();
						}}
					>
						<Text style={sharedStyles.skipButtonText}>Back</Text>
					</Pressable>
					{!loading ? (
						<Pressable
							style={[sharedStyles.skipButton, sharedStyles.done]}
							onPress={handleSubmit(onSubmit)}
						>
							<Text style={[sharedStyles.skipButtonText, sharedStyles.doneText]}>
								Next
							</Text>
						</Pressable>
					) : (
						<View style={[sharedStyles.skipButton, sharedStyles.done]}>
							<ActivityIndicator animating={loading} color="white" />
						</View>
					)}
				</View>
			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
	},
	contentContainer: {
		flexGrow: 1,
		padding: 16,
	},
	imageChange: {
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '30%',
	},
	imageUpload: {
		marginTop: 20,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginBottom: 6,
	},
	imageWrapper: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 20,
		backgroundColor: 'white',
		height: 90,
	},
	imageContainer: {
		borderColor: '#55575A',
		borderWidth: 2,
		borderStyle: 'dashed',
		width: 75,
		height: 75,
		borderRadius: 60,
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 8,
	},
	image: {
		width: 75,
		height: 75,
		borderRadius: 60,
		marginRight: 8,
	},
	title: {
		fontWeight: '600',
		fontSize: 15,
		fontFamily: FONT_BOLD,
		paddingTop: 8,
	},
	deleteWrapper: {
		marginTop: 4,
	},
	buttonDiv: {
		marginBottom: 20,
	},
	form: {
		flex: 2,
		paddingVertical: 16,
		width: '100%',
		zIndex: 1999,
	},
	text: {
		fontSize: 16,
		color: '#55575A',
		fontFamily: FONT_MEDIUM,
		marginLeft: 8,
	},
	title1: {
		color: '#346499',
		fontSize: 18,
		fontWeight: '700',
	},
	btnWrapper: {
		flex: 1,
		width: '100%',
		marginVertical: 20,
		paddingBottom: 16,
		zIndex: 1000,
	},
	textRed: {
		color: 'red',
	},
	dropDownContainer: {
		borderColor: '#A7A7A7',
		zIndex: 2000,
		height: 130,
	},
	placeholder: {
		color: '#A7A7A7',
	},
});
