import { Pressable, SafeAreaView, StatusBar, StyleSheet, Text, View } from 'react-native';
import { EvilIcons } from '@expo/vector-icons';

type Props = {
	close: () => void;
};

const Header = ({ close }: Props) => {
	return (
		<SafeAreaView style={styles.safeAreaContainer}>
			<View style={styles.container}>
				<Text style={styles.text}>All media</Text>
				<Pressable onPress={close}>
					<EvilIcons name="close" size={24} color="white" />
				</Pressable>
			</View>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	safeAreaContainer: {
		flex: 1,
	},
	container: {
		alignItems: 'center',
		flexDirection: 'row',
		height: 52,
		justifyContent: 'space-between',
		paddingHorizontal: 15,
		marginTop: StatusBar.currentHeight,
	},
	text: {
		color: 'white',
	},
});

export default Header;
