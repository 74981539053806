import { Image, Pressable, StyleSheet, Text } from 'react-native';
//constant
import { AntDesign } from '@expo/vector-icons';

import { PRIMARY_COLOR } from '../../../common/constants/Colors';
import { FONT_BOLD } from '../../../common/constants/Fonts';
import Header from '../../../components/Header';
//components
import { View } from '../../../components/Themed';

export default function SplashScreen({ navigation }: { navigation: any }) {
	const handleNext = () => {
		navigation.navigate('Intro');
	};

	return (
		<View style={styles.mainWrapper}>
			<Header center={true} />
			<View style={styles.container}>
				<Text style={styles.textStyle}>Welcome to</Text>
				<Image source={require('../../../../assets/images/fmisc.png')} />
			</View>
			<View style={styles.wrapper}>
				<Pressable style={styles.button} onPress={handleNext}>
					<Text style={styles.buttonText}>Get Started</Text>
					<AntDesign name="doubleright" size={18} color="white" />
				</Pressable>
			</View>
			<View style={styles.btnWrapper}>
				<Image source={require('../../../../assets/images/initiative.png')} />
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	mainWrapper: {
		flex: 1,
		flexDirection: 'column',
	},
	container: {
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		marginTop: 35,
	},
	textStyle: {
		fontSize: 21,
		textAlign: 'center',
		fontWeight: '700',
		marginBottom: 30,
	},
	button: {
		backgroundColor: PRIMARY_COLOR,
		padding: 16,
		borderRadius: 4,
		width: '60%',
		color: 'white',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
	},
	buttonText: {
		color: 'white',
		fontSize: 18,
		fontFamily: FONT_BOLD,
		textAlign: 'center',
		marginRight: 8,
	},
	btnWrapper: {
		flexDirection: 'row',
		alignItems: 'flex-end',
		flex: 1,
		width: '100%',
		justifyContent: 'center',
		padding: 16,
		marginBottom: 20,
	},
	wrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		flex: 3,
	},
});
