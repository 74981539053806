/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */

import * as React from 'react';
import { AntDesign, FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../../types';
import Colors from '../common/constants/Colors';
import useAuth from '../common/hooks/useAuth';
import useColorScheme from '../common/hooks/useColorScheme';
import { setNavigationBarColor } from '../common/utils/navigationColor';
import ReportIncident from '../components/incident-reporting/ReportIncident';
import ChooseScreen from '../screens/auth/common/ChooseScreen';
import CommonDetailsScreen from '../screens/auth/common/GeneralDetailsScreen';
import IdentifyScreen from '../screens/auth/common/IdentifyScreen';
import InfoScreen from '../screens/auth/common/InfoScreen';
import SplashScreen from '../screens/auth/common/SplashScreen';
import VerifyContactScreen from '../screens/auth/common/VerifyContactScreen';
import VerifyOTPScreen from '../screens/auth/common/VerifyOTPScreen';
import CGeneralDetails from '../screens/auth/contract-signup/CGeneralDetails';
import CJurisdictionDetails from '../screens/auth/contract-signup/CJurisdictionDetails';
import CLoad from '../screens/auth/contract-signup/CLoad';
import VerifyDetailsScreen from '../screens/auth/permanentOfficial/VerifyDetailsScreen';
import AlertsTabScreen from '../screens/domain/AlertsTabScreen';
import HomeTabScreen from '../screens/domain/HomeTabScreen';
import IncidentTabScreen from '../screens/domain/IncidentTabScreen';
import LoadingScreen from '../screens/domain/LoadingScreen';
import ProfileTabScreen from '../screens/domain/ProfileTabScreen';
import NotFoundScreen from '../screens/error/NotFoundScreen';

import LinkingConfiguration from './LinkingConfiguration';

type IconProps = {
	color: string;
};

const PieChartIcon = ({ color }: IconProps) => (
	<AntDesign name="piechart" size={20} color={color} />
);

const AlertIcon = ({ color }: IconProps) => (
	<MaterialCommunityIcons name="alert" size={20} color={color} />
);

const NotificationActiveIcon = ({ color }: IconProps) => (
	<MaterialIcons name="notifications-active" size={20} color={color} />
);

const UserTieIcon = ({ color }: IconProps) => (
	<FontAwesome5 name="user-tie" size={20} color={color} />
);

export default function Navigation() {
	const [_newUser, setNewUser] = React.useState<string | null>('');
	const { isAuthenticated } = useAuth();

	const getNewuser = async () => {
		const getUser = await AsyncStorage.getItem('new_user');
		setNewUser(getUser);
	};

	getNewuser();

	return (
		<NavigationContainer linking={LinkingConfiguration} theme={DefaultTheme}>
			{!isAuthenticated && <AuthStack />}
			{isAuthenticated && <AuthenticatedStack />}
			{/* <AuthenticatedStack /> */}
		</NavigationContainer>
	);
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function AuthStack() {
	setNavigationBarColor('#fff');

	return (
		<Stack.Navigator>
			<Stack.Screen name="Splash" component={SplashScreen} options={{ headerShown: false }} />
			<Stack.Screen name="Intro" component={InfoScreen} options={{ headerShown: false }} />
			<Stack.Screen
				name="Identify"
				component={IdentifyScreen}
				options={{ headerShown: false }}
			/>
			<Stack.Screen name="Choose" component={ChooseScreen} options={{ headerShown: false }} />
			<Stack.Screen
				name="PVerifyDetails"
				component={VerifyDetailsScreen}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="VerifyContact"
				component={VerifyContactScreen}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="VerifyOTP"
				component={VerifyOTPScreen}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="PGeneralDetails"
				component={CommonDetailsScreen}
				options={{ headerShown: false }}
			/>

			{/* Contract Official Sign Up Flow */}
			<Stack.Screen
				name="CGDetails"
				component={CGeneralDetails}
				options={{ headerShown: false }}
			/>

			<Stack.Screen
				name="CJDetails"
				component={CJurisdictionDetails}
				options={{ headerShown: false }}
			/>
			<Stack.Screen name="CLoad" component={CLoad} options={{ headerShown: false }} />
		</Stack.Navigator>
	);
}

function AuthenticatedStack() {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="Loading"
				component={LoadingScreen}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="Root"
				component={BottomTabNavigator}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="ReportIncident"
				component={ReportIncident}
				options={{ headerShown: false }}
			/>
			<Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
		</Stack.Navigator>
	);
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
	const colorScheme = useColorScheme();

	setNavigationBarColor('#F5F7FB');

	return (
		<BottomTab.Navigator
			initialRouteName="HomeTab"
			screenOptions={{
				tabBarActiveTintColor: Colors[colorScheme].tint,
				tabBarStyle: {
					backgroundColor: '#F5F7FB',
					height: 70,
					paddingBottom: 16,
				},
			}}
		>
			<BottomTab.Screen
				name="HomeTab"
				component={HomeTabScreen}
				options={(_: RootTabScreenProps<'HomeTab'>) => ({
					title: 'Overview',
					tabBarIcon: PieChartIcon,
					headerShown: false,
				})}
			/>
			<BottomTab.Screen
				name="IncidentTab"
				component={IncidentTabScreen}
				options={{
					title: 'Incident Management',
					tabBarIcon: AlertIcon,
					headerShown: false,
				}}
			/>
			<BottomTab.Screen
				name="AlertTab"
				component={AlertsTabScreen}
				options={{
					title: 'Alerts',
					tabBarIcon: NotificationActiveIcon,
					headerShown: false,
				}}
			/>
			<BottomTab.Screen
				name="ProfileTab"
				component={ProfileTabScreen}
				options={{
					title: 'Profile',
					tabBarIcon: UserTieIcon,
					headerShown: false,
				}}
			/>
		</BottomTab.Navigator>
	);
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
