/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	ActivityIndicator,
	Alert,
	Image,
	Keyboard,
	Pressable,
	ScrollView,
	StyleSheet,
	TextInput,
} from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { AntDesign, Feather } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import mime from 'mime';

import Colors, { PRIMARY_COLOR } from '../../common/constants/Colors';
import { FONT_BOLD, FONT_NORMAL, FONT_SEMI_BOLD } from '../../common/constants/Fonts';
import { sharedStyles } from '../../common/constants/SharedStyles';
import axios from '../../common/utils/axios';
import { Text, View } from '../Themed';

import ReportSubmitModal from './ReportSubmitModal';

type ReportIncidentTypes = {
	onPress?: () => any;
};

type ImageProps = {
	uri: string;
	assetId?: string | null;
	width: number;
	height: number;
	type?: 'image' | 'video';
	fileName?: string | null;
	fileSize?: number;
	exif?: Record<string, any>;
	base64?: string;
	duration?: number;
	cancelled: boolean;
};

type Area = {
	label: string;
	value: number;
};

type Incident = {
	label: string;
	value: number;
};

type ItemProp = {
	item: ImagePicker.ImageInfo;
};

export default function ReportIncident(props: ReportIncidentTypes) {
	const [images, setImages] = useState<any[]>([]);
	const [incidentOpen, setIncidentOpen] = useState(false);
	const [incidentValue, setIncidentValue] = useState<Incident | any>(null);
	const [incident, setIncident] = useState<Incident[]>([]);
	const [incidentDistrictOpen, setIncidentDistrictOpen] = useState(false);
	const [incidentDistrictValue, setIncidentDistrictValue] = useState<Area | any>(null);
	const [districtPage, setDistrictPage] = useState<number>(1);
	const [districtLastPage, setDistrictLastPage] = useState<number>(1);

	const [incidentBlockOpen, setIncidentBlockOpen] = useState(false);
	const [incidentBlockValue, setIncidentBlockValue] = useState<Area | any>(null);
	const [blockPage, setBlockPage] = useState<number>(1);
	const [blockLastPage, setBlockLastPage] = useState<number>(1);

	const [incidentVillageOpen, setIncidentVillageOpen] = useState(false);
	const [incidentVillageValue, setIncidentVillageValue] = useState<Area | any>(null);
	const [villagePage, setVillagePage] = useState<number>(1);
	const [villageLastPage, setVillageLastPage] = useState<number>(1);

	const [districtList, setDistrictList] = useState<Area[]>([]);
	const [BlockList, setBlockList] = useState<Area[]>([]);
	const [villageList, setVillageList] = useState<Area[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [showNext, setShowNext] = useState<boolean>(false);
	const closeModal = () => {
		setModalVisible(false);
		props.onPress && props.onPress();
	};

	const reportAnother = () => setModalVisible(false);
	const isMounted = useRef(false);

	const pickImage = async () => {
		let length = images.length;
		if (length === 10) {
			Alert.alert('Alert', 'Maximum 10 images/videos are allowed', [
				{
					text: 'OK',
				},
			]);
			return;
		}
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: false,
			quality: 0.6,
			aspect: [4, 3],
			allowsMultipleSelection: true,
			selectionLimit: 10,
		});
		if (!result.cancelled) {
			if (result.selected && result.selected.length !== 0) {
				if (result.selected.length + length <= 10) {
					setImages([...images, ...result.selected]);
				} else {
					let array = result.selected.slice(0, 10 - length);
					setImages([...images, ...array]);
				}
			} else {
				setImages([...images, result]);
			}
		}
	};

	const captureImage = async () => {
		if (images.length === 10) {
			Alert.alert('Alert', 'Maximum 10 images/videos are allowed', [
				{
					text: 'OK',
				},
			]);
			return;
		}
		let permissionResult = await ImagePicker.requestCameraPermissionsAsync();

		if (permissionResult.granted === false) {
			Alert.alert('Alert', "You've refused to allow this appp to access your camera!", [
				{
					text: 'OK',
				},
			]);
			return;
		}

		const result = await ImagePicker.launchCameraAsync({
			allowsMultipleSelection: true,
			selectionLimit: 10,
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
		});
		if (!result.cancelled) {
			setImages([...images, result]);
		}
	};

	const deleteImg = (uri: string) => {
		let filteredData = images.filter((item: ImageProps) => item.uri !== uri);
		setImages(filteredData);
	};
	const { handleSubmit, control, reset } = useForm();

	const onFinalSubmit = async (data: any) => {
		const reportForm = new FormData();
		incidentDistrictValue &&
			reportForm.append('incident_area', incidentDistrictValue.toString().split(' ')[0]);
		incidentValue && reportForm.append('title_id', incidentValue.toString().split(' ')[0]);
		incidentBlockValue &&
			reportForm.append('block_id', incidentBlockValue.toString().split(' ')[0]);
		incidentVillageValue &&
			reportForm.append('village_id', incidentVillageValue.toString().split(' ')[0]);
		data.description && reportForm.append('incident_description', data.description);
		if (images.length > 0) {
			images.map((image: ImageProps) => {
				const newImageUri = 'file:///' + image.uri.split('file:/').join('');
				reportForm.append('image[]', {
					uri: newImageUri,
					type: mime.getType(newImageUri),
					name: newImageUri.split('/').pop(),
				} as any);
			});
		}

		try {
			setLoading(true);
			await axios
				.post('/api/incident/report-incident', reportForm, {
					headers: { 'Content-Type': 'multipart/form-data' },
				})
				.then((response) => {
					const { data: incidentData } = response.data;
					console.log(incidentData);
					setModalVisible(true);
					setIncidentValue(null);
					setIncidentDistrictValue(null);
					setIncidentBlockValue(null);
					setIncidentVillageValue(null);
					setImages([]);
					setLoading(false);
					setShowNext(false);
					reset({
						description: '',
					});
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
			setLoading(false);
		}
	};
	const onSubmit = async (data: any) => {
		if (
			incidentValue === null ||
			incidentDistrictValue === null ||
			incidentBlockValue === null
		) {
			Alert.alert('Alert!', 'Please add required fields!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else {
			Alert.alert('Please Confirm!', 'Incidents once created cannot be deleted!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
					onPress: async () => await onFinalSubmit(data),
				},
			]);
		}
	};

	const toggleNext = () => {
		setShowNext(!showNext);
	};

	const onNext = () => {
		if (
			incidentValue == null ||
			incidentBlockValue === null ||
			incidentDistrictValue === null
		) {
			Alert.alert('Alert!', 'Please enter all the mandatory fields!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		} else {
			toggleNext();
		}
	};

	const Item = ({ item }: ItemProp) => {
		if (item.type === 'image') {
			return (
				<View style={styles.imageWrapper}>
					<Image source={{ uri: item.uri }} style={styles.image} />
					<Pressable
						style={styles.closIcon}
						onPress={() => item.uri && deleteImg(item.uri)}
					>
						<View style={styles.closIcon}>
							<AntDesign name="closecircle" size={14} color="black" />
						</View>
					</Pressable>
				</View>
			);
		} else {
			return (
				<View style={styles.imageWrapper}>
					{item.fileName !== null ? (
						<Text>{item.fileName}</Text>
					) : (
						<AntDesign
							name="play"
							style={styles.playIcon}
							size={24}
							color={PRIMARY_COLOR}
						/>
					)}
					<Pressable
						style={styles.closIcon}
						onPress={() => item.uri && deleteImg(item.uri)}
					>
						<View style={styles.closIcon}>
							<AntDesign name="closecircle" size={14} color="black" />
						</View>
					</Pressable>
				</View>
			);
		}
	};

	useEffect(() => {
		const getIncidentList = async () => {
			try {
				await axios.get('/api/incident/incident-title').then((response) => {
					if (!isMounted.current) {
						const { data } = response.data;
						if (data) {
							setIncident(data);
						}
					}
				});
			} catch (error: any) {
				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			}
		};
		getIncidentList();
	}, []);

	const getDistrictList = async (refresh: boolean) => {
		if (refresh) {
			setDistrictPage(1);
		}
		if ((refresh && districtPage > 1) || !incidentValue || incidentValue === '') {
			return;
		}
		setBlockPage(1);
		setVillagePage(1);
		setIncidentDistrictValue(null);
		setBlockList([]);
		setIncidentBlockValue(null);
		setVillageList([]);
		setIncidentVillageValue(null);
		try {
			await axios
				.get('/api/alerts/district', {
					params: { page: districtPage },
				})
				.then((response) => {
					if (!isMounted.current) {
						const { data } = response.data;
						if (data && data.items) {
							setDistrictLastPage(data.info.lastPage);
							if (districtPage === 1) {
								setDistrictList(data.items);
							} else {
								setDistrictList((prev) => [...prev, ...data.items]);
							}
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (incidentValue && incidentValue !== '' && districtPage <= districtLastPage) {
			getDistrictList(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [districtPage]);

	// useEffect(() => {
	//     const getDistrictList = async () => {
	//         try {
	//             await axios
	//                 .get("/api/incident/arealist", {
	//                     params: { page: districtPage },
	//                 })
	//                 .then((response) => {
	//                     if (!isMounted.current) {
	//                         const { data } = response.data;
	//                         if (data && data.items) {
	//                         setDistrictLastPage(data.info.lastPage);
	//                         if (districtPage === 1) {
	//                             setDistrictList(data.items);
	//                         } else {
	//                             setDistrictList((prev) => [
	//                                 ...prev,
	//                                 ...data.items,
	//                             ]);
	//                         }
	//                     }
	//                     }
	//                 });
	//         } catch (error: any) {
	//             Alert.alert(
	//                 "Alert!",
	//                 error.message ? error.message : "Something went wrong!",
	//                 [
	//                     {
	//                         text: "Cancel",
	//                         style: "cancel",
	//                     },
	//                     {
	//                         text: "OK",
	//                     },
	//                 ]
	//             );
	//         }
	//     };

	//     getDistrictList();
	// }, [districtPage]);

	const getBlockList = async (refresh: boolean) => {
		if (refresh) {
			setBlockPage(1);
		}
		if ((refresh && blockPage > 1) || !incidentDistrictValue || incidentDistrictValue === '') {
			return;
		}
		try {
			setVillagePage(1);
			setIncidentBlockValue(null);
			setVillageList([]);
			setIncidentVillageValue(null);
			await axios
				.get('/api/incident/block', {
					params: {
						district_id:
							incidentDistrictValue && incidentDistrictValue.toString().split(' ')[0],
						page: blockPage,
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setBlockLastPage(data.info.lastPage);
						if (blockPage === 1) {
							setBlockList(data.items);
						} else {
							setBlockList((prev) => [...prev, ...data.items]);
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const getVillageList = async (refresh: boolean) => {
		if (refresh) {
			setVillagePage(1);
		}
		if ((refresh && villagePage > 1) || !incidentBlockValue || incidentBlockValue === '') {
			return;
		}

		setIncidentVillageValue(null);
		try {
			await axios
				.get('/api/master/village', {
					params: {
						block_id: incidentBlockValue.toString().split(' ')[0],
						page: villagePage,
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setVillageLastPage(data.info.lastPage);
						if (villagePage === 1) {
							setVillageList(data.items);
						} else {
							setVillageList((prev) => [...prev, ...data.items]);
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useEffect(() => {
		if (incidentDistrictValue && incidentDistrictValue !== '' && blockPage <= blockLastPage) {
			getBlockList(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blockPage]);

	useEffect(() => {
		if (incidentBlockValue && incidentBlockValue !== '' && villagePage <= villageLastPage) {
			getVillageList(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [villagePage]);

	useEffect(
		() => () => {
			isMounted.current = true;
		},
		[]
	);

	return (
		<View style={styles.container}>
			<Pressable style={styles.backButton} onPress={props.onPress}>
				<AntDesign name="doubleleft" size={12} color="#346499" />
				<Text style={styles.backText}>Back</Text>
			</Pressable>
			<View style={styles.header}>
				<Text style={styles.heading}>Report an incident</Text>
				<View style={styles.form}>
					{!showNext ? (
						<>
							<Text style={styles.title}>
								Type of incident
								<Text style={styles.textRed}>*</Text>
							</Text>
							<DropDownPicker
								style={[sharedStyles.generalInput, styles.input]}
								open={incidentOpen}
								value={incidentValue}
								items={incident}
								onOpen={() => {
									setIncidentDistrictOpen(false);
									setIncidentBlockOpen(false);
									setIncidentVillageOpen(false);
								}}
								setOpen={setIncidentOpen}
								setValue={setIncidentValue}
								setItems={setIncident}
								zIndex={3000}
								placeholder="Choose"
								onChangeValue={() => getDistrictList(true)}
								zIndexInverse={1000}
								placeholderStyle={styles.placeholder}
								dropDownContainerStyle={styles.dropDownContainer}
							/>
							<Text style={styles.title}>
								Select District
								<Text style={styles.textRed}>*</Text>
							</Text>
							<DropDownPicker
								style={[sharedStyles.generalInput, styles.input]}
								open={incidentDistrictOpen}
								value={incidentDistrictValue}
								items={districtList}
								onOpen={() => {
									setIncidentOpen(false);
									setIncidentBlockOpen(false);
									setIncidentVillageOpen(false);
								}}
								setOpen={setIncidentDistrictOpen}
								setValue={setIncidentDistrictValue}
								setItems={setDistrictList}
								zIndex={2500}
								listMode="FLATLIST"
								flatListProps={{
									onEndReached: () => {
										if (districtPage < districtLastPage) {
											setDistrictPage(districtPage + 1);
										}
									},
									onEndReachedThreshold: 0.9,
									keyExtractor: (item, index) => index.toString(),
								}}
								disabled={incidentValue === null ? true : false}
								onChangeValue={() => {
									getBlockList(true);
								}}
								// zIndexInverse={3000}
								placeholder="Choose"
								placeholderStyle={styles.placeholder}
								dropDownContainerStyle={StyleSheet.flatten([
									styles.dropDownContainer,
									{ zIndex: 4000 },
								])}
							/>

							<Text style={styles.title}>
								Select Block
								<Text style={styles.textRed}>*</Text>
							</Text>
							<DropDownPicker
								style={[sharedStyles.generalInput, styles.input]}
								open={incidentBlockOpen}
								value={incidentBlockValue}
								items={BlockList}
								onOpen={() => {
									setIncidentOpen(false);
									setIncidentDistrictOpen(false);
									setIncidentVillageOpen(false);
								}}
								setOpen={setIncidentBlockOpen}
								setValue={setIncidentBlockValue}
								onChangeValue={() => getVillageList(true)}
								setItems={setBlockList}
								zIndex={2000}
								listMode="FLATLIST"
								flatListProps={{
									onEndReached: () => {
										if (blockPage < blockLastPage) {
											setBlockPage(blockPage + 1);
										}
									},
									onEndReachedThreshold: 0.9,
									keyExtractor: (item, index) => index.toString(),
								}}
								disabled={incidentDistrictValue === null ? true : false}
								// zIndexInverse={3000}
								placeholder="Choose"
								placeholderStyle={styles.placeholder}
								dropDownContainerStyle={styles.dropDownContainer}
							/>

							<Text style={styles.title}>Select Village</Text>
							<DropDownPicker
								style={[sharedStyles.generalInput, styles.input]}
								open={incidentVillageOpen}
								value={incidentVillageValue}
								items={villageList}
								onOpen={() => {
									setIncidentOpen(false);
									setIncidentDistrictOpen(false);
									setIncidentBlockOpen(false);
								}}
								setOpen={setIncidentVillageOpen}
								setValue={setIncidentVillageValue}
								setItems={setVillageList}
								zIndex={1000}
								zIndexInverse={3000}
								listMode="FLATLIST"
								flatListProps={{
									onEndReached: () => {
										if (villagePage < villageLastPage) {
											setVillagePage(villagePage + 1);
										}
									},
									onEndReachedThreshold: 0.9,
									keyExtractor: (item, index) => index.toString(),
								}}
								disabled={
									incidentDistrictValue === null || incidentBlockValue === null
										? true
										: false
								}
								placeholder="Choose"
								placeholderStyle={styles.placeholder}
								dropDownContainerStyle={styles.dropDownContainer}
								dropDownDirection="TOP"
							/>
						</>
					) : (
						<>
							<View style={styles.descContainer}>
								<Text style={styles.title}>
									Enter description or any message (optional)
								</Text>
								<Controller
									name="description"
									defaultValue=""
									control={control}
									render={({ field: { onChange, value } }) => (
										<TextInput
											style={StyleSheet.flatten([
												sharedStyles.generalInput,
												styles.input,
												{
													width: '100%',
													height: 100,
													textAlignVertical: 'top',
												},
											])}
											onChangeText={onChange}
											value={value}
											numberOfLines={4}
											multiline={true}
											placeholder="Type here"
											placeholderTextColor="#A7A7A7"
											returnKeyType="done"
											blurOnSubmit={true}
											onSubmitEditing={() => {
												Keyboard.dismiss();
											}}
											maxLength={300}
											selectionColor={Colors.light.tabIconDefault}
										/>
									)}
								/>
							</View>
							<View style={styles.scrollWrapper}>
								<Text style={styles.title}>
									<Feather name="paperclip" size={15} color="black" />
									Attach photos or videos (optional)
								</Text>
								<View style={styles.btnContainer}>
									<Pressable
										style={StyleSheet.flatten([
											styles.uploadFileBtn,
											{ width: '35%', marginRight: 4 },
										])}
										onPress={captureImage}
									>
										<Feather name="camera" size={20} color="#346499" />
										<Text style={styles.uploadFileBtnText}>Take photo</Text>
									</Pressable>
									<Pressable
										style={StyleSheet.flatten([
											styles.uploadFileBtn,
											{ width: '60%' },
										])}
										onPress={pickImage}
									>
										<Feather name="upload-cloud" size={20} color="#346499" />
										<Text style={styles.uploadFileBtnText}>Browse</Text>
									</Pressable>
								</View>
								{images && (
									<ScrollView horizontal style={styles.imageContainer}>
										{images.map((item, index) => (
											<Item key={index} item={item} />
										))}
									</ScrollView>
								)}
							</View>
						</>
					)}
					{!showNext ? (
						<Pressable
							style={[styles.nextBtn]}
							onPress={onNext}
							// disabled={
							//     incidentBlockValue === null ||
							//     incidentDistrictValue === null ||
							//     incidentBlockValue === null
							//         ? true
							//         : false
							// }
						>
							<Text style={styles.reportBtnText}>Next</Text>
						</Pressable>
					) : (
						<View style={styles.btnWrapper}>
							<Pressable
								style={[styles.backBtn]}
								onPress={toggleNext}
								disabled={loading}
							>
								<Text style={styles.backBtnText}>Back</Text>
							</Pressable>
							<Pressable style={[styles.reportBtn]} onPress={handleSubmit(onSubmit)}>
								{loading ? (
									<ActivityIndicator color="white" />
								) : (
									<Text style={styles.reportBtnText}>Report</Text>
								)}
							</Pressable>
						</View>
					)}
				</View>
			</View>

			<ReportSubmitModal
				openState={modalVisible}
				onClose={closeModal}
				reportAnother={reportAnother}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		padding: 16,
	},
	header: {
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		width: '100%',
		flexDirection: 'column',
		paddingVertical: 7,
		paddingHorizontal: 6,
	},
	title: {
		fontSize: 15.42,
		fontFamily: FONT_NORMAL,
	},
	backButton: {
		backgroundColor: 'transparent',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		marginBottom: 8,
	},
	backText: {
		color: PRIMARY_COLOR,
		fontSize: 12,
		fontFamily: FONT_SEMI_BOLD,
		paddingLeft: 4,
	},
	form: {
		backgroundColor: '#F5F7FB',
		paddingHorizontal: 10,
		paddingTop: 18,
		height: '90%',
		width: '100%',
		borderRadius: 4,
	},
	input: {
		height: 50,
		borderWidth: 1.02,
		borderColor: '#DFE0EB',
		borderStyle: 'solid',
		backgroundColor: '#fff',
	},
	heading: {
		color: PRIMARY_COLOR,
		fontFamily: FONT_BOLD,
		fontSize: 21,
		paddingBottom: 16,
	},
	reportBtn: {
		backgroundColor: '#AF2525',
		borderRadius: 4,
		width: '47%',
		textAlign: 'center',
		padding: 8,
		justifyContent: 'center',
		marginTop: 16,
	},
	backBtn: {
		borderColor: PRIMARY_COLOR,
		borderRadius: 4,
		width: '47%',
		textAlign: 'center',
		justifyContent: 'center',
		padding: 8,
		marginTop: 16,
		borderWidth: 2,
	},
	nextBtn: {
		backgroundColor: PRIMARY_COLOR,
		borderRadius: 4,
		width: '100%',
		textAlign: 'center',
		padding: 16,
		marginTop: 16,
	},
	reportBtnText: {
		color: '#FFFFFF',
		textAlign: 'center',
	},
	backBtnText: {
		color: PRIMARY_COLOR,
		fontSize: 18,
		fontFamily: FONT_BOLD,
		textAlign: 'center',
	},
	uploadFileBtn: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		backgroundColor: '#FFFFFF',
		borderColor: '#DFE0EB',
		borderWidth: 1.02,
		borderStyle: 'solid',
		borderRadius: 4,
		paddingVertical: 10,
		paddingHorizontal: 15,
		marginTop: 10,
	},
	uploadFileBtnText: {
		color: PRIMARY_COLOR,
		paddingLeft: 8,
	},
	image: {
		margin: 2,
		height: 80,
		borderRadius: 4,
	},
	imageWrapper: {
		flex: 1,
		borderRadius: 4,
		backgroundColor: '#FFFF',
		height: 80,
		width: 80,
		margin: 2,
	},
	imageContainer: {
		backgroundColor: '#F5F7FB',
		paddingTop: 8,
	},
	closIcon: {
		position: 'absolute',
		right: 0,
		borderRadius: 50,
	},
	scrollWrapper: {
		backgroundColor: 'transparent',
	},
	btnContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: '#F5F7FB',
	},
	descContainer: {
		backgroundColor: '#F5F7FB',
	},
	btnWrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: '#F5F7FB',
		justifyContent: 'space-between',
	},
	playIcon: {
		position: 'absolute',
		left: 25,
		top: 27,
	},
	textRed: {
		color: 'red',
	},
	placeholder: {
		color: '#A7A7A7',
	},
	dropDownContainer: {
		borderColor: '#A7A7A7',
	},
});
