import { createSlice } from '@reduxjs/toolkit';

export const generalDetailsSlice = createSlice({
	name: 'details',
	initialState: {
		value: {
			designation: '',
			image: '',
			fullName: '',
			employeecode: '',
			designationLabel: '',
		},
	},
	reducers: {
		generalDetails: (state, action) => {
			state.value = action.payload;
		},
	},
});
export const { generalDetails } = generalDetailsSlice.actions;

export default generalDetailsSlice.reducer;
