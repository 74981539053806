/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Alert, FlatList, Image, Pressable, StyleSheet } from 'react-native';
//components
import DropDownPicker from 'react-native-dropdown-picker';
//svg
import { AntDesign, Ionicons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';

//font
import { PRIMARY_COLOR } from '../../common/constants/Colors';
import { DA } from '../../common/constants/Constants';
import {
	FONT_BOLD,
	FONT_LIGHT,
	FONT_MEDIUM,
	FONT_NORMAL,
	FONT_SEMI_BOLD,
} from '../../common/constants/Fonts';
import { sharedStyles } from '../../common/constants/SharedStyles';
import useAuth from '../../common/hooks/useAuth';
import axios from '../../common/utils/axios';
//Constants
import ReportIncident from '../../components/incident-reporting/ReportIncident';
import { ImageGallery } from '../../components/photo-grid';
import ProfileHeader from '../../components/ProfileHeader';
import { Text, View } from '../../components/Themed';

type Media = {
	file_name: string;
	id: number;
	mime_type: string;
	original_url: string;
	thumbnail_url: string;
};

type Incident = {
	id: number;
	created_at: string;
	created_by: {
		account_type: string;
		id: number;
	};
	description: string;
	district: null | {
		id: number;
		district: string;
	};
	block: null | {
		id: number;
		block: string;
	};
	village: null | {
		id: number;
		village: string;
	};
	media: Media[];
	river: string | null;
	status: string;
	title: string;
	severity: string | null;
	updated_at: string;
};

const FOOTER_HEIGHT = 100;

export default function IncidentTabScreen({ navigation }: { navigation: any }) {
	const [reportIncident, setReportIncident] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState(false);
	const openGallery = (index: number) => {
		setIndex(index);
		setIsOpen(true);
	};
	const closeGallery = () => {
		setIsOpen(false);
	};
	const [alertFlag] = useState<boolean>(false);
	const [filterFlag, setFilterFlag] = useState<boolean>(false);
	const [incidents, setIncidents] = useState<Incident[]>([]);
	const [index, setIndex] = useState<number>(0);
	const isMounted = useRef(false);
	const [listPage, setListPage] = useState<number>(1);
	const [lastPage, setLastPage] = useState<number>(1);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [selectedMediaList, setSelectedMediaList] = useState<Media[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [userId, setUserId] = useState<number>(1);
	const [incidentOpen, setIncidentOpen] = useState(false);
	const [incidentValue, setIncidentValue] = useState('all');
	const [assign, setAssign] = useState('');
	const isFocused = useIsFocused();
	const { user } = useAuth();

	const [items, setItems] = useState([
		{ value: 'all', label: 'All Incidents', assigned: '' },
		// { value: "self_reported", label: "Reported by me" },
		{
			value: 'custom',
			label: 'Reported by me',
			assigned: 'reported_by_me',
		},
		// {value: "near_by_me",label:"Near by me"}
	]);

	const [adminItems, setAdminItems] = useState([
		{ value: 'all', label: 'All Incidents', assigned: '' },
	]);

	const [collapsed, setCollapsed] = useState(true);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		const getUserDetails = async () => {
			try {
				await axios.get('/api/user/profile').then((response) => {
					if (!isMounted.current) {
						const { data } = response.data;
						setUserId(data.id);
					}
				});
			} catch (error: any) {
				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			}
		};
		getUserDetails();
	}, []);

	useEffect(() => {
		const getIncidentList = async () => {
			setLoading(true);
			setListPage(1);
			try {
				await axios
					.get('/api/incident/incident-list', {
						params: {
							type: incidentValue,
							page: 1,
							assigned: assign,
						},
					})
					.then((response) => {
						if (!isMounted.current) {
							const { data, meta } = response.data;
							setIncidents(data);
							meta && setLastPage(meta.last_page);
						}
						setLoading(false);
					});
			} catch (error: any) {
				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
				setLoading(false);
			}
		};
		getIncidentList();
		setRefresh(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh, incidentValue, isFocused]);

	const loadMore = async () => {
		let page = listPage + 1;
		if (page <= lastPage) {
			setListPage(page);
			try {
				await axios
					.get('/api/incident/incident-list', {
						params: {
							type: incidentValue,
							page: page,
							assigned: assign,
						},
					})
					.then((response) => {
						const { data } = response.data;

						if (page === 1) {
							setIncidents(data);
						} else {
							setIncidents((prev) => [...prev, ...data]);
						}
					});
			} catch (error: any) {
				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			}
		}
	};

	useEffect(
		() => () => {
			isMounted.current = true;
		},
		[]
	);

	const applyFilter = () => {
		// setIncidents(
		//     INCIDENTDATA.filter((incident) => incident.location === myLocation)
		// );
		setFilterFlag(true);
	};

	const removeFilter = () => {
		// setIncidents(INCIDENTDATA);
		setFilterFlag(false);
	};

	const t = (date: string) => {
		const d = new Date(date);
		let hours = d.getHours();
		let minutes = d.getMinutes(); // Check whether AM or PM
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		var newformat = hours >= 12 ? 'PM' : 'AM'; // Find current hour in AM-PM Format
		hours = hours % 12; // To display "0" as "12"
		hours = hours ? hours : 12;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const m = minutes < 10 ? '0' + minutes : minutes;

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		var alertDate = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) {
			month = '0' + month;
		}
		if (day.length < 2) {
			day = '0' + day;
		}

		return (
			<Text style={StyleSheet.flatten([styles.lightText, { fontSize: 11 }])}>
				{/* {hours}:{m} {newformat} |  */}
				{[day, month, year].join('-')}
			</Text>
		);
	};

	const Incident = ({ item, index: i }: any) => {
		return (
			<View style={styles.wrapper} key={i}>
				{item && item.created_by && item.created_by.toString() === userId.toString() && (
					<View style={styles.ribbonWrapper}>
						<Image
							style={styles.ribbon}
							source={require('../../../assets/images/ReportedByMe.png')}
						/>
					</View>
				)}
				<View style={styles.incidentListWrapper}>
					<View style={styles.flexWrapper}>
						<Text style={styles.biharFlood}>{item.title}</Text>
						{item && item.severity && item.severity === 'low' && (
							<View
								style={StyleSheet.flatten([
									styles.catWrapper,
									{ backgroundColor: '#E7FFE9' },
								])}
							>
								<Ionicons name="alert-circle" size={13} color="#00AB55" />
								<Text
									style={StyleSheet.flatten([
										styles.lowCat,
										{ color: '#007B55' },
									])}
								>
									Low Severity
								</Text>
							</View>
						)}
						{item && item.severity && item.severity === 'medium' && (
							<View
								style={StyleSheet.flatten([
									styles.catWrapper,
									{ backgroundColor: '#FFFCEA' },
								])}
							>
								<Ionicons name="alert-circle" size={13} color="#E0A800" />
								<Text
									style={StyleSheet.flatten([
										styles.lowCat,
										{ color: '#E0A800' },
									])}
								>
									Medium Severity
								</Text>
							</View>
						)}
						{item && item.severity && item.severity === 'high' && (
							<View
								style={StyleSheet.flatten([
									styles.catWrapper,
									{ backgroundColor: '#F9E9E5' },
								])}
							>
								<Ionicons name="alert-circle" size={13} color="#D73030" />
								<Text
									style={StyleSheet.flatten([
										styles.lowCat,
										{ color: '#D73030' },
									])}
								>
									High Severity
								</Text>
							</View>
						)}
					</View>
					<View style={styles.listInnerWrapper}>
						<Text
							style={[
								styles.status,
								{
									color:
										item &&
										(item.status === 'Active'
											? '#00AB55'
											: item.status === 'Under Review'
											? '#E0A800'
											: '#55575A'),
								},
							]}
						>
							{item && item.status}
						</Text>
						{item && item.status === 'Active' ? (
							<Text style={styles.currentDate}>
								{item && item.created_at && t(item.created_at.split(' ')[0])} -
								current
							</Text>
						) : (
							<Text style={styles.currentDate}>
								{item && item.created_at && t(item.created_at.split(' ')[0])}{' '}
								{item &&
									item.created_at &&
									item.updated_at &&
									item.created_at.split(' ')[0] !==
										item.updated_at.split(' ')[0] &&
									'-' +
										(item &&
											item.updated_at &&
											t(item.updated_at.split(' ')[0]))}
							</Text>
						)}
					</View>

					{item && item.district && (
						<View style={styles.listInnerWrapper}>
							<Ionicons name="ios-location-sharp" size={22} color={PRIMARY_COLOR} />
							{item.village && <Text>{item.village.village}, </Text>}
							{item.block && <Text>{item.block.block}, </Text>}
							<Text>{item.district.name}</Text>
						</View>
					)}
					{item && item.description && (
						<Text style={styles.lightText}>{item.description}</Text>
					)}
					{item && item.media.length > 0 && (
						<View style={styles.gallaryWrapper}>
							<>
								<View style={styles.gallaryContainer}>
									{item &&
										item.media.map((media: any, itemIndex: number) => {
											if (itemIndex <= 2) {
												let type = media.mime_type.split('/')[0];
												if (type === 'image') {
													return (
														<Pressable
															key={itemIndex}
															onPress={() => {
																setSelectedMediaList(item.media);
																openGallery(itemIndex);
															}}
														>
															<Image
																style={styles.logo}
																source={{
																	uri: media.thumbnail_url,
																}}
															/>
														</Pressable>
													);
												} else {
													return (
														<Pressable
															key={itemIndex}
															onPress={() => {
																setSelectedMediaList(item.media);
																openGallery(itemIndex);
															}}
														>
															<Image
																style={[styles.logo]}
																source={{
																	uri: media.thumbnail_url,
																}}
															/>
															<AntDesign
																name="play"
																style={styles.playIcon}
																size={24}
																color={PRIMARY_COLOR}
															/>
														</Pressable>
													);
												}
											}
										})}
								</View>
								<Pressable
									style={styles.seeAllMedia}
									onPress={() => {
										setSelectedMediaList(item.media);
										openGallery(0);
									}}
								>
									<Text style={styles.mediaText}>See all media</Text>
									<Text>
										<AntDesign name="doubleright" size={15} color="#346499" />
									</Text>
								</Pressable>
							</>
						</View>
					)}
				</View>
			</View>
		);
	};

	const ListEmpty = () => {
		return (
			<View style={styles.EmptyWrapper}>
				<Text style={styles.title}>
					{incidentValue === 'near_by_me'
						? 'No incidents found nearby you'
						: 'No incidents found'}
				</Text>
			</View>
		);
	};

	return reportIncident ? (
		<>
			<ProfileHeader navigation={navigation} />
			<ReportIncident
				onPress={() => {
					setReportIncident(false);
					setRefresh(true);
				}}
			/>
		</>
	) : (
		<>
			<ProfileHeader navigation={navigation} />
			<View style={styles.container}>
				{filterFlag && (
					<View style={styles.backContainer}>
						<Pressable style={styles.button} onPress={removeFilter}>
							<AntDesign name="doubleleft" size={15} color="#346499" />
							<Text style={styles.buttonText}>Back to all incidents</Text>
						</Pressable>
					</View>
				)}
				<View style={styles.header}>
					<Text style={styles.title}>Incidents</Text>
					{user && user.designation.label !== DA && (
						<Pressable style={styles.reportBtn} onPress={() => setReportIncident(true)}>
							<AntDesign name="exclamationcircle" size={14} color="#AF2525" />

							<Text style={styles.reportBtnText}>Report an incident</Text>
						</Pressable>
					)}
				</View>
				{alertFlag && !filterFlag && (
					<View style={styles.warningBox}>
						<Ionicons name="md-warning" size={24} color="#AF2525" />
						<Text style={styles.alertHeadLine}>
							Alert : Your area has been reported in an incident. Click&nbsp;
							<Text style={styles.span} onPress={() => applyFilter()}>
								here
							</Text>
							&nbsp;to see details.
						</Text>
					</View>
				)}
				<View style={styles.dropDownWrapper}>
					<DropDownPicker
						style={[sharedStyles.generalInput, styles.input]}
						open={incidentOpen}
						value={incidentValue}
						items={user && user.designation.label === DA ? adminItems : items}
						setOpen={setIncidentOpen}
						setValue={setIncidentValue}
						setItems={user && user.designation.label === DA ? setAdminItems : setItems}
						onChangeValue={(e) => {
							e && setIncidentValue(e);
						}}
						onSelectItem={(e: any) => {
							setAssign(e.assigned);
						}}
						zIndex={3000}
						placeholder="Select"
						zIndexInverse={1000}
						placeholderStyle={styles.placeholder}
						dropDownContainerStyle={styles.dropdownContainer}
					/>
				</View>
				<View style={styles.listWrapper}>
					{loading ? (
						<View style={styles.loaderWrapper}>
							<ActivityIndicator color={PRIMARY_COLOR} size="large" />
						</View>
					) : incidents && incidents.length > 0 ? (
						<FlatList
							data={incidents}
							renderItem={Incident}
							onEndReached={loadMore}
							onEndReachedThreshold={0.9}
							// refreshing={refresh}
							ListFooterComponent={<View style={{ height: FOOTER_HEIGHT }} />}
							// onRefresh={() => {
							//     setRefresh(true);
							// }}
						/>
					) : (
						<ListEmpty />
					)}
				</View>
				<ImageGallery
					resizeMode="contain"
					thumbColor="white"
					thumbSize={70}
					close={closeGallery}
					isOpen={isOpen}
					images={selectedMediaList}
					initialIndex={index}
				/>
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: '100%',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		flexDirection: 'row',
		paddingHorizontal: 16,
		paddingTop: 16,
	},
	title: {
		fontSize: 21,
		fontFamily: FONT_BOLD,
		color: PRIMARY_COLOR,
	},
	reportBtn: {
		borderRadius: 3,
		borderColor: '#D73030',
		borderWidth: 0.96,
		borderStyle: 'solid',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		paddingVertical: 6.4,
		paddingHorizontal: 16,
	},
	reportBtnText: {
		color: '#D73030',
		fontFamily: FONT_MEDIUM,
		fontSize: 13,
		paddingLeft: 6.4,
	},
	warningBox: {
		alignItems: 'center',
		flexDirection: 'row',
		backgroundColor: '#F9E9E5',
		borderRadius: 4,
		padding: 12,
		marginTop: 16,
		width: '100%',
	},
	alertHeadLine: {
		color: '#D73030',
		fontFamily: FONT_SEMI_BOLD,
		fontSize: 13,
		paddingLeft: 9.6,
	},
	lightText: {
		fontFamily: FONT_LIGHT,
		fontSize: 13,
	},
	span: {
		textDecorationLine: 'underline',
		color: '#D73030',
		fontFamily: FONT_SEMI_BOLD,
	},
	incidentListWrapper: {
		width: '100%',
		backgroundColor: '#FAFAFA',
		borderRadius: 4,
		padding: 8,
		marginTop: 16,
		position: 'relative',
		paddingTop: 16,
	},
	wrapper: {
		marginLeft: 16,
		marginRight: 16,
	},
	biharFlood: {
		fontFamily: FONT_BOLD,
		fontSize: 20.54,
		color: '#252733',
	},
	listInnerWrapper: {
		width: '100%',
		flexDirection: 'row',
		alignItems: 'flex-end',
		paddingTop: 8,
		backgroundColor: '#FAFAFA',
	},
	active: {
		color: '#00AB55',
		fontSize: 12,
		fontFamily: FONT_LIGHT,
		textTransform: 'capitalize',
	},
	status: {
		color: '#55575A',
		fontSize: 12,
		fontFamily: FONT_LIGHT,
		textTransform: 'capitalize',
	},
	currentDate: {
		paddingLeft: 20,
		flex: 1,
		flexWrap: 'wrap',
		fontSize: 12,
	},
	gallaryWrapper: {
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		width: '100%',
		flexDirection: 'row',
		paddingTop: 12,
		backgroundColor: '#FAFAFA',
	},
	gallaryContainer: {
		alignItems: 'flex-start',
		flexDirection: 'row',
		backgroundColor: '#FAFAFA',
	},
	logo: {
		width: 70,
		height: 70,
		borderRadius: 4,
		marginRight: 8,
	},
	mediaText: {
		fontFamily: FONT_LIGHT,
		fontSize: 11.74,
		lineHeight: 15,
	},
	seeAllMedia: {
		backgroundColor: '#F5F7FB',
		borderRadius: 4,
		width: 77,
		height: 70,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		paddingHorizontal: 9.6,
	},
	flexWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		backgroundColor: '#FAFAFA',
	},
	lowCat: {
		fontSize: 11,
		paddingLeft: 2,
	},
	catWrapper: {
		borderRadius: 4,
		flexDirection: 'row',
		alignItems: 'center',
		padding: 6,
	},
	button: {
		backgroundColor: 'transparent',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
	},
	buttonText: {
		fontFamily: FONT_NORMAL,
		color: PRIMARY_COLOR,
		fontSize: 13,
		marginLeft: 6,
	},
	backContainer: {
		width: '100%',
		padding: 16,
	},
	listWrapper: {
		width: '100%',
		marginTop: 8,
		paddingBottom: 20,
	},
	loaderWrapper: {
		height: '80%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	ribbon: {
		backgroundColor: 'FAFAFA',
	},
	ribbonWrapper: {
		position: 'absolute',
		zIndex: 1,
		top: 10,
		left: -10,
		backgroundColor: 'FAFAFA',
	},
	EmptyWrapper: {
		height: '80%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	input: {
		height: 50,
		width: '50%',
		backgroundColor: '#FFFFFF',
		borderWidth: 1.02,
		borderColor: '#DFE0EB',
		borderStyle: 'solid',
	},
	dropDownWrapper: {
		paddingLeft: 16,
		width: '100%',
		paddingTop: 8,
		zIndex: 1,
	},
	playIcon: {
		position: 'absolute',
		left: 24,
		top: 25,
	},
	placeholder: {
		color: '#A7A7A7',
	},
	dropdownContainer: {
		borderColor: '#A7A7A7',
		width: '50%',
	},
});
