import { Image, StyleSheet } from 'react-native';

//constant
import { PRIMARY_COLOR } from '../common/constants/Colors';

import { View } from './Themed';

export default function Header({ center = false }) {
	return (
		<View style={[styles.container, center && styles.center]}>
			<Image style={styles.logo} source={require('../../assets/images/icon.png')} />
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: PRIMARY_COLOR,
		width: '100%',
		paddingTop: 50,
		paddingHorizontal: 16,
		paddingBottom: 16,
	},
	logo: {
		width: 64,
		height: 34,
	},
	center: {
		alignItems: 'center',
	},
});
