import { StyleSheet } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import moment from 'moment';

import { FALLING, RISING, STEADY } from '../../common/constants/Constants';
//constants
import { FONT_BOLD, FONT_LIGHT, FONT_SEMI_BOLD } from '../../common/constants/Fonts';
import { Text, View } from '../Themed';

interface Details {
	today: {
		water_discharge_data: string;
		water_discharge_date: string;
	};
	yesterday: {
		water_discharge_data: string;
		water_discharge_date: string;
	};
	trend: string;
}

interface Props {
	waterDischargeData?: Details;
}

export default function WaterDischage({ waterDischargeData }: Props) {
	const t = (date: string) => {
		const currDate = moment(date).format('DD-M-YYYY');
		const currTime = moment(date).format('Hmm');

		return (
			<Text style={StyleSheet.flatten([styles.lightText, { fontSize: 11 }])}>
				last recorded at {currTime} hrs of {currDate}
			</Text>
		);
	};

	const dateFormat = (date: string) => {
		let day = date.split(' ')[0];
		const check = day.split('-');
		return [check[2], check[1], check[0]].join('-');
	};

	return (
		<>
			<View style={styles.container}>
				{waterDischargeData === undefined ? (
					<View style={styles.empty}>
						<Text style={styles.emptyText}>No data found</Text>
					</View>
				) : (
					<>
						<Text style={styles.lightGreyText}>
							{t(waterDischargeData?.today?.water_discharge_date)}
						</Text>
						<View style={styles.innerCard}>
							<View
								style={StyleSheet.flatten([
									styles.flexWrapper,
									{ justifyContent: 'flex-start' },
								])}
							>
								<View style={styles.WrapperSmall}>
									<Text style={styles.semiBoldText}>
										{waterDischargeData &&
											waterDischargeData?.today &&
											waterDischargeData?.today?.water_discharge_date &&
											dateFormat(
												waterDischargeData?.today?.water_discharge_date
											)}
									</Text>

									<Text
										style={StyleSheet.flatten([
											styles.bigText,
											{ marginTop: 2, marginLeft: 3 },
										])}
									>
										{waterDischargeData?.today.water_discharge_data} cusec
									</Text>
								</View>
								<View style={styles.divider} />
								<View style={styles.WrapperSmall}>
									<Text style={styles.lightGreyText}>
										{waterDischargeData &&
											waterDischargeData?.yesterday &&
											waterDischargeData?.yesterday?.water_discharge_date &&
											dateFormat(
												waterDischargeData?.yesterday?.water_discharge_date
											)}
									</Text>
									<Text
										style={StyleSheet.flatten([
											styles.lightGreyText,
											{ marginTop: 2 },
										])}
									>
										{waterDischargeData?.yesterday.water_discharge_data} cusec
									</Text>
								</View>
								<View style={styles.divider} />

								<View
									style={StyleSheet.flatten([
										styles.WrapperSmall,
										{ flexShrink: 1 },
									])}
								>
									<Text style={styles.lightText}>Trend</Text>
									<View style={styles.innerWrapper}>
										<FontAwesome5
											name="dot-circle"
											size={11}
											color={
												waterDischargeData && waterDischargeData?.trend
													? waterDischargeData?.trend === FALLING
														? '#31B231'
														: waterDischargeData?.trend === RISING
														? '#D20000'
														: '#E0A800'
													: '#E0A800'
											}
										/>
										<Text
											style={StyleSheet.flatten([
												styles.bigText,
												{
													fontSize: 12,
													marginTop: 2,
													marginLeft: 3,
													textTransform: 'capitalize',
													color:
														waterDischargeData &&
														waterDischargeData?.trend
															? waterDischargeData?.trend === FALLING
																? '#31B231'
																: waterDischargeData?.trend ===
																  RISING
																? '#CE8601'
																: waterDischargeData?.trend ===
																  STEADY
																? '#CFB101'
																: '#D73030'
															: '#E0A800',
												},
											])}
										>
											{waterDischargeData &&
												waterDischargeData.trend &&
												waterDischargeData.trend.charAt(0).toUpperCase() +
													waterDischargeData.trend.slice(1).toLowerCase()}
										</Text>
									</View>
								</View>
							</View>
						</View>
					</>
				)}
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		borderRadius: 4,
		marginTop: 3,
	},

	flexWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		backgroundColor: '#F5F7FB',
	},
	innerWrapper: {
		flexDirection: 'row',
		alignItems: 'baseline',
		backgroundColor: '#F5F7FB',
	},
	WrapperSmall: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
		backgroundColor: '#F5F7FB',
		height: 20,
	},
	divider: {
		height: 50,
		width: 1,
		backgroundColor: '#DFE0EB',
		marginHorizontal: 18,
	},
	innerCard: {
		padding: 8,
		backgroundColor: '#F5F7FB',
		width: '100%',
		marginTop: 8,
		borderRadius: 4,
	},
	semiBoldText: {
		fontFamily: FONT_SEMI_BOLD,
		fontSize: 11,
	},
	bigText: {
		fontFamily: FONT_SEMI_BOLD,
		fontSize: 15,
		color: '#346499',
	},
	lightText: {
		fontFamily: FONT_LIGHT,
		fontSize: 11,
	},
	lightGreyText: {
		fontFamily: FONT_LIGHT,
		fontSize: 11,
		color: '#55575A',
	},
	empty: {
		padding: 8,
		backgroundColor: '#F5F7FB',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		marginTop: 10,
		borderRadius: 4,
		height: 100,
	},
	emptyText: {
		fontFamily: FONT_BOLD,
		fontSize: 18,
		color: '#346499',
		marginLeft: 6,
	},
});
