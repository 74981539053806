import Svg, { Path, SvgProps } from 'react-native-svg';

function VeryHeavyRainfall(props: SvgProps) {
	return (
		<Svg width={13} height={13} viewBox="0 0 13 13" fill="none" {...props}>
			<Path
				d="M2.155 8.96a.406.406 0 01.257.513l-.406 1.219a.407.407 0 11-.77-.257l.406-1.219a.406.406 0 01.513-.256zm7.72 0a.406.406 0 01.257.513l-.406 1.219a.406.406 0 01-.77-.257l.406-1.219a.406.406 0 01.513-.256zm-6.094 1.218a.406.406 0 01.256.514l-.406 1.219a.407.407 0 11-.77-.257l.406-1.219a.407.407 0 01.514-.257zm7.72 0a.406.406 0 01.256.514l-.406 1.219a.407.407 0 01-.77-.257l.406-1.219a.407.407 0 01.513-.257zM5.726 9.163a.406.406 0 01.363-.225h.813a.406.406 0 01.385.535l-.228.684h.655a.407.407 0 01.317.66L6.407 12.85a.405.405 0 01-.711-.352l.28-1.12h-.699a.406.406 0 01-.363-.59l.813-1.624zm5.162-5.89a4.064 4.064 0 00-7.72-.817 2.844 2.844 0 10-.33 5.67h7.72a2.438 2.438 0 00.33-4.854z"
				fill="#D73030"
			/>
		</Svg>
	);
}

export default VeryHeavyRainfall;
