/* eslint-disable react/no-unstable-nested-components */
import { useCallback, useRef, useState } from 'react';
import {
	Alert,
	Pressable,
	ScrollView,
	StyleProp,
	StyleSheet,
	Text,
	TextStyle,
	useWindowDimensions,
	View,
	ViewStyle,
} from 'react-native';
import {
	NavigationState,
	Route,
	SceneMap,
	SceneRendererProps,
	TabBar,
	TabBarIndicatorProps,
	TabBarItemProps,
	TabView,
} from 'react-native-tab-view';
import { Event, Scene } from 'react-native-tab-view/lib/typescript/types';
import { useDispatch, useSelector } from 'react-redux';
import { AntDesign } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';

//constants
import Colors, { PRIMARY_COLOR } from '../../common/constants/Colors';
import { DA } from '../../common/constants/Constants';
import { FONT_BOLD, FONT_NORMAL } from '../../common/constants/Fonts';
import useAuth from '../../common/hooks/useAuth';
import axios from '../../common/utils/axios';
import { locationDetails } from '../../redux/slices/location';
import { locationLabelDetails } from '../../redux/slices/locationLabel';

import AdminIncidentTabs from './AdminIncidentTabs';
import AlertCard from './AlertNotifications';
import DistrictMap from './DistrictMap';
import DropdownModal from './DropdownModal';
import IncidentCard, { InicidentProps } from './IncidentCard';
import NepalNews from './NepalNews';
import RainfallData from './RainfallData';
import WaterDischage from './WaterDischage';
import WaterLevel from './WaterLevel';
import Weather from './Weather';

export type AlertProps = {
	alertArea: [
		{
			block: null | string;
			created_at: string;
			district: string;
			id: number;
			river: string;
			update_remarks: null | string;
			updated_at: string;
			village: string;
		}
	];
	alerttype: string;
	created_at: string;
	description: string;
	id: number;
	water_discharge: string;
	rainfall: string;
	severity: string;
	status: string;
	title: string;
	today_fl: boolean;
	updated_at: string;
	water_level: string;
	trend: string;
};

export type RouteType = {
	key: string;
	title: string;
};

export interface WaterLevelDetails {
	CurrentWaterLevel: {
		downstream_water_level: string;
		upstream_water_level: string;
		water_level_date: string;
	};
	YesterdayWaterLevel: {
		downstream_water_level: string;
		upstream_water_level: string;
		water_level_date: string;
	};
	danger_level: string;
	secondLastWaterLevel: number;
	trend: string;
}

export interface WaterDichargeDetails {
	today: {
		water_discharge_data: string;
		water_discharge_date: string;
	};
	yesterday: {
		water_discharge_data: string;
		water_discharge_date: string;
	};
	trend: string;
}

const ROUTE_HEIGHT = 100;

export default function OfficialDashboard({ navigation }: { navigation: any }) {
	const [alerts, setAlerts] = useState<any>({});
	const isMounted = useRef(false);
	const [isOpen, setOpen] = useState<boolean>(false);
	const { user } = useAuth();
	const [routes, setRoutes] = useState<RouteType[]>([]);
	const [incidents, setincidents] = useState<InicidentProps[]>([]);

	const [waterLevelDetails, setWaterLevelDetails] = useState<WaterLevelDetails | undefined>();
	const [waterDischargeDetails, setWaterDischargeDetails] = useState<
		WaterDichargeDetails | undefined
	>();

	const [waterLevelRoutes, setWaterLevelRoutes] = useState<RouteType[]>([]);
	const [river, setRiver] = useState('');

	const {
		zoneId,
		circleId,
		divisionId,
		sub_divisionId,
		districtId,
		blockId,
		river_id,
		riverType,
		riverName,
		refreshLocation,
	} = useSelector((state: any) => state.location.value);
	const { village } = useSelector((state: any) => state.locationLabel.value);

	const dispatch = useDispatch();

	const [index, setIndex] = useState(0);

	const layout = useWindowDimensions();

	const getAlertTypes = async () => {
		try {
			await axios.get('/api/alerts/alert-type').then((response) => {
				if (!isMounted.current) {
					const { data } = response.data;
					if (data.length !== 0) {
						setRoutes(data);
						getAlerts(data[0].key);
					} else {
						setRoutes([]);
						setAlerts([]);
					}
					setIndex(0);
				}
			});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	useFocusEffect(
		useCallback(() => {
			if (!districtId) {
				if (user && user.defaultjurisdiction && user.defaultjurisdiction.district) {
					const data = user.defaultjurisdiction;
					dispatch(
						locationDetails({
							zoneId: data.zone && data.zone.value,
							circleId: data.circle && data.circle.value,
							divisionId: data.division && data.division.value,
							sub_divisionId: data.sub_division && data.sub_division.value,
							districtId: data.district && data.district.value,
							blockId: data.block && data.block.value,
							river_id: data.river && data.river.value,
							riverName: data.river && data.river.label,
							riverType: data.type && data.type,
							refreshLocation: !refreshLocation,
						})
					);
					dispatch(
						locationLabelDetails({
							district: data.district && data.district.label,
							block: data.block && data.block.label,
							village: village,
						})
					);
				} else {
					dispatch(
						locationDetails({
							zoneId: zoneId,
							circleId: circleId,
							divisionId: divisionId,
							sub_divisionId: sub_divisionId,
							districtId: districtId,
							blockId: blockId,
							river_id: river_id,
							riverName: riverName,
							riverType: riverType,
							refreshLocation: false,
						})
					);
					getAlertTypes();
					if (river_id && river_id !== '' && riverType && riverType !== '') {
						getBarrage();
					}
				}
			} else {
				getAlertTypes();
				if (river_id && river_id !== '' && riverType && riverType !== '') {
					getBarrage();
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [refreshLocation])
	);

	useFocusEffect(
		useCallback(() => {
			const getIncidentDetails = async () => {
				try {
					await axios
						.post('/api/dashboard/incident-dashboard', {
							params: { page: 1 },
							type: 'all',
							limit: '1',
						})
						.then((response) => {
							const { data } = response.data;
							if (data) {
								setincidents(data);
							}
						});
				} catch (error: any) {
					Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
						{
							text: 'Cancel',
							style: 'cancel',
						},
						{
							text: 'OK',
						},
					]);
				}
			};
			if (user && user.designation.label !== DA) {
				getIncidentDetails();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	const getAlerts = async (id: string) => {
		const alertType = String(id);
		try {
			await axios
				.post('/api/dashboard/alert-dashboard', {
					alert_type: alertType,
					limit: '1',
					block_id: blockId && blockId.toString(),
					district_id: districtId && districtId.toString(),
				})
				.then((response) => {
					if (!isMounted.current) {
						const { data } = response.data;
						if (data.length !== 0) {
							setAlerts(data);
						} else {
							setAlerts([]);
						}
					}
				});
		} catch (error: any) {
			setAlerts({});
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const getBarrage = async () => {
		if (river_id && river_id !== '' && riverType && riverType !== '') {
			const params = {
				river_id: river_id && river_id.toString(),
				type: riverType && riverType,
			};

			try {
				await axios
					.get('/api/dashboard/getbarrage', {
						params,
					})
					.then((response) => {
						if (!isMounted.current) {
							const { data } = response.data;

							if (Object.keys(data).length !== 0) {
								if (data[riverType].length > 0) {
									setRiver(data.river);
									setWaterLevelRoutes(data[riverType]);
									getWaterLevel(data[riverType][0].key);
								} else {
									setWaterLevelRoutes([]);
									setWaterDischargeDetails(undefined);
									setWaterLevelDetails(undefined);
								}
							} else {
								setWaterLevelRoutes([]);
								setWaterDischargeDetails(undefined);
								setWaterLevelDetails(undefined);
							}
						}
					});
			} catch (error: any) {
				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			}
		}
	};

	const getWaterLevel = async (id: string) => {
		const params = {
			type_id: id,
			type: riverType && riverType,
			river_id: river_id,
		};
		try {
			await axios
				.get('/api/dashboard/waterlevel', {
					params,
				})
				.then((response) => {
					if (!isMounted.current) {
						const { data } = response.data;
						if (Object.keys(data).length) {
							if (data.water_discharge.length !== 0) {
								setWaterDischargeDetails(data.water_discharge);
							} else {
								setWaterDischargeDetails(undefined);
							}
							setWaterLevelDetails(data.water_level);
						} else {
							setWaterLevelDetails(undefined);
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const AllRoute = () => (
		<View style={{ height: ROUTE_HEIGHT }}>
			{alerts && alerts[0]?.alerttype ? (
				<AlertCard alert={alerts[0]} />
			) : (
				<View style={styles.errorContainer}>
					<Text style={styles.emptyText}>No alerts found</Text>
				</View>
			)}
		</View>
	);

	const getSceneMap = () => {
		let sceneMap: any = {};
		routes.map((item: any, routeIndex) => {
			sceneMap[item.key] = () => {
				return <AllRoute key={routeIndex} />;
			};
		});
		return sceneMap;
	};
	const sceneRendering = SceneMap(getSceneMap());

	const renderTabBar = (
		props: JSX.IntrinsicAttributes &
			SceneRendererProps & {
				navigationState: NavigationState<Route>;
				scrollEnabled?: boolean | undefined;
				bounces?: boolean | undefined;
				activeColor?: string | undefined;
				inactiveColor?: string | undefined;
				pressColor?: string | undefined;
				pressOpacity?: number | undefined;
				getLabelText?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getAccessible?: ((scene: Scene<Route>) => boolean | undefined) | undefined;
				getAccessibilityLabel?: ((scene: Scene<Route>) => string | undefined) | undefined;
				getTestID?: ((scene: Scene<Route>) => string | undefined) | undefined;
				renderLabel?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderIcon?:
					| ((
							scene: Scene<Route> & {
								focused: boolean;
								color: string;
							}
					  ) => React.ReactNode)
					| undefined;
				renderBadge?: ((scene: Scene<Route>) => React.ReactNode) | undefined;
				renderIndicator?:
					| ((props: TabBarIndicatorProps<Route>) => React.ReactNode)
					| undefined;
				renderTabBarItem?:
					| ((
							props: TabBarItemProps<Route> & { key: string }
					  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
					| undefined;
				onTabPress?: ((scene: Scene<Route> & Event) => void) | undefined;
				onTabLongPress?: ((scene: Scene<Route>) => void) | undefined;
				tabStyle?: StyleProp<ViewStyle>;
				indicatorStyle?: StyleProp<ViewStyle>;
				indicatorContainerStyle?: StyleProp<ViewStyle>;
				labelStyle?: StyleProp<TextStyle>;
				contentContainerStyle?: StyleProp<ViewStyle>;
				style?: StyleProp<ViewStyle>;
				gap?: number | undefined;
			}
	) => (
		<View>
			<TabBar
				{...props}
				indicatorStyle={{
					backgroundColor: PRIMARY_COLOR,
				}}
				scrollEnabled
				style={{
					backgroundColor: Colors.light.background,
				}}
				renderLabel={({ route, focused }) => {
					return (
						<Text
							style={{
								color: focused ? PRIMARY_COLOR : Colors.dark.background,
							}}
						>
							{route.title}
						</Text>
					);
				}}
			/>
		</View>
	);

	return (
		<ScrollView style={styles.mainContainer}>
			<View style={styles.mainContainer}>
				<View style={styles.welcomeBanner}>
					<Text>
						Welcome{' '}
						<Text style={styles.fontSemiBold}>
							{user && user.full_name && user.full_name !== '' && user.full_name}{' '}
							{user && user.designation && `, ${user.designation.label}`}
						</Text>
					</Text>
				</View>
				<View style={styles.jurisHead}>
					<Text style={styles.jurisHeading}>Jurisdiction Details</Text>
					<Pressable onPress={() => setOpen(true)}>
						<Text style={styles.chooseTxt}>Edit</Text>
					</Pressable>
				</View>
				<DropdownModal openState={isOpen} handleClose={() => setOpen(false)} />
				<View style={styles.alertContainer}>
					<View style={styles.flexWrapper}>
						<Text style={styles.title}>Alerts</Text>
						<Pressable
							style={styles.button}
							onPress={() => navigation.navigate('AlertTab')}
						>
							<Text style={styles.buttonText}>View all</Text>
							<AntDesign name="doubleright" size={15} color="#346499" />
						</Pressable>
					</View>
					<View style={styles.tabWrapper}>
						<TabView
							navigationState={{ index, routes }}
							renderScene={sceneRendering}
							onIndexChange={(currentIndex) => {
								setIndex(currentIndex);
								setAlerts({});
								getAlerts(routes[currentIndex].key);
							}}
							initialLayout={{ width: layout.width }}
							style={styles.tab}
							renderTabBar={renderTabBar}
						/>
					</View>
				</View>

				{user && user.designation.label === DA && (
					<AdminIncidentTabs navigation={navigation} />
				)}
				{/* {user !== DA && user !== USER && (
                    <OfficialIncidentTabs navigation={navigation} />
                )} */}
				{user && user.designation.label !== DA && incidents.length > 0 && (
					<View style={styles.incidentContainer}>
						<View style={[styles.flexWrapper, styles.topSpace]}>
							<Text style={styles.title}>Latest Incidents</Text>
							<Pressable
								style={styles.button}
								onPress={() => navigation.navigate('IncidentTab')}
							>
								<Text style={styles.buttonText}>View all</Text>
								<AntDesign name="doubleright" size={15} color="#346499" />
							</Pressable>
						</View>
						<IncidentCard
							title={incidents[0].title}
							status={incidents[0].status}
							assigned_by={''}
							assigned={''}
							reported_by={''}
							village={incidents[0].village}
							block={incidents[0].block}
							district={incidents[0].district}
							created_at={incidents[0].created_at}
							updated_at={incidents[0].updated_at}
						/>
					</View>
				)}

				<View style={styles.topSpace}>
					<NepalNews />
				</View>
				<View style={styles.wrapper}>
					<Text style={[styles.title, styles.topSpace]}>Your District Map</Text>
					<DistrictMap
						open={() => setOpen(true)}
						currRiver={river}
						waterLevelRoute={waterLevelRoutes}
					/>
					<Text style={[styles.title, styles.topSpaceInfo]}>Information Products</Text>

					<Text style={[styles.title, styles.topSpace]}>Rainfall Data</Text>
					<RainfallData open={() => setOpen(true)} />
					<View style={[styles.flexWrapper]}>
						<Text style={[styles.title, styles.topSpace]}>Water Level</Text>
						{waterLevelDetails === undefined ||
						Object.keys(waterLevelDetails).length === 0 ? (
							<Pressable onPress={() => setOpen(true)}>
								<Text style={styles.editText}>Edit Location Details</Text>
							</Pressable>
						) : null}
					</View>
					<WaterLevel
						currRiver={river}
						waterLevelRoute={waterLevelRoutes}
						waterLevelData={waterLevelDetails}
						onTabChange={(id: any) => {
							getWaterLevel(id);
						}}
						open={() => setOpen(true)}
					/>
					{riverType && riverType !== 'station' && (
						<>
							<Text style={[styles.title, styles.topSpace]}>Water Discharge</Text>
							<WaterDischage waterDischargeData={waterDischargeDetails} />
						</>
					)}
					<Text style={[styles.title, styles.topSpace]}>Weather</Text>
					<Weather open={() => setOpen(true)} />
				</View>
			</View>
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
		flexGrow: 1,
		backgroundColor: 'white',
		// paddingBottom: "75%",
		paddingBottom: 30,
	},
	title: {
		fontWeight: '700',
		color: PRIMARY_COLOR,
		fontSize: 18,
		lineHeight: 22,
	},
	topSpace: {
		marginTop: 20,
	},
	topSpaceInfo: {
		marginTop: 25,
	},
	button: {
		backgroundColor: 'transparent',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'row',
	},
	buttonText: {
		fontFamily: FONT_NORMAL,
		color: PRIMARY_COLOR,
		fontSize: 13,
		marginRight: 6,
	},
	//admin dashboard
	jurisHeading: {
		fontSize: 16,
		lineHeight: 20,
		color: PRIMARY_COLOR,
	},
	welcomeBanner: {
		backgroundColor: '#DFE0EB',
		padding: 12,
		marginBottom: 30,
	},
	jurisHead: {
		backgroundColor: '#F5F7FB',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 10,
	},
	chooseTxt: {
		textDecorationLine: 'underline',
		fontWeight: '600',
		lineHeight: 20,
		fontSize: 16,
	},
	editText: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: 'normal',
		color: PRIMARY_COLOR,
		textDecorationLine: 'underline',
	},
	alertContainer: {
		marginHorizontal: 16,
		marginVertical: 25,
		maxHeight: 190,
	},
	tabWrapper: {
		flexDirection: 'row',
		width: '100%',
		height: '100%',
	},
	tab: {
		marginTop: 10,
		height: 300,
	},
	flexWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 5,
	},
	wrapper: {
		backgroundColor: 'white',
		paddingHorizontal: 16,
	},
	errorContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		padding: 8,
		backgroundColor: '#F5F7FB',
		marginTop: 10,
		borderRadius: 4,
	},
	emptyText: {
		fontFamily: FONT_BOLD,
		fontSize: 18,
		color: '#346499',
		marginLeft: 6,
	},
	fontSemiBold: {
		fontWeight: '600',
	},
	incidentContainer: {
		marginHorizontal: 16,
		marginTop: 25,
	},
});
