import { createSlice } from '@reduxjs/toolkit';

export const locationLabelSlice = createSlice({
	name: 'locationLabel',
	initialState: {
		value: {
			district: '',
			block: '',
			village: '',
		},
	},
	reducers: {
		locationLabelDetails: (state, action) => {
			state.value = action.payload;
		},
	},
});
export const { locationLabelDetails } = locationLabelSlice.actions;

export default locationLabelSlice.reducer;
