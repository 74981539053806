import { Image, Pressable, StyleSheet, Text, View } from 'react-native';

import PCRequest from '../../../../assets/images/svg/PCRequest';
//constants
import { PRIMARY_COLOR } from '../../../common/constants/Colors';
import { FONT_NORMAL } from '../../../common/constants/Fonts';
//components
import Header from '../../../components/OnboardingHeader';

export default function CLoad({ navigation }: any) {
	return (
		<View style={styles.container}>
			<Header />
			<View style={styles.imageWrapper}>
				<PCRequest />
			</View>
			<View style={styles.content}>
				<View style={styles.load}>
					<Image source={require('../../../../assets/images/Correct.png')} />
					<Text style={styles.text1}>
						Your Request to access FMISC Portal has been sent to the officials for
						verification
					</Text>
					<Text style={styles.text2}>Please check again in a while.</Text>
					<Pressable
						onPress={() => navigation.navigate('Identify')}
						style={styles.actionButton}
					>
						<Text style={styles.actionButtonText}>Go to home</Text>
					</Pressable>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: '#fff',
		flex: 1,
	},
	content: {
		alignItems: 'center',
		marginTop: 50,
	},
	actionButton: {
		alignItems: 'center',
		marginTop: 14,
		paddingVertical: 10,
		borderRadius: 4,
		elevation: 3,
		backgroundColor: '#fff',
		width: '48%',
	},
	actionButtonText: {
		fontWeight: '700',
		fontSize: 18,
		color: '#346499',
	},
	load: {
		backgroundColor: PRIMARY_COLOR,
		borderRadius: 4,
		width: '85%',
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 50,
		paddingHorizontal: 25,
	},
	text1: {
		fontFamily: FONT_NORMAL,
		textAlign: 'center',
		lineHeight: 28,
		color: '#fff',
		fontSize: 22,
		padding: 30,
		fontWeight: '700',
	},
	text2: {
		color: '#fff',
		fontSize: 15,
		fontWeight: '600',
	},
	imageWrapper: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 20,
		backgroundColor: 'white',
		height: 90,
	},
});
