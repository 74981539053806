/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, BackHandler, Linking, Platform, StyleSheet, ToastAndroid } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import axios from 'axios';
import * as Location from 'expo-location';

import { COMMON } from '../../common/constants/Constants';
import CommonDashboard from '../../components/dashboard/CommonDashboard';
import OfficialDashboard from '../../components/dashboard/OfficialDashboard';
//components
import ProfileHeader from '../../components/ProfileHeader';
import { currentLocation } from '../../redux/slices/currentLocation';
import { useDispatch, useSelector } from '../../redux/store';

export type AlertProps = {
	id: number;
	alerttype: string;
	today_fl: boolean;
	title: string;
	description: string;
	target: string;
	trend: string;
	severity: string;
	water_discharge: string;
	water_level: string;
	rainfall: string;
	alertArea: [
		{
			id: number;
			district: string;
			block: string;
			village: string;
			created_at: string;
			updated_at: string;
			river: {
				id: string;
				name: string;
			};
		}
	];
	created_at: string;
	updated_at: string;
};

export default function HomeTabScreen({ navigation }: { navigation: any; route: any }) {
	const isMounted = useRef(false);
	const [location, setLocation] = useState<any | null>(null);
	const [locationFlag, setLocationFlag] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [loadingFlag, setLoadingFlag] = useState<boolean>(false);
	const AuthDetails = useSelector((state) => state.auth.value);
	const CurrentLocation = useSelector((state) => state.currentLocation.value);
	const dispatch = useDispatch();

	// const { user } = useAuth();
	// console.log("user", user)

	// const allowLocation = async () => {
	// let { status, canAskAgain } =
	//     await Location.requestForegroundPermissionsAsync();
	// if (status !== "granted") {
	// setLocationFlag(true);
	// setErrorMsg("Permission to access location was denied");
	// return;
	// }
	// setLocationFlag(false);
	// setLoadingFlag(true);
	// let location = await Location.getCurrentPositionAsync({});
	// setLoadingFlag(false);
	// setLocation(location);
	// const place = await Location.reverseGeocodeAsync({
	//     latitude: location.coords.latitude,
	//     longitude: location.coords.longitude,
	// });
	// const myApiKey = "AIzaSyAat1mdIZ7aXLqz6PxyE-LVPGn9HlQgQjs";
	// try {
	//     const test = await axios.get(
	//         "https://maps.googleapis.com/maps/api/place/details/json?latlng=" +
	//             location.coords.latitude +
	//             "," +
	//             location.coords.longitude +
	//             "&key=" +
	//             myApiKey
	//     );
	//     console.log("test", test);
	// } catch (error: any) {
	//     console.log("error", error);
	// }
	// await AsyncStorage.multiSet([
	//     [
	//         "latitude",
	//         location.coords.latitude
	//             ? String(location.coords.latitude)
	//             : "",
	//     ],
	//     [
	//         "longitude",
	//         location.coords.longitude
	//             ? String(location.coords.longitude)
	//             : "",
	//     ],
	//     ["country", place[0].country ? String(place[0].country) : ""],
	//     ["city", place[0].city ? String(place[0].city) : ""],
	//     ["district", place[0].district ? String(place[0].district) : ""],
	//     ["name", place[0].name ? String(place[0].name) : ""],
	//     [
	//         "postalCode",
	//         place[0].postalCode ? String(place[0].postalCode) : "",
	//     ],
	//     ["region", place[0].region ? String(place[0].region) : ""],
	//     ["subregion", place[0].subregion ? String(place[0].subregion) : ""],
	//     ["timezone", place[0].timezone ? String(place[0].timezone) : ""],
	// ]);
	// console.log("location", location);
	// }
	// };

	useFocusEffect(() => {
		const backAction = () => {
			ToastAndroid.show('Back is not allowed on this screen', ToastAndroid.SHORT);
			return true;
		};
		BackHandler.addEventListener('hardwareBackPress', backAction);
		return () => BackHandler.removeEventListener('hardwareBackPress', backAction);
	});

	// current location for both ios and android
	// const allowLocation = async () => {
	//     const {status, canAskAgain} =
	//         await Location.requestForegroundPermissionsAsync();
	//     if (status !== "granted") {

	//         if(CurrentLocation.granted){
	//             Alert.alert('Permission Denied!', 'Please grant permission to access the current location', [
	//                 {
	//                     text: 'Cancel',
	//                     style: 'cancel',
	//                     onPress:()=>{
	//                         dispatch(currentLocation({
	//                             granted:false
	//                         }))
	//                         setLocationFlag(true);
	//                         setErrorMsg("Permission to access location was denied");
	//                         return;
	//                     }
	//                 },
	//                 {
	//                     text: 'Open Settings',
	//                     onPress:async()=> {
	//                         Platform.OS==='ios' ?
	//                         Linking.openURL('app-settings:')
	//                         : Linking.openSettings();
	//                     }
	//                 },
	//             ]);
	//         }else{
	//             setLocationFlag(true);
	//             setErrorMsg("Permission to access location was denied");
	//             return;
	//         }
	//     }
	//     setLocationFlag(false);
	//     setLoadingFlag(true);
	//     let location = await Location.getCurrentPositionAsync().catch((error)=>{
	//     })
	//     if(location && location.coords){
	//     setLoadingFlag(false);
	//     setLocation(location);
	//     const myApiKey = "AIzaSyAat1mdIZ7aXLqz6PxyE-LVPGn9HlQgQjs";
	//          try {
	//         const response = await axios.get(
	//             "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
	//             location.coords.latitude +
	//             "," +
	//             location.coords.longitude +
	//             "&key=" +
	//             myApiKey
	//             );
	// 			if(response.data && response.data.results && response.data.results.length!==0){
	// 				const data= response.data.results.filter((val:any)=>{ return val?.types[0]=== "locality" && val?.types[1]==="political"});

	// 			}
	//         } catch (error: any) {
	//         }
	//         // await AsyncStorage.multiSet([
	//         //         [
	//         //                 "latitude",
	//         //                 location.coords.latitude
	//         //                     ? String(location.coords.latitude)
	//         //                     : "",
	//         //             ],
	//         //             [
	//         //                     "longitude",
	//         //                     location.coords.longitude
	//         //                         ? String(location.coords.longitude)
	//         //                         : "",
	//         //                 ],
	//         //                 ["country", place[0].country ? String(place[0].country) : ""],
	//         //                 ["city", place[0].city ? String(place[0].city) : ""],
	//         //                 ["district", place[0].district ? String(place[0].district) : ""],
	//         //                 ["name", place[0].name ? String(place[0].name) : ""],
	//         //                 [
	//         //                         "postalCode",
	//         //                         place[0].postalCode ? String(place[0].postalCode) : "",
	//         //                     ],
	//         //                     ["region", place[0].region ? String(place[0].region) : ""],
	//         //                     ["subregion", place[0].subregion ? String(place[0].subregion) : ""],
	//         //                     ["timezone", place[0].timezone ? String(place[0].timezone) : ""],
	//         //                 ]);

	//         //                 console.log("location", location);
	//                     }

	//                 }

	// useEffect(() => {
	//         allowLocation();
	//     },
	//     []
	// );

	useEffect(
		() => () => {
			isMounted.current = true;
		},
		[]
	);

	return (
		// <>
		//     {loadingFlag ? (
		//         <View style={styles.loadingWrapper}>
		//             <ActivityIndicator color={PRIMARY_COLOR} size="large" />
		//             <Text>Fetching location...</Text>
		//         </View>
		//     ) : (
		<>
			<ProfileHeader navigation={navigation} />
			{AuthDetails.userType === COMMON ? (
				<CommonDashboard navigation={navigation} />
			) : (
				<OfficialDashboard navigation={navigation} />
			)}
		</>
		//     )}
		// </>
	);
}

const styles = StyleSheet.create({
	loadingWrapper: {
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
});
