import { createContext, ReactNode, useEffect, useReducer } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import mime from 'mime';

// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

enum Types {
	Initial = 'INITIALIZE',
	Logout = 'LOGOUT',
	VerifyUser = 'VERIFYUSER',
}

type JWTAuthPayload = {
	[Types.Initial]: {
		isAuthenticated: boolean;
		user: AuthUser;
	};

	[Types.Logout]: undefined;

	[Types.VerifyUser]: {
		user: AuthUser;
	};
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
	switch (action.type) {
		case 'INITIALIZE':
			return {
				isAuthenticated: action.payload.isAuthenticated,
				isInitialized: true,
				user: action.payload.user,
			};
		case 'LOGOUT':
			return {
				...state,
				isAuthenticated: false,
				user: null,
			};

		case 'VERIFYUSER':
			return {
				...state,
				isAuthenticated: true,
				user: action.payload.user,
			};
		default:
			return state;
	}
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
	children: ReactNode;
};
function AuthProvider({ children }: AuthProviderProps) {
	const [state, dispatch] = useReducer(JWTReducer, initialState);

	useEffect(() => {
		const initialize = async () => {
			try {
				const api_token = await AsyncStorage.getItem('api_token');

				if (api_token && isValidToken(api_token)) {
					setSession(api_token);
					setTimeout(async () => {
						const response = await axios.get('/api/user/profile');
						const { data } = response.data;
						dispatch({
							type: Types.Initial,
							payload: {
								isAuthenticated: true,
								user: data,
							},
						});
					}, 1);
				} else {
					dispatch({
						type: Types.Initial,
						payload: {
							isAuthenticated: false,
							user: null,
						},
					});
				}
			} catch (err) {
				console.error(err);
				dispatch({
					type: Types.Initial,
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		};

		initialize();
	}, []);

	const verifyphoneotp = async (
		account_type: string,
		account_sub_type: string,
		action: string,
		phone_number: string,
		otp: string
	) => {
		const response = await axios.post('/api/auth/verify-otp', {
			account_type,
			account_sub_type,
			action,
			phone_number,
			otp,
		});
		const { data } = response.data;
		setSession(data.api_token);

		dispatch({
			type: Types.VerifyUser,
			payload: {
				user: data.user,
			},
		});
	};

	const signup = async (
		account_type: string,
		account_sub_type: string,
		action: string,
		phone_number: string,
		email: string,
		full_name: string,
		imageFlag: boolean,
		image_uri: any
	) => {
		const profileForm = new FormData();
		if (imageFlag) {
			profileForm.append('image', {
				uri: image_uri,
				type: mime.getType(image_uri),
				name: image_uri.split('/').pop(),
			} as any);
		}

		profileForm.append('account_type', account_type);
		profileForm.append('account_sub_type', account_sub_type);
		profileForm.append('action', action);
		profileForm.append('full_name', full_name);
		profileForm.append('phone_number', phone_number);
		profileForm.append('email', email);
		const response = await axios.post('/api/auth/signup', profileForm, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		const { data } = response.data;
		setSession(data.api_token);

		dispatch({
			type: Types.VerifyUser,
			payload: {
				user: data.user,
			},
		});
	};

	const emailLogin = async (email: string, password: string) => {
		const response = await axios.post('/api/auth/email-login', {
			email,
			password,
		});
		const { data } = response.data;
		setSession(data.api_token);

		dispatch({
			type: Types.VerifyUser,
			payload: {
				user: data.user,
			},
		});
	};

	const profileData = async () => {
		const response = await axios.get('/api/user/profile');
		const { data } = response.data;
		dispatch({
			type: Types.Initial,
			payload: {
				isAuthenticated: true,
				user: data,
			},
		});
	};

	const logout = async () => {
		setSession(null);
		dispatch({ type: Types.Logout });
		await AsyncStorage.multiRemove(['api_token', 'new_user', 'latitude']);
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'jwt',
				verifyphoneotp,
				emailLogin,
				profileData,
				signup,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
