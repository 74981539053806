export const MAPDATA = [
	// {
	//     id: 1,
	//     title: "Zone",
	//     type:'zone',
	// },
	{
		id: 2,
		title: 'District Magistrate',
		type: 'd_m',
	},
	{
		id: 3,
		title: 'Population',
		type: 'population',
	},
	{
		id: 4,
		title: 'Area',
		type: 'area_km',
	},
	{
		id: 5,
		title: 'Incidents Reported',
		type: 'reported_incident',
	},
	{
		id: 6,
		title: 'Rivers',
		type: 'river_count',
	},
];

export const MAPDATA2 = [
	{
		id: 1,
		title: 'Cities/Blocks',
		data: '23',
	},
	{
		id: 2,
		title: 'Sub-divisions',
		data: '06',
	},
	{
		id: 3,
		title: 'Villages',
		data: '1395',
	},
	{
		id: 4,
		title: 'Circles',
		data: '23',
	},
];

export const MAPTABDATA = [
	{
		id: 1,
		title: 'Water',
		title2: 'Level',
		data: '60.3',
	},
	{
		id: 2,
		title: 'Upstream',
		title2: 'Level',
		data: '24900 ft',
	},
	{
		id: 3,
		title: 'Downstream',
		title2: 'Level',
		data: '60.3',
	},
	{
		id: 4,
		title: 'Water',
		title2: 'Discharge',
		data: '1,63,910 m³/s',
	},
];

export const LOGIN = 'login';
export const REGISTER = 'signup';

export const OFFICIAL = 'official';
export const COMMON = 'user';
export const PERMANENT = 'permanent';
export const CONTRACT = 'contract';
export const PHONE = 'phone';
export const EMAIL = 'email';

export const roles = [
	{ label: 'Manager', value: 'manager' },
	{ label: 'Assistant', value: 'assisant' },
	{ label: 'Secretary', value: 'secretary' },
	{ label: 'Staff1', value: 'staff1' },
	{ label: 'Staff2', value: 'staff2' },
	{ label: 'Staff3', value: 'staff3' },
	{ label: 'Staff4', value: 'staff4' },
	{ label: 'Staff5', value: 'staff5' },
	{ label: 'Staff6', value: 'staff6' },
	{ label: 'Staff7', value: 'staff7' },
];

export const CE = 'Chief Engineer';
export const SE = 'Superintending Engineer';
export const JE = 'Junior Engineer';
export const EE = 'Executive Engineer';
export const AE = 'Assistant Engineer';
export const DA = 'Department Admin';
export const USER = 'User';
export const DM = 'District Magistrate';

export const DANGERRAINFALL = 'Extremely Heavy Rainfall';
export const VERYHEAVYRAINFALL = 'Very Heavy Rainfall';
export const HEAVYRAINFALL = 'Heavy Rainfall';
export const NORMALRAINFALL = 'Moderate Rainfall';
export const LIGHTRAINFALL = 'Light Rainfall';
export const VERYLIGHTRAINFALL = 'Very Light Rainfall';
export const MOSTLYCLOUDY = 'Mostly Cloudy';

export const RAINFALL = 'Rainfall';
export const WATERDISCHARGE = 'Water Discharge';
export const WATERLEVEL = 'Water Level';

export const RISING = 'rising';
export const STEADY = 'steady';
export const FALLING = 'falling';

export const BELOW_WARNING = 'below warning level';
export const ABOVE_WARNING = 'above warning level';
export const ABOVE_DANGER = 'above danger level';

export const WARNING = 'WARNING' || 'warning';
export const ALERT = 'ALERT';
export const WATCH = 'WATCH';
export const NOWARNING = 'NO WARNING';
export const DANGER = 'danger';
export const HFL = 'HFL';

export const INDIA = 'India';

export const STATION = 'station';

export const BARRAGE = 'barrage';

export const RIVER_TYPE = [
	{
		value: 'station',
		label: 'Station',
	},
	{
		value: 'barrage',
		label: 'Barrage',
	},
];
