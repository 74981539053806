import { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Alert, Pressable, StyleSheet, Text, View } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
//libraries
import mime from 'mime';

import PCJurisdictionDetails from '../../../../assets/images/svg/PCJurisdictionDetails';
//constant
import { AE, CE, EE, JE, SE } from '../../../common/constants/Constants';
import { sharedStyles } from '../../../common/constants/SharedStyles';
import axios from '../../../common/utils/axios';
//components
import Header from '../../../components/OnboardingHeader';
import { useSelector } from '../../../redux/store';

type Option = {
	label: string;
	value: number;
};

export default function CJurisdictionDetails({ navigation }: any) {
	const [openZone, setOpenZone] = useState(false);
	const [openCircle, setOpenCircle] = useState(false);
	const [openDivision, setOpenDivision] = useState(false);
	const [openSubDivision, setOpenSubDivision] = useState(false);
	const [loading, setLoading] = useState(false);
	const isMounted = useRef(false);
	const generalDetails = useSelector((state) => state.details.value);
	const AuthDetails = useSelector((state) => state.auth.value);
	const [showNext, setShowNext] = useState<boolean>(false);
	//zone
	const [zoneList, setZoneList] = useState<Option[]>([]);
	const [zonePage, setZonePage] = useState<number>(1);
	const [zoneLastPage, setZoneLastPage] = useState<number>(1);
	const [zoneValue, setZoneValue] = useState<Option | any>(null);
	//Circle
	const [circleList, setCircleList] = useState<Option[]>([]);
	const [circlePage, setCirclePage] = useState<number>(1);
	const [circleLastPage, setCircleLastPage] = useState<number>(1);
	const [circleValue, setCircleValue] = useState<Option | any>(null);
	//Division
	const [divisionList, setDivisionList] = useState<Option[]>([]);
	const [divisionPage, setDivisionPage] = useState<number>(1);
	const [divisionLastPage, setDivisionLastPage] = useState<number>(1);
	const [divisionValue, setDivisionValue] = useState<Option | any>(null);
	// //Sub-Division
	const [subDivisionList, setSubDivisionList] = useState<Option[]>([]);
	const [subDivisionPage, setSubDivisionPage] = useState<number>(1);
	const [subDivisionLastPage, setSubDivisionLastPage] = useState<number>(1);
	const [subDivisionValue, setSubDivisionValue] = useState<Option | any>(null);
	//section
	const [_sectionPage, setSectionPage] = useState<number>(1);

	const getCircleList = async (refresh: boolean) => {
		if (refresh) {
			setCirclePage(1);
		}
		if ((refresh && circlePage > 1) || !zoneValue || zoneValue === '') {
			return;
		}
		setDivisionPage(1);
		setSubDivisionPage(1);
		setSectionPage(1);
		setCircleValue(null);
		setCircleList([]);
		setDivisionList([]);
		setDivisionValue(null);
		setSubDivisionList([]);
		setSubDivisionValue(null);
		// setSectionValue(null);
		// setSectionList([]);
		try {
			await axios
				.get('/api/preauth/circle', {
					params: {
						page: circlePage,
						zone_id: zoneValue.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setCircleLastPage(data.info.lastPage);
						if (circlePage === 1) {
							setCircleList(data.items);
						} else {
							setCircleList((prev) => [...prev, ...data.items]);
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	const getDivisionList = async (refresh: boolean) => {
		if (refresh) {
			setDivisionPage(1);
		}
		if ((refresh && divisionPage > 1) || !circleValue || circleValue === '') {
			return;
		}
		setSubDivisionPage(1);
		setSectionPage(1);
		setDivisionList([]);
		setDivisionValue(null);
		setSubDivisionList([]);
		setSubDivisionValue(null);
		// setSectionValue(null);
		// setSectionList([]);
		try {
			await axios
				.get('/api/preauth/division', {
					params: {
						page: divisionPage,
						circle_id: circleValue.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setDivisionLastPage(data.info.lastPage);
						if (divisionPage === 1) {
							setDivisionList(data.items);
						} else {
							setDivisionList((prev) => [...prev, ...data.items]);
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};
	const getSubDivisionList = async (refresh: boolean) => {
		if (refresh) {
			setSubDivisionPage(1);
		}
		if ((refresh && subDivisionPage > 1) || !divisionValue || divisionValue === '') {
			return;
		}
		setSectionPage(1);
		setSubDivisionList([]);
		setSubDivisionValue(null);
		// setSectionValue(null);
		// setSectionList([]);
		try {
			await axios
				.get('/api/preauth/sub-division', {
					params: {
						page: subDivisionPage,
						division_id: divisionValue.toString().split(' ')[0],
					},
				})
				.then((response) => {
					const { data } = response.data;
					if (data && data.items) {
						setSubDivisionLastPage(data.info.lastPage);
						if (subDivisionPage === 1) {
							setSubDivisionList(data.items);
						} else {
							setSubDivisionList((prev) => [...prev, ...data.items]);
						}
					}
				});
		} catch (error: any) {
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};
	// const getSectionList = async (refresh: boolean) => {
	//     if (refresh) {
	//         setSectionPage(1);
	//     }
	//     if (
	//         (refresh && sectionPage > 1) ||
	//         !subDivisionValue ||
	//         subDivisionValue === ""
	//     ) {
	//         return;
	//     }
	//     // setSectionValue(null);
	//     // setSectionList([]);
	//     try {
	//         await axios
	//             .get("/api/preauth/section", {
	//                 params: {
	//                     page: sectionPage,
	//                     subdivision_id: subDivisionValue
	//                         .toString()
	//                         .split(" ")[0],
	//                 },
	//             })
	//             .then((response) => {
	//                 const { data } = response.data;
	//                 if (data && data.items) {
	//                     setSectionLastPage(data.info.lastPage);
	//                     if (sectionPage === 1) {
	//                         setSectionList(data.items);
	//                     } else {
	//                         setSectionList((prev) => [...prev, ...data.items]);
	//                     }
	//                 }
	//             });
	//     } catch (error: any) {
	//         Alert.alert(
	//             "Alert!",
	//             error.message ? error.message : "Something went wrong!",
	//             [
	//                 {
	//                     text: "Cancel",
	//                     style: "cancel",
	//                 },
	//                 {
	//                     text: "OK",
	//                 },
	//             ]
	//         );
	//     }
	// };

	useEffect(() => {
		const getZoneList = async () => {
			setCirclePage(1);
			setDivisionPage(1);
			setSubDivisionPage(1);
			// setSectionPage(1);
			try {
				await axios
					.get('/api/preauth/zone-list', {
						params: { page: zonePage },
					})
					.then((response) => {
						if (!isMounted.current) {
							const { data } = response.data;
							if (data && data.items) {
								setZoneLastPage(data.info.lastPage);
								if (zonePage === 1) {
									setZoneList(data.items);
								} else {
									setZoneList((prev) => [...prev, ...data.items]);
								}
							}
						}
					});
			} catch (error: any) {
				Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
					{
						text: 'Cancel',
						style: 'cancel',
					},
					{
						text: 'OK',
					},
				]);
			}
		};
		if (zonePage <= zoneLastPage) {
			getZoneList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zonePage]);

	useEffect(() => {
		if (zoneValue !== null && circlePage <= circleLastPage) {
			getCircleList(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [circlePage]);

	useEffect(() => {
		if (circleValue !== null && divisionPage <= divisionLastPage) {
			getDivisionList(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [divisionPage]);

	useEffect(() => {
		if (divisionValue !== null && subDivisionPage <= subDivisionLastPage) {
			getSubDivisionList(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subDivisionPage]);

	// useEffect(() => {
	//     if (subDivisionValue !== null && sectionPage <= sectionLastPage) {
	//         getSectionList(false);
	//     }
	// }, [sectionPage, subDivisionValue]);

	useEffect(
		() => () => {
			isMounted.current = true;
		},
		[]
	);

	const onSubmit = async () => {
		try {
			setLoading(true);
			const profileForm = new FormData();
			if (generalDetails.image !== '') {
				const newImageUri = 'file:///' + generalDetails.image.split('file:/').join('');
				profileForm.append('profile_picture', {
					uri: newImageUri,
					type: mime.getType(newImageUri),
					name: newImageUri.split('/').pop(),
				} as any);
			}

			profileForm.append('account_type', AuthDetails.userType);
			profileForm.append('account_sub_type', AuthDetails.officialType);
			profileForm.append('action', AuthDetails.type);
			profileForm.append('full_name', generalDetails.fullName);
			profileForm.append('phone_number', AuthDetails.phoneValue);
			profileForm.append('designation_id', generalDetails.designation);
			profileForm.append('employee_code', generalDetails.employeecode);
			zoneValue && profileForm.append('zone_id', zoneValue);
			circleValue && profileForm.append('circle_id', circleValue);
			divisionValue && profileForm.append('division_id', divisionValue);
			subDivisionValue && profileForm.append('sub_division_id', subDivisionValue);
			// sectionValue && profileForm.append("section_id", sectionValue);
			await axios.post('/api/auth/signup', profileForm, {
				headers: { 'Content-Type': 'multipart/form-data' },
			});
			setLoading(false);
			navigation.navigate('CLoad');
		} catch (error: any) {
			setLoading(false);
			Alert.alert('Alert!', error.message ? error.message : 'Something went wrong!', [
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'OK',
				},
			]);
		}
	};

	return (
		<View style={styles.container}>
			<Header />
			<View style={styles.imageWrapper}>
				<PCJurisdictionDetails />
			</View>
			<View style={styles.form}>
				<Text style={styles.title1}>Jurisdiction Details</Text>
				{!showNext ? (
					<>
						<View style={StyleSheet.flatten([styles.dropDownWrapper, { zIndex: 10 }])}>
							<Text style={styles.title}>
								Zone
								<Text style={styles.textRed}>*</Text>
							</Text>
							<DropDownPicker
								listMode="FLATLIST"
								dropDownDirection="BOTTOM"
								style={sharedStyles.generalInput}
								open={openZone}
								items={zoneList}
								setOpen={setOpenZone}
								value={zoneValue}
								setValue={setZoneValue}
								setItems={setZoneList}
								zIndex={1000}
								zIndexInverse={3000}
								placeholder="Select"
								flatListProps={{
									onEndReached: () => {
										if (zonePage < zoneLastPage) {
											setZonePage(zonePage + 1);
										}
									},
									onEndReachedThreshold: 0.9,
									keyExtractor: (item, index) => index.toString(),
								}}
								onChangeValue={() => {
									if (generalDetails.designationLabel !== CE) {
										getCircleList(true);
									}
								}}
								placeholderStyle={styles.placeholder}
								dropDownContainerStyle={styles.dropdownContainer}
							/>
						</View>
						{generalDetails.designationLabel !== CE && (
							<View
								style={StyleSheet.flatten([styles.dropDownWrapper, { zIndex: 9 }])}
							>
								<Text style={styles.title}>
									Circle
									<Text style={styles.textRed}>*</Text>
								</Text>

								<DropDownPicker
									listMode="FLATLIST"
									dropDownDirection="BOTTOM"
									style={sharedStyles.generalInput}
									open={openCircle}
									value={circleValue}
									setValue={setCircleValue}
									items={circleList}
									setOpen={setOpenCircle}
									setItems={setCircleList}
									zIndex={1000}
									zIndexInverse={3000}
									placeholder="Select"
									flatListProps={{
										onEndReached: () => {
											if (circlePage < circleLastPage) {
												setCirclePage(circlePage + 1);
											}
										},
										onEndReachedThreshold: 0.9,
										keyExtractor: (item, index) => index.toString(),
									}}
									onChangeValue={() => {
										if (
											generalDetails.designationLabel !== CE &&
											generalDetails.designationLabel !== SE
										) {
											getDivisionList(true);
										}
									}}
									placeholderStyle={styles.placeholder}
									dropDownContainerStyle={styles.dropdownContainer}
								/>
							</View>
						)}
						{generalDetails.designationLabel !== CE &&
							generalDetails.designationLabel !== SE && (
								<View
									style={StyleSheet.flatten([
										styles.dropDownWrapper,
										{ zIndex: 8 },
									])}
								>
									<Text style={styles.title}>
										Division
										<Text style={styles.textRed}>*</Text>
									</Text>
									<DropDownPicker
										listMode="FLATLIST"
										dropDownDirection="BOTTOM"
										style={sharedStyles.generalInput}
										open={openDivision}
										value={divisionValue}
										setValue={setDivisionValue}
										items={divisionList}
										setOpen={setOpenDivision}
										setItems={setDivisionList}
										zIndex={1000}
										zIndexInverse={3000}
										placeholder="Select"
										placeholderStyle={styles.placeholder}
										flatListProps={{
											onEndReached: () => {
												if (divisionPage < divisionLastPage) {
													setDivisionPage(divisionPage + 1);
												}
											},
											onEndReachedThreshold: 0.9,
											keyExtractor: (item, index) => index.toString(),
										}}
										onChangeValue={() => {
											if (
												generalDetails.designationLabel !== CE &&
												generalDetails.designationLabel !== SE &&
												generalDetails.designationLabel !== EE
											) {
												getSubDivisionList(true);
											}
										}}
										dropDownContainerStyle={styles.dropdownContainer}
									/>
								</View>
							)}
					</>
				) : (
					<>
						{generalDetails.designationLabel !== CE &&
							generalDetails.designationLabel !== SE &&
							generalDetails.designationLabel !== EE && (
								<View
									style={StyleSheet.flatten([
										styles.dropDownWrapper,
										{ zIndex: 7 },
									])}
								>
									<Text style={styles.title}>
										Sub-division
										<Text style={styles.textRed}>*</Text>
									</Text>

									<DropDownPicker
										listMode="FLATLIST"
										dropDownDirection="BOTTOM"
										style={sharedStyles.generalInput}
										open={openSubDivision}
										value={subDivisionValue}
										setValue={setSubDivisionValue}
										items={subDivisionList}
										setOpen={setOpenSubDivision}
										setItems={setSubDivisionList}
										zIndex={1000}
										zIndexInverse={3000}
										placeholder="Select"
										flatListProps={{
											onEndReached: () => {
												if (subDivisionPage < subDivisionLastPage) {
													setSubDivisionPage(subDivisionPage + 1);
												}
											},
											onEndReachedThreshold: 0.9,
											keyExtractor: (item, index) => index.toString(),
										}}
										onChangeValue={() => {
											if (
												generalDetails.designationLabel !== CE &&
												generalDetails.designationLabel !== SE &&
												generalDetails.designationLabel !== EE &&
												generalDetails.designationLabel !== AE
											) {
												// getSectionList(true);
											}
										}}
										placeholderStyle={styles.placeholder}
										dropDownContainerStyle={styles.dropdownContainer}
									/>
								</View>
							)}
						{/* {generalDetails.designationLabel !== CE &&
                            generalDetails.designationLabel !== SE &&
                            generalDetails.designationLabel !== EE &&
                            generalDetails.designationLabel !== AE && (
                                <View
                                    style={[
                                        styles.dropDownWrapper,
                                        { zIndex: openSection ? 12 : 6 },
                                    ]}
                                >
                                    <Text style={styles.title}>
                                        Section
                                        <Text
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            *
                                        </Text>
                                    </Text>
                                    <DropDownPicker
                                        listMode="FLATLIST"
                                        style={sharedStyles.generalInput}
                                        open={openSection}
                                        value={sectionValue}
                                        setValue={setSectionValue}
                                        items={sectionList}
                                        setOpen={setOpenSection}
                                        setItems={setSectionList}
                                        zIndex={1000}
                                        zIndexInverse={3000}
                                        placeholder="Select"
                                        flatListProps={{
                                            onEndReached: () => {
                                                if (
                                                    sectionPage <
                                                    sectionLastPage
                                                ) {
                                                    setSectionPage(
                                                        sectionPage + 1
                                                    );
                                                }
                                            },
                                            onEndReachedThreshold: 0.9,
                                            keyExtractor: (item, index) =>
                                                index.toString(),
                                        }}
                                        placeholderStyle={{
                                            color: "#A7A7A7",
                                        }}
                                        dropDownContainerStyle={{
                                            borderColor: "#A7A7A7",
                                            zIndex: 54324,
                                        }}
                                    />
                                </View>
                            )} */}
					</>
				)}
			</View>
			<View style={[sharedStyles.btnWrapper, styles.btnWrapper]}>
				<Pressable
					onPress={() => {
						showNext ? setShowNext(false) : navigation.pop();
					}}
					style={sharedStyles.skipButton}
				>
					<Text style={sharedStyles.skipButtonText}>Back</Text>
				</Pressable>
				{!showNext &&
				(generalDetails.designationLabel === AE ||
					generalDetails.designationLabel === JE) ? (
					<Pressable
						onPress={() => setShowNext(true)}
						style={[sharedStyles.skipButton, sharedStyles.done]}
					>
						<Text style={[sharedStyles.skipButtonText, sharedStyles.doneText]}>
							Next
						</Text>
					</Pressable>
				) : !loading ? (
					<Pressable
						onPress={onSubmit}
						style={[sharedStyles.skipButton, sharedStyles.done]}
					>
						<Text style={[sharedStyles.skipButtonText, sharedStyles.doneText]}>
							Send Request
						</Text>
					</Pressable>
				) : (
					<View style={[sharedStyles.skipButton, sharedStyles.done]}>
						<ActivityIndicator animating={loading} color="white" />
					</View>
				)}
			</View>
		</View>
	);
}
const styles = StyleSheet.create({
	container: {
		backgroundColor: '#fff',
		flex: 1,
	},
	form: {
		flex: 1,
		paddingHorizontal: 16,
	},
	imageWrapper: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		paddingBottom: 20,
		backgroundColor: 'white',
		height: 90,
		paddingTop: 36,
	},
	dropDownWrapper: {
		paddingTop: 8,
	},
	title: {
		fontWeight: '600',
		fontSize: 16,
	},
	input: {
		height: 60,
		marginVertical: 8,
		backgroundColor: '#F5F7FB',
		borderRadius: 7,
		borderWidth: 0,
	},
	btnWrapper: {
		marginBottom: 36,
		paddingHorizontal: 16,
	},
	title1: {
		color: '#346499',
		fontSize: 18,
		fontWeight: '700',
		marginBottom: 20,
	},
	textRed: {
		color: 'red',
	},
	placeholder: {
		color: '#A7A7A7',
	},
	dropdownContainer: {
		borderColor: '#A7A7A7',
		zIndex: 54324,
	},
});
