import { useState } from 'react';
import { Image, Pressable, StyleSheet, Text } from 'react-native';

//constant
import { FONT_BOLD } from '../../../common/constants/Fonts';
import { sharedStyles } from '../../../common/constants/SharedStyles';
import Header from '../../../components/Header';
//components
import { View } from '../../../components/Themed';

export default function InfoScreen({ navigation }: { navigation: any }) {
	const handleSkip = () => {
		setShowNext(false);
		navigation.navigate('Identify');
	};
	const handleNext = () => {
		if (showNext === false) {
			setShowNext(true);
		} else {
			setShowNext(false);
			navigation.navigate('Identify');
		}
	};
	const [showNext, setShowNext] = useState<boolean>(false);

	return (
		<View style={styles.mainWrapper}>
			<Header center={true} />
			<View style={styles.container}>
				{!showNext ? (
					<Image
						style={styles.ministerImage}
						source={require('../../../../assets/images/minister.png')}
					/>
				) : (
					<View>
						<Image
							style={styles.loopImage}
							source={require('../../../../assets/images/loop.png')}
						/>
					</View>
				)}
				{!showNext ? (
					<Text style={styles.textStyle}>
						Flood Management Improvement Support Centre (FMISC) was created as a{' '}
						<Text style={styles.fontBold}>comprehensive flood information system</Text>{' '}
						to control and manage the flood prone areas of Bihar.
					</Text>
				) : (
					<>
						<Text
							style={StyleSheet.flatten([styles.textStyleWrapper, { marginTop: 16 }])}
						>
							This application aims at providing customised{' '}
							<Text style={styles.fontBold}>real time “call for action”</Text>{' '}
							information to various stakeholders for timely action planning.
						</Text>

						<Text
							style={StyleSheet.flatten([styles.textStyleWrapper, { marginTop: 22 }])}
						>
							This is also an effort to{' '}
							<Text style={styles.fontBold}>
								connect with the community to help strengthen flood management
							</Text>{' '}
							in targeted areas.
						</Text>
					</>
				)}
			</View>
			<View style={[sharedStyles.btnWrapper, styles.btnWrapper]}>
				<Pressable style={sharedStyles.skipButton} onPress={handleSkip}>
					<Text style={sharedStyles.skipButtonText}>Skip for now</Text>
				</Pressable>
				<Pressable
					style={[sharedStyles.skipButton, sharedStyles.done]}
					onPress={handleNext}
				>
					<Text style={[sharedStyles.skipButtonText, sharedStyles.doneText]}>Next</Text>
				</Pressable>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	mainWrapper: {
		flex: 1,
		flexDirection: 'column',
	},
	container: {
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		paddingTop: 16,
		flex: 4,
	},
	ministerImage: {
		// width: "100%",
		marginBottom: 30,
	},
	loopImage: {
		marginVertical: 20,
	},
	textStyle: {
		fontSize: 16,
		textAlign: 'center',
		lineHeight: 24,
		paddingHorizontal: 20,
	},
	textStyleWrapper: {
		fontSize: 16,
		textAlign: 'center',
		lineHeight: 24,
		paddingHorizontal: 30,
	},
	btnWrapper: {
		flex: 1,
		width: '100%',
		padding: 16,
		marginBottom: 20,
	},
	fontBold: {
		fontFamily: FONT_BOLD,
	},
});
