import Svg, { Path, SvgProps } from 'react-native-svg';

function HeavyRainfall(props: SvgProps) {
	return (
		<Svg width={14} height={13} viewBox="0 0 14 13" fill="none" {...props}>
			<Path
				d="M11.262 2.468a.106.106 0 01-.056-.06A3.68 3.68 0 007.761 0a3.67 3.67 0 00-2.748 1.24.107.107 0 01-.117.03 2.707 2.707 0 00-.91-.16A2.726 2.726 0 001.288 3.59a.107.107 0 01-.053.083A2.495 2.495 0 000 5.817a2.47 2.47 0 001.587 2.316c.192.11.41.168.63.168h7.94c.114 0 .228-.016.341-.047a3.057 3.057 0 00.764-5.786zM5.485 10.58c-.006-.004-.011-.008-.018-.01-.006-.003-.013-.004-.02-.006l-.02-.002c-.007 0-.013 0-.02.002l-.02.005-.018.011c-.004.003-.009.004-.013.008l-.008.008c-.37.34-.777 1.399-.551 1.99.103.27.32.414.63.414s.528-.143.631-.413c.227-.596-.188-1.668-.56-2-.003-.003-.009-.004-.013-.007zM7.383 9.81l-.01-.008c-.003-.004-.008-.005-.013-.008-.006-.004-.011-.008-.017-.01l-.02-.006-.02-.003c-.007 0-.013.002-.02.003a.099.099 0 00-.02.005l-.018.01c-.005.004-.01.005-.014.009l-.008.008c-.37.34-.777 1.4-.551 1.99.103.27.32.414.63.414s.528-.143.63-.413c.227-.592-.18-1.65-.55-1.99zM3.864 8.91l-.018-.012-.02-.004-.02-.003-.02.002c-.007.002-.014.003-.02.006-.007.002-.012.006-.018.01-.004.003-.01.004-.014.008-.371.331-.786 1.403-.559 1.999.103.27.321.413.63.413.31 0 .528-.143.631-.413.228-.596-.187-1.668-.559-1.999-.004-.004-.009-.005-.013-.008zM9.307 10.597l-.009-.01-.013-.007c-.006-.004-.012-.008-.018-.01l-.02-.006-.02-.002c-.007 0-.013 0-.02.002a.103.103 0 00-.02.005l-.018.01c-.004.004-.01.005-.014.009l-.008.008c-.37.34-.777 1.399-.551 1.99.103.27.321.414.63.414.31 0 .528-.143.631-.413.226-.592-.18-1.65-.55-1.99z"
				fill="#FFC107"
			/>
		</Svg>
	);
}

export default HeavyRainfall;
