import { useRef, useState } from 'react';
import {
	Dimensions,
	Image,
	Pressable,
	StyleSheet,
	TouchableWithoutFeedback,
	View,
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Video } from 'expo-av';

import { ImagePreviewProps } from './types';

const { height, width } = Dimensions.get('window');

const ImagePreview = ({
	index,
	isSelected,
	item,
	renderCustomImage,
	resizeMode,
	next,
	prev,
	length,
}: ImagePreviewProps) => {
	let type = item.mime_type.split('/')[0];
	const video = useRef(null);
	const [_status, setStatus] = useState({});

	return (
		<View style={styles.outerWrapper}>
			<TouchableWithoutFeedback onPress={() => {}}>
				<View style={styles.containerStyle}>
					{renderCustomImage ? (
						renderCustomImage(item, index, isSelected)
					) : (
						<>
							{type === 'image' ? (
								<Image
									resizeMode={resizeMode}
									source={{ uri: item.original_url }}
									style={styles.image}
								/>
							) : (
								<Video
									ref={video}
									style={styles.video}
									source={{
										uri: item.original_url,
									}}
									useNativeControls
									// resizeMode="contain"
									shouldPlay
									isLooping
									onPlaybackStatusUpdate={(status) => setStatus(() => status)}
								/>
							)}
						</>
					)}
					{index > 0 && (
						<Pressable
							onPress={prev}
							style={StyleSheet.flatten([styles.buttonWrapper, { left: -15 }])}
						>
							<View>
								<AntDesign name="doubleleft" size={15} color="#346499" />
							</View>
						</Pressable>
					)}
					{index < length - 1 && (
						<Pressable
							onPress={next}
							style={StyleSheet.flatten([
								styles.buttonWrapper,
								{ marginLeft: 'auto', right: -15 },
							])}
						>
							<View>
								<AntDesign name="doubleright" size={15} color="#346499" />
							</View>
						</Pressable>
					)}
				</View>
			</TouchableWithoutFeedback>
		</View>
	);
};

const styles = StyleSheet.create({
	containerStyle: {
		height,
		width: width - 30,
		position: 'relative',
	},
	image: {
		height: '100%',
		width: '100%',
	},
	buttonWrapper: {
		backgroundColor: '#F5F7FB',
		position: 'absolute',
		top: height / 2,
		borderRadius: 4,
		padding: 8,
		width: 30,
	},
	outerWrapper: {
		paddingLeft: 8,
		paddingRight: 8,
		width: width,
		alignItems: 'center',
	},
	video: {
		alignSelf: 'center',
		width: 320,
		height: 200,
		marginTop: (height - 160) / 2,
		justifyContent: 'center',
	},
});

export default ImagePreview;
