import * as React from 'react';
import { ScrollView, StyleSheet } from 'react-native';

import { Text, View } from '../Themed';

type PropsType = {
	Dos_Data: any;
	Donts_data: any;
};

export default function List(props: PropsType) {
	return (
		<View style={styles.container}>
			<Text style={styles.doText}>Do’s</Text>
			<ScrollView>
				{props.Dos_Data.map(
					(ele: { description: string }, i: React.Key | null | undefined) => (
						<Text key={i}>{'\u2022' + ' ' + ele.description}</Text>
					)
				)}
			</ScrollView>

			<Text style={styles.dontText}>Don’ts</Text>
			<ScrollView>
				{props.Donts_data.map(
					(ele: { description: string }, i: React.Key | null | undefined) => (
						<Text key={i}>{'\u2022' + ' ' + ele.description}</Text>
					)
				)}
			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 16,
		backgroundColor: 'transparent',
	},
	doText: {
		color: '#00AB55',
		paddingBottom: 8,
		fontSize: 15,
	},
	dontText: {
		color: '#D73030',
		paddingVertical: 8,
		fontSize: 15,
	},
});
