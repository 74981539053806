import { StyleSheet } from 'react-native';

import { PRIMARY_COLOR } from './Colors';
import { FONT_BOLD } from './Fonts';

export const sharedStyles = StyleSheet.create({
	skipButton: {
		padding: 14,
		borderRadius: 4,
		width: '46%',
		color: 'white',
		borderWidth: 1,
		borderColor: PRIMARY_COLOR,
	},
	skipButtonText: {
		color: PRIMARY_COLOR,
		fontSize: 18,
		fontFamily: FONT_BOLD,
		textAlign: 'center',
	},
	done: {
		backgroundColor: PRIMARY_COLOR,
	},
	doneText: {
		color: '#FFFFFF',
	},
	btnWrapper: {
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		marginTop: 20,
	},
	input: {
		height: 20,
		marginVertical: 6,
		backgroundColor: '#fff',
		borderRadius: 4,
		borderWidth: 0,
		width: '100%',
	},
	generalInput: {
		height: 60,
		marginVertical: 8,
		backgroundColor: '#F5F7FB',
		borderRadius: 7,
		borderWidth: 0,
		padding: 10,
		width: '100%',
	},
	passwordField: {
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
});
